import React from 'react';

import { Container, Button, Row } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

export default function About(props) {

  const navigate = useNavigate();
  const navigateApproach = () => {
    navigate('/Approach');
  };

  /*
  const navigateStudy = () => {
    navigate('/Participate');
  }*/

  return (
    <Container fluid>
        {/* Intro */}
        <Row align='center'>      
            <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>A bit of Context</b></h1>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
              <i>UCM</i> is a domain-agnostic tool designed to explore required scenarios, actors and components to 
              conceptualize and build use cases. Modeled use cases can be analyzed against each other to find commonalities.
              The initial focus and provided knowledge bases refer to Autonomous Mobility (AM).
            </p>
        </Row>
        {/* Classification */}         
        <Row align='center'>
            <h3 style={{marginTop: '30px', marginBottom: '30px'}}>The Underlying Developed Approach</h3>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }> 
            <i>UCM</i> provides a unique Four-Step-Approach to use case engineering, embedding LLMs and semantic models for improved usability, thus presenting a comprehensive solution builder.
            
            Thereby, the tool's knowledge base is built upon the two developed semantic models, 'General Use Case Ontology' (<a href="https://marc-ga.github.io/peamonto/guco.owl">GUCO</a>)
            and 'Platform Economy Autonomous Mobility Ontology' (<a href="https://marc-ga.github.io/peamonto/peamonto.owl">PEAMONTO</a>) ontologies,
            both accessible on Github.
            </p>                        
        </Row>
        {/* Learn about the approach */}
        <Row align='center'>
          <div>
            <Button variant="outline-primary" style={{ marginRight: '10px', marginTop: '10px'  }} onClick={navigateApproach}>
              <i className="bi bi-lightbulb"></i> Learn about the approach
            </Button>
          </div>            
        </Row>
        {/* The research */}
        <Row align='center'>      
            <h3 style={ { marginTop: '50px', marginBottom: '30px' } }>The research</h3>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
            This tool is used alongside PhD research efforts to investigate the complexities of AM use cases and to propose a reference model for the development of AM systems.
            </p>
        </Row>
        <Row align='center'>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
            Research landmarks are: semantic models, ontologies, digital platforms, CCAM, Distributed AI (DAI), MaaS, Smart City, platform economy, network effects, public traffic space.
            </p>
        </Row>
        {/* Navigate 
        <Row align='center'>
            <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Participate</b></h1>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
              We soon invite experts to participate in a semi-structured focus session to systematically gather data on AM use cases. This input is used to develop a comprehensive AM model.
            </p>
            <div>            
                <Button variant="success" style={{ marginRight: '10px', marginTop: '10px' }} onClick={navigateStudy}>
                  <i className="bi bi-pencil"></i> <b>Contribute to this research</b>
                </Button>
            </div>                
        </Row>
        */}
      </Container>
    );
};

//export default About;
export { default as About } from './About';