import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button, ListGroup, Badge, Modal } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ElemToastCopied } from '../elems/elem_toast';

import vis from 'vis-network';
import { retrieveIndividuals } from './onto_parse'; // parseOWLFile,
import { GUCOontologyURL } from './onto_config';

import { buildSidebarItems } from '../onto_tree_handling/parse_items';

//import 'vis/dist/vis.css'; // Import vis.js styles

/**
 * Transforms the parsed RDF data into a format suitable for the Vis.js network.
 * @param {*} rdfData 
 * @returns 
 */ /*
const processRdfData = (rdfData, classLabel) => {
  const nodes = [];
  const edges = [];

  const classId = getClassIdByLabel(rdfData, classLabel);

  nodes.push({ id: classId, label: classLabel});
  // Iterate through each class in rdfData
  rdfData.forEach(cls => {
      // Check if the class ID appears in the subclasses of the current class
      if (cls.subclasses.includes(classId)) {
          // Add the current class as a node
          nodes.push({ id: cls.classNode, label: cls.label });

          // Add edges from the current class to its subclasses
          cls.subclasses.forEach(subclassId => {
              edges.push({ from: cls.classNode, to: subclassId });
          });
      }
  });

  return { nodes, edges };
};*/

const processRdfDataFromRoot = (rdfData, classLabel) => {
  const nodes = [];
  const edges = [];

  const classId = getClassIdByLabel(rdfData, classLabel);

  // Helper function to recursively add nodes and edges
  const addNodeAndEdges = (currentClassId, currentClassLabel) => {
    if (!nodes.find(node => node.id === currentClassId)) {
      nodes.push({ id: currentClassId, label: currentClassLabel });
    }

    rdfData.forEach(cls => {
      if (cls.subclasses.includes(currentClassId)) {
        if (!nodes.find(node => node.id === cls.classNode)) {
          nodes.push({ id: cls.classNode, label: cls.label });
        }

        cls.subclasses.forEach(subclassId => {
          if (!edges.find(edge => edge.from === cls.classNode && edge.to === subclassId)) {
            edges.push({ from: cls.classNode, to: subclassId });
          }
        });

        addNodeAndEdges(cls.classNode, cls.label); // Recursively add subclasses
      }
    });
  };

  addNodeAndEdges(classId, classLabel);

  return { nodes, edges };
};

/**
 * Searches the RDF data for a class with a specific label and returns its ID.
 * @param {*} rdfData 
 * @param {*} classLabel 
 * @returns 
 */
const getClassIdByLabel = (rdfData, classLabel) => {
  const foundClass = rdfData.find(cls => cls.label === classLabel);
  return foundClass ? foundClass.classNode : null;
};

// Retrieve individuals from the ontology
const individuals = retrieveIndividuals(GUCOontologyURL);

/** 
 * Fetch and parse the OWL file from the specified URL.
 * The function returns a promise that resolves to the parsed RDF data.
 * @param {string} ontologyURL - The URL of the OWL ontology file.
 * @returns {Promise} A promise that resolves to the parsed RDF data.
*/
/*
const OWLFileParser = (ontologyURL) => {

  // State to store the parsed RDF data
  const [rdfData, setRdfData] = useState(null);

  useEffect(() => {
    parseOWLFile(ontologyURL)
      .then((data) => {
        setRdfData(data);
        //console.log('Parsed OWL file:', data);
      })
      .catch((error) => {
        console.error('Error parsing OWL file:', error);
      });
  }, [ontologyURL]);

  return rdfData;
}
*/

// Function to process RDF data and return OWL snippet
const processRdfDataToOwl = (rdfData, classLabel) => {
  let owlSnippet = '';

  const classId = getClassIdByLabel(rdfData, classLabel);

  const addOwlSnippet = (currentClassId, currentClassLabel) => {
    if (!owlSnippet.includes(`<owl:Class rdf:about="#${currentClassId}">`)) {
      owlSnippet += `<owl:Class rdf:about="#${currentClassId}">
  <rdfs:label>${currentClassLabel}</rdfs:label>
</owl:Class>\n`;
    }

    rdfData.forEach(cls => {
      if (cls.subclasses.includes(currentClassId)) {
        if (!owlSnippet.includes(`<owl:Class rdf:about="#${cls.classNode}">`)) {
          owlSnippet += `<owl:Class rdf:about="#${cls.classNode}">
  <rdfs:label>${cls.label}</rdfs:label>
</owl:Class>\n`;
        }

        cls.subclasses.forEach(subclassId => {
          if (!owlSnippet.includes(`<rdf:Description rdf:about="#${cls.classNode}">
  <rdfs:subClassOf rdf:resource="#${subclassId}"/>
</rdf:Description>\n`)) {
            owlSnippet += `<rdf:Description rdf:about="#${cls.classNode}">
  <rdfs:subClassOf rdf:resource="#${subclassId}"/>
</rdf:Description>\n`;
          }
        });

        addOwlSnippet(cls.classNode, cls.label); // Recursively add subclasses
      }
    });
  };

  addOwlSnippet(classId, classLabel);

  return owlSnippet;
};

const OWLModal = ({ rdfData, searchTerm, show, handleClose }) => {
  const owlSnippet = processRdfDataToOwl(rdfData, searchTerm);
  const [showToast, setShowToast] = useState(false);

  const handleCopy = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000); // Toast will close automatically after 2 seconds
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>OWL Snippet for <b>{searchTerm}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <SyntaxHighlighter language="xml" style={docco}>
            {owlSnippet}
          </SyntaxHighlighter>
        </Modal.Body>
        <Modal.Footer>
          <CopyToClipboard text={owlSnippet} onCopy={handleCopy}>
            <Button variant="primary">Copy to Clipboard</Button>
          </CopyToClipboard>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {showToast && (
        <ElemToastCopied
          show={showToast}
          onClose={() => setShowToast(false)}
          label="OWL snippet copied to clipboard!"
          delay={2000}
          autohide
        >
          Code copied to clipboard!
        </ElemToastCopied>
      )}
    </>
  );
};

/**
 * This component visualizes an OWL ontology using the Vis.js library.
 * It fetches the ontology data, parses it, and then visualizes it.
 * The visualization consists of nodes representing classes and edges representing subclass relationships.
 * The component uses the `parseOWLFile` function from the `onto_parse` module to parse the OWL file.
 * @returns {JSX.Element} The OWL visualization component.
 */
/*
const OWLVisualization0 = ({ onActorDrop, onActorNodes, newNodeLabel, newNodeEdges, triggerAddNode, setTriggerAddNode }) => {

    // State to store the nodes and edges for the Vis.js network
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    // Fetch and parse the OWL file
    const rdfData = OWLFileParser(GUCOontologyURL);

    
    useEffect(() => {
      if (rdfData !== null) {
        //console.log(rdfData);
      }
    }, [rdfData]);
    
  
    // Process the parsed RDF data and visualize it using Vis.js
    useEffect(() => {
      if (!rdfData) return;
  
      const net1 = processRdfData(rdfData, 'Actor');
      const net2 = processRdfData(rdfData, 'Layer');

      const combinedNodes = [...net1.nodes, ...net2.nodes];
      const combinedEdges = [...net1.edges, ...net2.edges];
      const uniqueNodes = Array.from(new Set(combinedNodes.map(node => node.id)))
        .map(id => combinedNodes.find(node => node.id === id));
      
      setNodes(uniqueNodes);
      setEdges(combinedEdges);

    }, [rdfData]);

    // Update the actor nodes used for e.g. the dropdown list
    useEffect(() => {
      //if (!nodes) return;
      if (nodes.length > 0) {
        onActorNodes(nodes);
      }
      //onActorNodes(nodes);
    }, [nodes, onActorNodes]);

    // Handle the drop event when an actor is dropped onto the network
    const handleDrop = (event) => {

      event.preventDefault();

      const actorData = JSON.parse(event.dataTransfer.getData('text/plain'));

      // Create a new node based on the dropped actor data
      const newNode = {
          id: nodes.length + 1, // Generate a unique ID for the new node
          label: actorData, // Use the actor data as the label
          type: "actor",
          x: event.clientX, // Set the node position based on the drop position
          y: event.clientY
      };

      // Create edges between the dropped node and existing nodes based on ontology relationships
      const newEdges = [];
      //console.log('new node', newNode.label)
      const relationships = getLinkages(rdfData, newNode.label);
      //console.log('relationship:', relationships);

      if (relationships && relationships.length > 0) {
        relationships.forEach(relationship => {
            // Find the existing node based on the relationship label
            const existingNode = nodes.find(node => node.label === relationship);
            if (existingNode) {
                // Add an edge from the existing node to the new node
                newEdges.push({
                    from: existingNode.id, // ID of the existing node
                    to: newNode.id, // ID of the dropped node
                    //label: relationship
                });
            }
        });
      }

      setNodes(prevNodes => [...prevNodes, newNode]);
      setEdges(prevEdges => [...prevEdges, ...newEdges]);
      onActorDrop(actorData); // Call the onActorDrop function with the dropped actor data
    };

    // Handle the drag over event to allow dropping elements
    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const addNode = useCallback((newNodeLabel) => {
      // Create a new node based on the provided label
      const newNode = {
        id: nodes.length + 1, // Generate a unique ID for the new node
        label: newNodeLabel, // Use the input field value as the label
        type: "actor",
        x: 100, // Set the node position to a default value
        y: 100
      };
  
      // Connect newNode to the given list of nodes newEdges
      const newEdges = newNodeEdges.map(edge => ({
        from: edge,
        to: newNode.id,
      }));
  
      setNodes(prevNodes => [...prevNodes, newNode]);
      setEdges(prevEdges => [...prevEdges, ...newEdges]);
  
      setTriggerAddNode(false); // Ensure to reset the trigger
    }, [nodes.length, newNodeEdges, setTriggerAddNode]);
  
    useEffect(() => {
      if (triggerAddNode && newNodeLabel) {
        addNode(newNodeLabel);
      }
    }, [triggerAddNode, newNodeLabel, addNode]);
    
    // Render the network visualization using Vis.js
    useEffect(() => {
      const container = document.getElementById('vis-network');
      const data = { 
        nodes, 
        edges 
      };
      const options = {
          layout: {
              improvedLayout: true,              
          },
          nodes: {
              shape: 'box',
              margin: 10,
              widthConstraint: {
                  maximum: 200,
              },
          },
      };

      const network = new vis(container, data, options);

      return () => {
          network.destroy();
      };
    }, [nodes, edges]);
  
    return <div>
        <div 
          id="vis-network" 
          style={{ width: '100%', height: '600px' }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          //onClick={addNode}
        >
        </div>
    </div>
      
};
*/

const ContextMenu = ({ position, options, onOptionClick, closeMenu }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeMenu]);

  return (
    <div
      ref={menuRef}
      className="context-menu"
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
        position: 'absolute',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
      }}
    >
      {options.map((option, index) => (
        <div
          key={index}
          className="context-menu-item"
          onClick={() => onOptionClick(option)}
          style={{ padding: '8px 12px', cursor: 'pointer' }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

// also applied in uc_actors; modularize later on...
const ListView = ({ nodes }) => {

  // Group nodes by their categories
  const categoryNodes = nodes.reduce((acc, node) => {
    if (!acc[node.label]) {
      acc[node.label] = [];
    }
    acc[node.label].push(node);
    return acc;
  }, {});

  return (
    <ListGroup>
      {Object.keys(categoryNodes).map((category) => (
        <ListGroup.Item key={category} className="d-flex justify-content-between align-items-center">
          <div>
            <strong>{category.title}</strong>
            <div>
              {categoryNodes[category].map((node) => (
                <Badge key={node.id} bg="light" text="dark" style={{ margin: '5px' }}>
                  {node.label}
                </Badge>
              ))}
            </div>
          </div>
          <Badge pill bg="primary">
            {categoryNodes[category].length}
          </Badge>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

const OWLVisualization = ( { rdfData, retrieveOWL, setRetrieveOWL, currentLayout, layoutOptions, sem_model, setSemModel, searchTerm, networkRef } ) => {

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const containerRef = useRef(null); 

  useEffect(() => {
      if (!rdfData) return;

      /*
      if (sem_model === 'GUCO') {
        const net1 = processRdfData(rdfData, 'Actor');
        const net2 = processRdfData(rdfData, 'Layer');

        const combinedNodes = [...net1.nodes, ...net2.nodes];
        const combinedEdges = [...net1.edges, ...net2.edges];
        const uniqueNodes = Array.from(new Set(combinedNodes.map(node => node.id)))
            .map(id => combinedNodes.find(node => node.id === id));

        setNodes(uniqueNodes);
        setEdges(combinedEdges);
      }
      */

      if (sem_model !== '') {
          
          const net1 = processRdfDataFromRoot(rdfData, searchTerm);
          const combinedNodes = net1.nodes;
          
          const combinedEdges = [...net1.edges]
          const uniqueNodes = Array.from(new Set(combinedNodes.map(node => node.id)))
              .map(id => combinedNodes.find(node => node.id === id));
  
          setNodes(uniqueNodes);
          setEdges(combinedEdges);
          setNodes(net1.nodes);
      }

  }, [rdfData, sem_model, searchTerm]);

  useEffect(() => {
      if (containerRef.current) {
          const data = { 
            nodes, 
            edges 
          };
          const options = {
              layout: {
                  improvedLayout: true,
              },
              nodes: {
                  shape: 'box',
                  margin: 10,
                  widthConstraint: {
                      maximum: 200,
                  },
              },
              ...layoutOptions[currentLayout],
          };

          const network = new vis.Network(containerRef.current, data, options);
          networkRef.current = network;

          network.on('oncontext', (params) => {
              params.event.preventDefault();
              setContextMenu({
                  position: { x: params.event.clientX, y: params.event.clientY },
                  nodeId: params.nodes[0],
              });
          });

          return () => {
              network.destroy();
          };
      }
  }, [nodes, edges, networkRef, currentLayout, layoutOptions]);

  const contextMenuOptions = [
    { label: 'Add Node' },
    { label: 'Edit Node' },        
    { label: 'Edit Edge' },
    { label: 'Delete Node' },
    { label: 'Delete Edge' },
  ];

  const handleContextMenuOptionClick = (option) => {
      // Handle context menu option click
      setContextMenu(null);
  };
  
  // parse the RDF data and build the sidebar items
  // used in the component modeling step and the chat to enhance the data by semantic information
  useEffect(() => {
    if (rdfData !== null) {
        const items = buildSidebarItems(rdfData);
        if (items.length > 0) {
            items[0].collapsed = false;
            //setSemModel(items[0].title);
        }
        //setSidebarItems(items);
    }
  }, [rdfData]); // setSemModel

  const handleClose = () => {
    setRetrieveOWL(!retrieveOWL);
  }

  return (
    <DndProvider backend={HTML5Backend}>
    <Row>
      {/* Network Visualization */}
      <Col md={12} style={{ textAlign: 'left', marginTop: '15px' }}>
          {/* list view used in actors */}
          {currentLayout === 'list' ? (
            <>
              <ListView nodes={nodes} />
            </>
          ) : (
              <>                                   
                <div>
                  <div
                      ref={containerRef}
                      //style={{ width: '100%', height: '600px' }}
                      style={{ height: '500px', width: '100%', border: '0.5px solid black' }}
                  ></div>
                  {contextMenu && (
                    <ContextMenu
                        position={contextMenu.position}
                        options={contextMenuOptions}
                        onOptionClick={handleContextMenuOptionClick}
                        closeMenu={() => setContextMenu(null)}
                    />
                  )}
                </div>               
              </>
          )}               
      </Col>
    </Row>
    {/* OWL Modal */}
    {rdfData && searchTerm && (
    <OWLModal
      rdfData={rdfData}
      searchTerm={searchTerm}
      show={retrieveOWL}
      handleClose={handleClose}
    />
    )}
  </DndProvider>
  );
};


export { OWLVisualization, individuals };