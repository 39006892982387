/**
 * Generalized Model Component
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-06
 * 
 */

import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';

import '../../App.css';
import vis from 'vis-network';
import { ResponsiveTreeMapHtml, ResponsiveTreeMap } from '@nivo/treemap';
import { ResponsiveSunburst } from '@nivo/sunburst';
 
import NetworkAnalysis from './eco_model_network';
import Loading from '../elems/elem_standard_load';

// data.js
export const cubeData = {
    front: {
        name: 'Platform Components',
        children: [
            { name: 'Hardware', children: [{ name: 'CPU', value: 50 }, { name: 'Sensors', value: 30 }] },
            { name: 'Middleware', children: [{ name: 'Middleware A', value: 40 }, { name: 'Middleware B', value: 60 }] },
            { name: 'Platform', children: [{ name: 'Core', value: 20 }, { name: 'Applications', value: 20 }] }
        ]
    },
    right: {
        name: 'Ecosystem',
        children: [
            { name: 'Geography', children: [{ name: 'Country', value: 50 }, { name: 'City', value: 20 }] },
            { name: 'Regulations', children: [{ name: 'Data Privacy', value: 50 }] }
        ]
    },
    left: {
        name: 'Actors',
        children: [
            { name: 'Producer', children: [{ name: 'Producer A', value: 50 }, { name: 'Producer B', value: 30 }] },
            { name: 'Consumer', children: [{ name: 'Consumer A', value: 40 }] },
            { name: 'Regulator', children: [{ name: 'Regulator A', value: 20 }] }
        ]
    },
    top: {
        name: 'Scenario',
        children: [
            { name: 'Use Case', children: [{ name: 'Use Case A', value: 50 }, { name: 'Use Case B', value: 30 }] },
            { name: 'Scenario', children: [{ name: 'Scenario A', value: 40 }, { name: 'Scenario B', value: 60 }] }
        ]
    },
    bottom: {
        name: 'Operations',
        children: [
            { name: 'Operations A', value: 50 },
            { name: 'Operations B', value: 30 }
        ]
    },
    back: {
        name: 'Regulatory',
        children: [
            { name: 'Data Privacy', value: 50 },
            { name: 'Data Security', value: 20 }
        ]
    }
    // Define additional data for top, bottom, and back faces as needed
};

// ------------------ Sunburst ------------------

const generateSunburstData = (useCaseDataObj) => {
    // Convert object properties to an array
    const useCaseDataArray = Object.values(useCaseDataObj);

    // Define baseline actors and components for consistent structure
    const baselineActors = ['Producer', 'Consumer', 'Regulator', 'Technical', 'Operator', 'Platform'];
    const baselineComponents = [
        { category: 'Operations', subcategories: ['Operations: Systems, Services, and Tools', 'Operations Management', 'Operations Analytics', 'Operations Platform'] },
        { category: 'Producer', subcategories: ['Producer', 'Producer Analytics', 'Producer Management', 'Producer Platform'] },
        { category: 'Consumer', subcategories: ['Consumer', 'Consumer Analytics', 'Consumer Management', 'Consumer Platform'] },
        { category: 'Technical Layer', subcategories: ['Technical Layer', 'Applications / Interfaces (proprietary, third-party, partially distributed)', 'Platform and Cloud (proprietary, third-party, partially distributed)', 'ADS: Automated Driving System', 'AI-Middleware and Edge (Devices)', 'Hardware (Vehicle, Infrastructure)'] }
    ];

    // Build data for actors with detailed items
    const actorsData = baselineActors.map((actor) => {
        const actorInstances = useCaseDataArray.flatMap((useCaseData) => {
            return useCaseData.actors?.value?.list[actor]?.value || [];
        });

        const uniqueActorItems = Array.from(
            actorInstances.reduce((map, item) => {
                map.set(item, (map.get(item) || 0) + 1);
                return map;
            }, new Map())
        ).map(([item, count]) => ({
            name: item,
            value: count
        }));

        return {
            name: actor,
            children: uniqueActorItems.length > 0 ? uniqueActorItems : [{ name: actor, value: 10 }]
        };
    }).filter(actor => actor.children.length > 0); // Filter out actors with no items

    // Build data for components, including unique items
    const componentsData = baselineComponents.map((componentCategory) => {
        return {
            name: componentCategory.category,
            children: componentCategory.subcategories.map((subcategory) => {
                // Collect items that match the subcategory description within the main category
                const allSubcategoryItems = useCaseDataArray.flatMap((useCaseData) => {
                    return (useCaseData.components?.value || []).flatMap((categoryComponent) => {
                        if (categoryComponent.category === componentCategory.category) {
                            return categoryComponent.components.flatMap((component) => {
                                if (component.description === subcategory) {
                                    return Array.isArray(component.items) ? component.items : [];
                                }
                                return [];
                            });
                        }
                        return [];
                    });
                });

                // Count unique items within each subcategory
                const uniqueSubcategoryItems = Array.from(
                    allSubcategoryItems.reduce((map, item) => {
                        map.set(item, (map.get(item) || 0) + 1);
                        return map;
                    }, new Map())
                ).map(([item, count]) => ({
                    name: item,
                    value: count
                }));

                return {
                    name: subcategory,
                    children: uniqueSubcategoryItems.length > 0 ? uniqueSubcategoryItems : [{ name: subcategory, value: 10 }]
                };
            }).filter(subcomponent => subcomponent.children.length > 0) // Filter out empty subcategories
        };
    }).filter(component => component.children.length > 0); // Filter out empty categories

    // Aggregate data for the ecosystem
    const ecosystemData = [
        {
            name: 'Country',
            children: Array.from(new Set(useCaseDataArray.flatMap(useCaseData => useCaseData.country?.value || [])))
                .map((country, index) => ({
                    name: country,
                    value: 10 + index * 2 // Adjust as needed
                }))
        },
        {
            name: 'City',
            children: Array.from(new Set(useCaseDataArray.flatMap(useCaseData => useCaseData.city?.value || [])))
                .map((city, index) => ({
                    name: city,
                    value: 5 + index * 2 // Adjust as needed
                }))
        },
        {
            name: 'Continents',
            children: Array.from(new Set(useCaseDataArray.flatMap(useCaseData => useCaseData.continents?.value || [])))
                .map((continent, index) => ({
                    name: continent,
                    value: 15 + index * 2 // Adjust as needed
                }))
        }
    ];

    // Build data for conditions and actions
    /*
    const conditionsData = useCaseDataArray.flatMap((useCaseData) =>
        Array.isArray(useCaseData.conditions?.value)
            ? useCaseData.conditions.value.map((condition, index) => ({
                name: `Condition ${index + 1}`,
                value: 10 // Placeholder value; adjust as needed
            }))
            : []
    );

    const actionsData = useCaseDataArray.flatMap((useCaseData) =>
        Array.isArray(useCaseData.actions?.value)
            ? useCaseData.actions.value.map((action, index) => ({
                name: `Action ${index + 1}`,
                value: 10 // Placeholder value; adjust as needed
            }))
            : []
    );
    */

    // Combine the major clusters into the final structure
    return {
        name: 'Platform Economy Model',
        children: [
            {
                name: 'Actors',
                children: actorsData
            },
            {
                name: 'Components',
                children: componentsData
            },
            {
                name: 'Ecosystem',
                children: ecosystemData.filter(ecosystem => ecosystem.children.length > 0) // Filter out empty groups
            },
            /*
            {
                name: 'Conditions',
                children: conditionsData
            },
            {
                name: 'Actions',
                children: actionsData
            }
            */
        ]
    };
};

const truncateLabel = (label, maxLength = 10) => {
    return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
};

const ZoomableSunburst = ({ usecases }) => {

    const [loading, setLoading] = useState(true);
    // Generate the initial sunburst data from the usecases prop
    const useCaseData = generateSunburstData(usecases);
    //console.log('Generated Sunburst Data:', useCaseData);

    const [currentData, setCurrentData] = useState(useCaseData);
    const [zoomHistory, setZoomHistory] = useState([useCaseData]);

    // Set loading to false after the initial render
    useEffect(() => {
        if (currentData) {
            setLoading(false);
        }
    }, [currentData]);

    // Handle click event to zoom into the clicked node
    const handleClick = (node) => {
        if (node.data.children) {
            setCurrentData(node.data);
            setZoomHistory((prevHistory) => [...prevHistory, node.data]);
        }
    };

    // Handle going one level up in the zoom history
    const handleStepBack = () => {
        if (zoomHistory.length > 1) {
            const newHistory = [...zoomHistory];
            newHistory.pop(); // Remove the last element
            const previousData = newHistory[newHistory.length - 1];
            setCurrentData(previousData);
            setZoomHistory(newHistory);
        }
    };

    // Handle reset to the root level
    const handleReset = () => {
        if (zoomHistory.length > 1) {
            const rootData = zoomHistory[0]; // Ensure we go back to the original root
            setCurrentData(rootData);
            setZoomHistory([rootData]);
        }
    };

    return (
        <div>
            {loading && <Loading show={loading} />}
            <div style={{ height: '600px' }}>
                <ResponsiveSunburst
                    data={currentData}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    identity="name"
                    value="value"
                    cornerRadius={2}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                    colors={{ scheme: 'nivo' }}
                    childColor={{ from: 'color', modifiers: [['brighter', 0.4]] }}
                    enableArcLabels={true}
                    arcLabel={(node) => truncateLabel(node.data.name, 15)} // Adjust length as needed
                    arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                    arcLabelsSkipAngle={10}
                    arcLabelsRadiusOffset={0.6} // Adjust position of labels for better fit
                    onClick={handleClick}
                    tooltip={({ id, data, value }) => (
                        <div style={{ padding: '5px', color: '#333', background: 'white', borderRadius: '3px' }}>
                            <strong>{data.name || id}</strong>: {value}
                        </div>
                    )}
                    theme={{
                        labels: {
                            text: {
                                fontSize: 12, // Adjust based on visualization
                                textTransform: 'capitalize',
                            },
                        },
                        tooltip: {
                            container: {
                                fontSize: '14px',
                            },
                        },
                    }}
                />
            </div>
            {/* Step-back button */}
            {zoomHistory.length > 1 && (
                <Button
                    className='btn-sm'
                    variant='outline-primary'
                    onClick={handleStepBack}
                    style={{ marginTop: '10px', padding: '10px', cursor: 'pointer' }}
                >
                    Step Back One Level
                </Button>
            )}

            {/* Reset button */}
            {zoomHistory.length > 1 && (
                <Button
                    className='btn-sm'
                    variant='outline-primary'
                    onClick={handleReset}
                    style={{ marginTop: '10px', marginLeft: '5px', padding: '10px', cursor: 'pointer' }}
                >
                    Reset to Root
                </Button>
            )}
        </div>
    );
};

// ------------------ Cube and cube Face ------------------

const CubeFace = ({ data, position, label }) => (
    <div className={`cube-face ${position}`}>
        <h5 className="face-label">{label}</h5>
        <ResponsiveTreeMapHtml
            data={data}
            identity="name"
            value="value"
            label={(node) => node.id}
            labelSkipSize={12}
            innerPadding={3}
            outerPadding={3}
            colors={{ scheme: 'blues' }} // Adjusted to use a blue color scheme
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
        />
    </div>
);

const PlatformEconomyCube1 = ({ usecases }) => {
    // Generate data for the cube from the new sunburst structure
    const sunburstData = generateSunburstData(usecases);

    // Map the sunburst data to specific cube faces
    const cubeData = {
        front: sunburstData.children.find(child => child.name === 'Components') || { name: 'Components', children: [] },
        back: sunburstData.children.find(child => child.name === 'Regulatory') || { name: 'Regulatory', children: [] },
        left: sunburstData.children.find(child => child.name === 'Actors') || { name: 'Actors', children: [] },
        right: sunburstData.children.find(child => child.name === 'Ecosystem') || { name: 'Ecosystem', children: [] },
        top: sunburstData.children.find(child => child.name === 'Scenario') || { name: 'Scenario', children: [] },
        bottom: sunburstData.children.find(child => child.name === 'Operations') || { name: 'Operations', children: [] }
    };

    const [rotation, setRotation] = useState({ rotateX: -30, rotateY: -45 });

    const rotateCube = (axis, value) => {
        setRotation((prev) => ({ ...prev, [axis]: prev[axis] + value }));
    };

    return (
        <Row className="justify-content-center">
            <Col md={8}>
                <div className="cube-container">
                    <div
                        className="cube"
                        style={{
                            transform: `rotateX(${rotation.rotateX}deg) rotateY(${rotation.rotateY}deg)`
                        }}
                    >
                        <CubeFace data={cubeData.front} position="front" label="Platform Components" />
                        <CubeFace data={cubeData.back} position="back" label="Regulatory" />
                        <CubeFace data={cubeData.left} position="left" label="Actors" />
                        <CubeFace data={cubeData.right} position="right" label="Ecosystem" />
                        <CubeFace data={cubeData.top} position="top" label="Scenario" />
                        <CubeFace data={cubeData.bottom} position="bottom" label="Operations" />
                    </div>
                </div>
            </Col>

            {/* Rotation Controls */}
            <Row className="justify-content-center mt-3">
                <Col md={6} className="text-center">
                    <Button variant="primary" onClick={() => rotateCube('rotateX', 30)} className="m-1">
                        Rotate X+
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateX', -30)} className="m-1">
                        Rotate X-
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateY', 30)} className="m-1">
                        Rotate Y+
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateY', -30)} className="m-1">
                        Rotate Y-
                    </Button>
                </Col>
            </Row>
        </Row>
    );
};

// ------------------ Generalized Model ------------------

const PlatformEconomyCube = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [rotation, setRotation] = useState({ rotateX: -30, rotateY: -45 });

    const rotateCube = (axis, value) => {
        setRotation((prev) => ({ ...prev, [axis]: prev[axis] + value }));
    };

    const cubeFaces = [
        {
            id: 'front',
            label: 'Front',
            position: 'front',
            content: ['Hardware', 'Middleware', 'ADS', 'Platform', 'Applications']
        },
        {
            id: 'right',
            label: 'Right',
            position: 'right',
            content: ['Vehicle', 'Edge', 'Cloud']
        },
        {
            id: 'left',
            label: 'Left',
            position: 'left',
            content: ['Producer', 'Consumer']
        },
        {
            id: 'top',
            label: 'Top',
            position: 'top',
            content: ['Ecosystem']
        },
        {
            id: 'bottom',
            label: 'Bottom',
            position: 'bottom',
            content: []
        },
        {
            id: 'back',
            label: 'Back',
            position: 'back',
            content: []
        }
    ];

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <Container className="text-center">
            <Row>
                <Col>
                    <h1>PEAM Platform Economy Model for Autonomous Mobility</h1>
                </Col>
            </Row>

            {/* Cube Container */}
            <Row className="justify-content-center">
                <Col md={8}>
                    <div className="cube-container">
                        <div
                            className="cube"
                            style={{
                                transform: `rotateX(${rotation.rotateX}deg) rotateY(${rotation.rotateY}deg)`
                            }}
                        >
                            {cubeFaces.map((face) => (
                                <div key={face.id} className={`cube-face ${face.position}`}>
                                    {face.content.map((item, index) => (
                                        <div
                                            key={index}
                                            className="cube-item"
                                            onClick={() => handleItemClick(item)}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            ))}

                        </div>
                    </div>
                </Col>
            </Row>

            {/* Rotation Controls */}
            <Row className="justify-content-center mt-3">
                <Col md={6} className="text-center">
                    <Button variant="primary" onClick={() => rotateCube('rotateX', 30)} className="m-1">
                        Rotate X+
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateX', -30)} className="m-1">
                        Rotate X-
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateY', 30)} className="m-1">
                        Rotate Y+
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateY', -30)} className="m-1">
                        Rotate Y-
                    </Button>
                </Col>
            </Row>

            {/* Data Viewer */}
            {selectedItem && (
                <Row className="justify-content-center mt-4">
                    <Col md={6}>
                        <div className="data-viewer p-3 border rounded">
                            <h3>Selected Item - {selectedItem}</h3>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

// ---

// Generate a single treemap with all the sunburst data state
const generateConsolidatedTreemapData = (useCaseDataObj) => {
    // Use the existing `generateSunburstData` function to create the base data structure
    const sunburstData = generateSunburstData(useCaseDataObj);

    return {
        name: 'Platform Economy Model',
        children: sunburstData.children
    };
};

const SingleLargeTreemap = ({ usecases }) => {
    const treemapData = generateConsolidatedTreemapData(usecases);

    return (
        <div style={{ height: '800px' }}>
            <ResponsiveTreeMap
                data={treemapData}
                identity="name"
                value="value"
                label={(node) => node.id}
                labelSkipSize={12}
                innerPadding={3}
                outerPadding={3}
                colors={{ scheme: 'blues' }} // Adjust color scheme as needed
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                tooltip={({ id, value, color }) => (
                    <div style={{ padding: '5px', background: 'white', border: '1px solid #ddd', borderRadius: '3px' }}>
                        <strong>{id}</strong>: {value}
                    </div>
                )}
            />
        </div>
    );
};

// ---

const GenerateVisData = (useCaseDataObj) => {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    useEffect(() => {
        // Temporary arrays to collect nodes and edges
        const tempNodes = [];
        const tempEdges = [];
        const nodeIds = new Set(); // To track unique node IDs

        // Helper function to add a node if it doesn't exist
        const addNode = (node) => {
            if (!nodeIds.has(node.id)) {
                tempNodes.push(node);
                nodeIds.add(node.id);
            }
        };

        // Helper function to add an edge
        const addEdge = (edge) => {
            tempEdges.push(edge);
        };

        // Define high-level categories (actors, components, ecosystem)
        const categories = ['Actors', 'Components', 'Ecosystem'];

        // Create a central node for the model
        addNode({ id: 'Platform Economy', label: 'Platform Economy', shape: 'box', color: '#2b7ce9' });

        // Add high-level nodes and their connections
        categories.forEach((category) => {
            addNode({ id: category, label: category, shape: 'ellipse', color: '#97c2fc' });
            addEdge({ from: 'Platform Economy', to: category });
        });

        // Adding detailed actor nodes
        const baselineActors = ['Producer', 'Consumer', 'Regulator', 'Technical', 'Operator', 'Platform'];
        baselineActors.forEach((actor) => {
            const actorInstances = Object.values(useCaseDataObj).flatMap((useCaseData) => {
                return useCaseData.actors?.value?.list[actor]?.value || [];
            });

            if (actorInstances.length > 0) {
                addNode({ id: actor, label: actor, shape: 'ellipse', color: '#d2e5ff' });
                addEdge({ from: 'Actors', to: actor });

                // Add individual instances as child nodes
                actorInstances.forEach((instance, index) => {
                    const nodeId = `${actor}-${instance}`; // Ensure unique IDs using instance names
                    addNode({ id: nodeId, label: instance, shape: 'dot', color: '#f0ad4e' });
                    addEdge({ from: actor, to: nodeId });
                });
            }
        });

        // Adding detailed component nodes
        const baselineComponents = [
            { category: 'Operations', subcategories: ['Operations: Systems, Services, and Tools', 'Operations Management', 'Operations Analytics', 'Operations Platform'] },
            { category: 'Producer', subcategories: ['Producer', 'Producer Analytics', 'Producer Management', 'Producer Platform'] },
            { category: 'Consumer', subcategories: ['Consumer', 'Consumer Analytics', 'Consumer Management', 'Consumer Platform'] },
            { category: 'Technical Layer', subcategories: ['Technical Layer', 'Applications / Interfaces (proprietary, third-party, partially distributed)', 'Platform and Cloud (proprietary, third-party, partially distributed)', 'ADS: Automated Driving System', 'AI-Middleware and Edge (Devices)', 'Hardware (Vehicle, Infrastructure)'] }
        ];

        baselineComponents.forEach((componentCategory) => {
            addNode({ id: componentCategory.category, label: componentCategory.category, shape: 'ellipse', color: '#d2e5ff' });
            addEdge({ from: 'Components', to: componentCategory.category });

            componentCategory.subcategories.forEach((subcategory) => {
                const allSubcategoryItems = Object.values(useCaseDataObj).flatMap((useCaseData) => {
                    return (useCaseData.components?.value || []).flatMap((categoryComponent) => {
                        if (categoryComponent.category === componentCategory.category) {
                            return categoryComponent.components.flatMap((component) => {
                                if (component.description === subcategory) {
                                    return Array.isArray(component.items) ? component.items : [];
                                }
                                return [];
                            });
                        }
                        return [];
                    });
                });

                const uniqueSubcategoryItems = Array.from(
                    allSubcategoryItems.reduce((map, item) => {
                        map.set(item, (map.get(item) || 0) + 1);
                        return map;
                    }, new Map())
                ).map(([item, count]) => {
                    const nodeId = `${componentCategory.category}-${subcategory}-${item}`; // Ensure unique IDs
                    return { id: nodeId, label: item, shape: 'dot', color: '#f0ad4e' };
                });

                if (uniqueSubcategoryItems.length > 0) {
                    addNode({ id: subcategory, label: subcategory, shape: 'ellipse', color: '#d2e5ff' });
                    addEdge({ from: componentCategory.category, to: subcategory });

                    uniqueSubcategoryItems.forEach((item) => {
                        addNode(item);
                        addEdge({ from: subcategory, to: item.id });
                    });
                }
            });
        });

        // Adding detailed ecosystem nodes
        const ecosystemData = [
            { 
                category: 'Country', 
                items: Array.from(new Set(Object.values(useCaseDataObj).flatMap(useCaseData => useCaseData.country?.value || [])) )
            },
            {
                category: 'City',
                items: Array.from(new Set(Object.values(useCaseDataObj).flatMap(useCaseData => useCaseData.city?.value || [])) )
            },
            {
                category: 'Continents',
                items: Array.from(new Set(Object.values(useCaseDataObj).flatMap(useCaseData => useCaseData.continents?.value || [])) )
            }
        ];

        ecosystemData.forEach((ecosystemCategory) => {
            addNode({ id: ecosystemCategory.category, label: ecosystemCategory.category, shape: 'ellipse', color: '#d2e5ff' });
            addEdge({ from: 'Ecosystem', to: ecosystemCategory.category });

            ecosystemCategory.items.forEach((item, index) => {
                const nodeId = `${ecosystemCategory.category}-${item}`; // Ensure unique IDs
                addNode({ id: nodeId, label: item, shape: 'dot', color: '#f0ad4e' });
                addEdge({ from: ecosystemCategory.category, to: nodeId });
            });
        });

        // Set state with the collected nodes and edges
        setNodes(tempNodes);
        setEdges(tempEdges);
    }, [useCaseDataObj]); // Re-run the effect when useCaseDataObj changes

    return { nodes, edges };
};

const VisNetwork = ({ useCaseDataObj }) => {
    const containerRef = useRef(null);
    const [network, setNetwork] = useState(null);
    const { nodes, edges } = GenerateVisData(useCaseDataObj);
    const [currentLayout, setCurrentLayout] = useState('circle');

    useEffect(() => {
        const data = {
            nodes: nodes,
            edges: edges,
        };

        const options = {
            nodes: {
                shape: 'dot',
                size: 10,
                font: {
                    size: 14,
                    color: '#343a40',
                },
                borderWidth: 2,
            },
            edges: {
                color: '#848484',
                arrows: {
                    to: { enabled: true, scaleFactor: 0.5 },
                },
                smooth: true,
            },
            physics: {
                enabled: true,
                solver: 'barnesHut',
                barnesHut: {
                    gravitationalConstant: -3000,
                    centralGravity: 0.2,
                    springLength: 100,
                    springConstant: 0.04,
                    damping: 0.3,
                },
                stabilization: {
                    iterations: 50,
                    updateInterval: 25,
                },
                worker: true,
            },
            layout: {
                improvedLayout: true,
            },
            interaction: {
                hover: true,
                navigationButtons: true,
                keyboard: true,
            },
        };

        const visNetwork = new vis.Network(containerRef.current, data, options);
        setNetwork(visNetwork);

        visNetwork.on('click', (params) => {
            if (params.nodes.length > 0) {
                const nodeId = params.nodes[0];
                alert(`Clicked on node: ${nodeId}`);
            }
        });

    }, [nodes, edges]);

    const changeLayout = (layoutType) => {
        setCurrentLayout(layoutType);
        if (!network) return;

        switch (layoutType) {
            case 'circle':
                network.setOptions({
                    layout: {
                        hierarchical: false,
                    },
                    physics: { enabled: false },
                });
                network.stabilize();
                break;
            case 'star':
                network.setOptions({
                    physics: {
                        enabled: true,
                        solver: 'forceAtlas2Based',
                        forceAtlas2Based: {
                            gravitationalConstant: -50,
                            centralGravity: 0.01,
                            springLength: 100,
                            springConstant: 0.08,
                            damping: 0.4,
                        },
                    },
                    layout: {
                        hierarchical: false,
                        improvedLayout: false,
                    },
                });
                network.stabilize();
                break;
            default:
                break;
        }
    };

    return (
        <div>
            {/* Toolbar for layout controls */}
            <div className="mb-3 d-flex justify-content-end">
                <OverlayTrigger placement="top" overlay={<Tooltip>Circle View</Tooltip>}>
                    <Button
                        variant={currentLayout === 'circle' ? 'secondary' : 'outline-secondary'}
                        onClick={() => changeLayout('circle')}
                        className="me-2 btn-sm"
                    >
                        <i className="bi bi-circle"></i> Circle View
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>Star View</Tooltip>}>
                    <Button
                        variant={currentLayout === 'star' ? 'secondary' : 'outline-secondary'}
                        onClick={() => changeLayout('star')}
                        className="me-2 btn-sm"
                    >
                        <i className="bi bi-asterisk"></i> Star View
                    </Button>
                </OverlayTrigger>
            </div>

            {/* Network container */}
            <div ref={containerRef} style={{ height: '600px', border: '1px solid #ddd' }} />
        </div>
    );
};

// ------------------ Generalized Model ------------------

const GeneralizedModel = ({ usecases, handleClear }) => {
    return (
        <div>
            {/* Header */}
            <Row>
                <Col md={12} style={{ textAlign: 'left' }} id="content">
                    {/* Title */}
                    <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <Col md={10}>
                            <span>Generalized Autonomous Mobility System Model in the Platform Economy (PEAM)</span>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} onClick={handleClear}>
                                <i className="bi bi-arrow-left"></i> Go back
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Tabbed Views */}
            <Tabs defaultActiveKey="sunburst" id="peam-tabs" className="mb-3">
                <Tab eventKey="sunburst" title="Aggregated PEAM Sunburst">
                    <Row>
                        <Col md={12}>
                            <ZoomableSunburst usecases={usecases} />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="treemap" title="Treemap PEAM Model">
                    <Row>
                        <Col md={12}>
                            <SingleLargeTreemap usecases={usecases} />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="treemap_cube" title="Treemap Cube PEAM Model">
                    <Row>
                        <Col md={12}>
                            <PlatformEconomyCube1 usecases={usecases} />
                        </Col>
                        {/* Replace with the appropriate component or content */}
                        <PlatformEconomyCube />
                    </Row>
                </Tab>
                <Tab eventKey="actor-mapping" title="Actor Component Mapping">
                    {/* Vis PEAM Overview */}
                    <Row>
                        <Col md={12}>
                            <VisNetwork useCaseDataObj={usecases} />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="network-analysis" title="Network Analysis">
                    <Row>
                        <Col md={12}>
                            {/* Add your cluster analysis component or content here */}
                            <div>Cluster Analysis Content</div>
                            <NetworkAnalysis useCases={usecases} />
                        </Col>
                    </Row>
                </Tab>                
            </Tabs>           
        </div>
    );
};

export default GeneralizedModel;
