/**
 * Study guide component.
 *
 * Description: This component is responsible for rendering the Study Guide.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-05
 */

import React from 'react';
import { Card, Collapse, Button } from 'react-bootstrap';

const StudyGuide = ( { currentStep, isGuideExpanded, setIsGuideExpanded }) => {

    const step = currentStep;

    const toggleGuide = () => {
        setIsGuideExpanded(!isGuideExpanded);
    };

    // Steps information
    const stepInfo = {
        1: {
        title: 'Task 1: Model Smart Parking',
        description: 'Model a Smart Parking use case using the UCM Co-Pilot. Provide a prompt to UCM and adapt the suggested elements to meet your understanding of this use case.',
        timeLimit: '10 minutes',
        },
        2: {
        title: 'Task 2: Manual Custom Use Case',
        description: 'Select any use case of your choice in the mobility and transportation domain, and model it manually without the UCM Co-Pilot assistance.',
        timeLimit: '15 minutes',
        },
        3: {
        title: 'Task 3: Co-Pilot Revisit',
        description: 'Revisit your previously modeled use case and model it from scratch using the UCM Co-Pilot.',
        timeLimit: '10 minutes',
        },
        4: {
        title: 'Task 4: Survey',
        description: 'Answer a quick survey about your experience with the tool.',
        timeLimit: '5 minutes',        
        },
        5: {
        title: 'Task 5: Interview',
        description: 'You will now be asked a few pre-defined questions tailored to your domain expertise. The answers will be transcripted and analyzed for the study.',
        timeLimit: '15 minutes',
        },
    };

    const currentStepInfo = stepInfo[step] || {};

    return (
        <>
        {/* Question mark button to toggle guide */}
        {!isGuideExpanded && (
        <Button
            variant="danger"
            size="sm"
            onClick={toggleGuide}
            style={{
            position: 'fixed',
            top: '120px',
            right: '50px',
            //width: isExpanded ? '250px' : '100px',
            //backgroundColor: 'white',
            //padding: isExpanded ? '15px' : '5px',
            zIndex: 1000,
            borderRadius: '50%',
            //borderRadius: '10px',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            //boxShadow: '0 0 15px rgba(0,0,0,0.2)',
            //transition: 'width 0.3s ease, padding 0.3s ease',
            }}
        >
            <i className="bi bi-question-circle" style={{ color: 'white', fontSize: '1.5rem' }}></i>
        </Button>
        )}

        {/* Study Guide Panel */}
        <div
            style={{
            position: 'fixed',
            top: '100px',
            right: isGuideExpanded ? '20px' : '-300px', // Hide guide off-screen when not expanded
            width: '300px',
            transition: 'right 0.3s ease',
            zIndex: 999,
            }}
        >
            <Collapse in={isGuideExpanded}>
            <div>
                <Card style={{ boxShadow: '0 0 15px rgba(0,0,0,0.2)' }}>
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                    <strong>Study Guide</strong>
                    <Button
                        variant="light"
                        size="sm"
                        onClick={toggleGuide}
                        style={{ border: 'none', boxShadow: 'none' }}
                    >
                        <i className="bi bi-chevron-right"></i>
                    </Button>
                    </div>
                </Card.Header>
                <Card.Body align='left'>
                    <h6>{currentStepInfo.title}</h6>
                    <p><small>{currentStepInfo.description}</small></p>
                    <div className="text-muted">
                    <i className="bi bi-clock" style={{ color: '#007bff', marginRight: '5px' }}></i>
                    <small>Time Limit: {currentStepInfo.timeLimit}</small>
                    </div>
                </Card.Body>
                </Card>
            </div>
            </Collapse>
        </div>
        </>
    );
};

export default StudyGuide;