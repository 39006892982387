/**
 * Modal Add Component
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: (updated on 2024-10-09)
 * 
 */

import { getCurrentDate } from '../utils/utils_date';
import { UserInformation } from '../auth_mgmt/auth_handling';

export const handleAddElement = (sectionName, setData, setDroppedItems, newElementTitle, setShowModalAddComponent) => {

    //const newElementTitle = prompt("Enter the title of the new element:");

    // Check if the new elem title is not empty and add it to the corresponding section
    //console.log(`Adding new element ${newElementTitle} to section ${sectionName}`);
    
    if (newElementTitle) {
        
        setDroppedItems(prev => ({
            ...prev,
            [sectionName]: [...prev[sectionName], newElementTitle],
        }));

        // Update the data object as well
        /*
        setData(prevData => {
            // Find the corresponding category in the data object
            const updatedComponents = prevData.components.map(componentCategory => {
                if (componentCategory.category === sectionName || 
                    componentCategory.components.some(subComponent => subComponent.description === sectionName)) {
                    return {
                        ...componentCategory,
                        components: componentCategory.components.map(subComponent => {
                            if (subComponent.description === sectionName) {
                                return {
                                    ...subComponent,
                                    items: [...subComponent.items, newElementTitle],
                                };
                            }
                            return subComponent;
                        }),
                    };
                }
                return componentCategory;
            });

            return {
                ...prevData,
                components: updatedComponents,
            };
        });
        */         

        // Update useCaseData components
        setData(prevData => {
            const updatedComponents = prevData.components.value.map(componentCategory => {
                if (componentCategory.components.some(subComponent => subComponent.description === sectionName)) {
                    return {
                        ...componentCategory,
                        components: componentCategory.components.map(subComponent => {
                            if (subComponent.description === sectionName) {
                                return {
                                    ...subComponent,
                                    items: [...subComponent.items, newElementTitle],
                                };
                            }
                            return subComponent;
                        }),
                    };
                }
                return componentCategory;
            });

            return {
                ...prevData,
                components: {
                    ...prevData.components,
                    value: updatedComponents,
                    modified: true,
                    updated_timestamp: getCurrentDate(),
                    updated_by: UserInformation().displayName,
                    uid: UserInformation().uid,
                    version: prevData.components.version + 1,
                },
            };
        });
    }
};