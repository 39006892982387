/**
 * Study Tasks
 *
 * Description: Provides the elements
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-08
 * Notes: /
 */

import React, { useState } from 'react';
import { Container, Row, Col, Card, Tab, Nav } from 'react-bootstrap';

export const Tasks = ( { handleStartStudy } ) => {

    const [activeTab, setActiveTab] = useState('step1'); // State to keep track of active tab
  
    // Function to go to the next tab when the user clicks on 'Noted' button
    /*
    const nextTab = () => {
      switch (activeTab) {
        case 'step1':
          setActiveTab('step2');
          break;
        case 'step2':
          setActiveTab('step3');
          break;
        case 'step3':
          setActiveTab('step4');
          break;
        case 'step4':
          setActiveTab('step5');
          break;
        case 'step5':
          handleStartStudy();
          break;
        default:
          break;
      }
    };
  
    const previousTab = () => {
      switch (activeTab) {
        case 'step2':
          setActiveTab('step1');
          break;
        case 'step3':
          setActiveTab('step2');
          break;
        case 'step4':
          setActiveTab('step3');
          break;
        case 'step5':
          setActiveTab('step4');
          break;
        default:
          break;
      }
    };
    */    

    const handleTabSelect = (key) => {
      setActiveTab(key);
    }
  
    return (
      <Container fluid>
        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <Tab.Container id="manual-tab" activeKey={activeTab} onSelect={handleTabSelect}>
              <Row>
                {/* Navigation Tabs */}
                <Col md={12}>
                  <Nav variant="tabs" className="mb-4 justify-content-center">
                    <Nav.Item>
                      <Nav.Link eventKey="step1" style={{ borderRadius: '5px' }}>
                        <i className="bi bi-box"></i> SP (1)
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="step2" style={{ borderRadius: '5px' }}>
                        <i className="bi bi-box"></i> Custom (2)
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="step3" style={{ borderRadius: '5px' }}>
                        <i className="bi bi-box"></i> Co-Pilot (3)
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="step4" style={{ borderRadius: '5px' }}>
                        <i className="bi bi-clipboard"></i> Survey (4)
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="step5" style={{ borderRadius: '5px' }}>
                        <i className="bi bi-question-circle"></i> Interview (5)
                      </Nav.Link>
                    </Nav.Item>                    
                  </Nav>
                </Col>
  
                {/* Tab Content */}
                <Col md={12}>
                  <Tab.Content>
                    {/* Task 1 Model with SP with UCM */}
                    <Tab.Pane eventKey="step1">
                      <Card className="shadow-sm mb-4">
                        <Card.Body>
                          <Row className="align-items-center">
                            <Col md={1}>
                              <i className="bi bi-box" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                            </Col>
                            <Col>
                              <strong>Task 1: Model a Smart Parking (SP) use case using the UCM Co-Pilot</strong>
                              <p className="mb-0">To do this, just provide a prompt to UCM and adapt the suggested elements that meets your understanding of this use case.</p>
                              <p className="mb-0">
                                <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                                <small>You have 10 minutes.</small>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
  
                    {/* Task 2 Model any use case manually */}
                    <Tab.Pane eventKey="step2">
                      <Card className="shadow-sm mb-4">
                        <Card.Body>
                          <Row className="align-items-center">
                            <Col md={1}>
                              <i className="bi bi-box" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                            </Col>
                            <Col>
                              <strong>Task 2: Model a Use Case of Your Choice in the Manual Mode</strong>
                              <p className="mb-0">
                                Select any use case of your choice in the mobility and transportation domain, 
                                and model it manually without the UCM Co-Pilot assistance.
                              </p>
                              <p className="mb-0">
                                <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                                <small>You have 15 minutes.</small>
                              </p>
                            </Col>
                            {/*
                            <Col md={2} className="text-end">
                              <Button variant="outline-primary" className="btn-sm" onClick={previousTab} style={ { marginRight:'5px' } }>
                                <i className="bi bi-arrow-left"></i>
                              </Button>
                              <Button variant="outline-primary" className="btn-sm" onClick={nextTab}>Noted</Button>
                            </Col>
                            */}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
  
                    {/* Task 3 Re-Model with UCM */}
                    <Tab.Pane eventKey="step3">
                      <Card className="shadow-sm mb-4">
                        <Card.Body>
                          <Row className="align-items-center">
                            <Col md={1}>
                              <i className="bi bi-box" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                            </Col>
                            <Col>
                              <strong>Task 3: Revisit your use case and model it from scratch with UCM Co-Pilot</strong>
                              <p className="mb-0">
                                Provide a prompt for UCM to model your previously created use case 
                                from scratch using the Co-Pilot.
                              </p>
                              <p className="mb-0">
                                <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                                <small>You have 10 minutes.</small>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>

                    {/* Task 4 Content */}
                    <Tab.Pane eventKey="step4">
                      <Card className="shadow-sm mb-4">
                        <Card.Body>
                          <Row className="align-items-center">
                            <Col md={1}>
                              <i className="bi bi-clipboard" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                            </Col>
                            <Col>
                              <strong>Task 4: Complete the Survey</strong>
                              <p className="mb-0">
                                Finish by answering a quick survey about your experience with the tool. 
                                This helps us understand the tool's usability.
                              </p>
                              <p className="mb-0">
                                <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                                <small>You have 5 minutes.</small>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
  
                    {/* Task 5 Content */}
                    <Tab.Pane eventKey="step5">
                      <Card className="shadow-sm mb-4">
                        <Card.Body>
                          <Row className="align-items-center">
                            <Col md={1}>
                              <i className="bi bi-question-circle" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                            </Col>
                            <Col>
                              <strong>Task 5: Answer Questions</strong>
                              <p className="mb-0">
                                You get asked a few pre-defined questions tailored to your domain expertise.
                                These are transcripted and analyzed for the study.
                              </p>
                              <p className="mb-0">
                                <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                                <small>You have 15 minutes.</small>
                              </p>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                      
                  </Tab.Content>
                </Col>
  
                {/* Total Time Information */}
                <Col md={12}>
                  <Card className="shadow-sm">
                    <Card.Body className="text-center">
                      <Card.Text>
                        <strong>It takes you about 55 minutes to complete the entire tasks.</strong>
                      </Card.Text>
                      <p>
                        Please complete each step in the time provided. We provide a time indicator and track each step's duration automatically. 
                        This allows us to gather consistent and comparable data.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    );
};