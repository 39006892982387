/**
 * Auth Handling Module
 *
 * Description:
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: last modified 2024-12-08
 * 
 */

import React, { useEffect } from 'react';

import { uiConfig, ui, auth } from './auth_config';
import { signOut, onAuthStateChanged, getAuth } from "firebase/auth"; // signInWithEmailAndPassword createUserWithEmailAndPassword

/**
 * Sign in the user
 * @returns {void}
 * Use the firebaseui library to sign in the user
 */
function SignIn() { 

  useEffect(() => {
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  return (
    <div>
      <div id="loader">Loading...</div>
      <div id="firebaseui-auth-container"></div>
    </div>
  );

  /*
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
  */
}

/**
 * Sign out the user
 * @returns {void}
 * Use the firebase signOut method to sign out the user
 */
function SignOut() {

    signOut(auth).then(() => {
      // Sign-out successful.
      console.log('SignOut');
      // Redirect to the home page
      window.location.href = '/';

    }).catch((error) => {
      // An error happened.
      console.error('SignOut Error');
    });
}

/**
 * Listen for changes in authentication state
 * @returns {void}
 * Use the onAuthStateChanged method to listen for changes in authentication state
 */
function AuthStateListener () {

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      console.log(user.displayName, uid);
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

}

/**
 * Retrieve user information
 * @returns {object} The current user object
 */
function UserInformation () {
  const user = getAuth().currentUser;

  if (user) {
    // If user is authenticated, retrieve the display name
    const { displayName, email, uid } = user;
    return { displayName, email, uid };
    //return user;
  } else {
    // Handle case where no user is authenticated
    console.log("No user signed in.");
    return null;
  }
}

/**
 * Retrieve the current user
 * @returns {string} The current user's first name
 * Use the currentUser method to retrieve the current user
 */
function CurrentUser () {
  const user = getAuth().currentUser;

  if (user) {
    // If user is authenticated, retrieve the display name
    const {displayName} = user;

    if (displayName) {
      // Split the display name into parts using a space as a separator
      const parts = displayName.split(' ');

      // The first part should be the first name
      const firstName = parts[0];
      //console.log(firstName);
      return firstName;
    } else {
      // Handle case where display name is not set
      console.log("User has no display name.");
      return null;
    }
  } else {
    // Handle case where no user is authenticated
    console.log("No user signed in.");
    return null;
  }
}

export { SignIn, SignOut, AuthStateListener, CurrentUser, UserInformation };
