/**
 * Database config
 *
 * Description: This module contains the configuration for the Firestore database
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-12-08
 * 
 */

import { db } from '../auth_mgmt/auth_config';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';

const dbLabelsCollection = collection(db, 'db_meta');

// Centralized error handling
const handleFirestoreError = (action, error) => {
  console.error(`Error during ${action}:`, error);
  throw new Error(`Failed to ${action}. Please try again.`);
};

// Function to fetch all database labels
export const fetchDbLabels = async () => {
  try {
    const querySnapshot = await getDocs(dbLabelsCollection);
    const dbLabels = {};
    querySnapshot.forEach((doc) => {
      dbLabels[doc.id] = doc.data();
    });
    return dbLabels;
  } catch (error) {
    handleFirestoreError('fetch all database labels', error);
  }
};

// Function to fetch a specific database label
export const fetchDbLabel = async (dbName) => {
  try {
    const docRef = doc(dbLabelsCollection, dbName);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  } catch (error) {
    handleFirestoreError('fetch the database label', error);
  }
};

// Function to add a new database label
export const addDbLabel = async (dbName, createdBy, active, meta) => {
  try {
    const newDoc = await addDoc(dbLabelsCollection, {
      dbName,
      createdBy,
      active,
      ...meta,
      created: serverTimestamp(),
      updated: serverTimestamp(),
    });
    console.log(`New database label "${dbName}" added successfully!`);
    return newDoc.id;
  } catch (error) {
    handleFirestoreError('add a new database label', error);
  }
};

// Function to update an existing database label
export const updateDbLabel = async (dbName, metaInfo) => {
  try {
    const docRef = doc(dbLabelsCollection, dbName);
    await updateDoc(docRef, {
      ...metaInfo,
      updated: serverTimestamp(),
    });
    console.log(`Database label "${dbName}" updated successfully!`);
  } catch (error) {
    handleFirestoreError('update the database label', error);
  }
};

// Function to delete a database label
export const deleteDbLabel = async (dbName) => {
  try {
    const docRef = doc(dbLabelsCollection, dbName);
    await deleteDoc(docRef);
    console.log(`Database label "${dbName}" deleted successfully!`);
  } catch (error) {
    handleFirestoreError('delete the database label', error);
  }
};