/**
 * Database management view
 *
 * Description: This module contains the database management view.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-12-08
 * Notes: /
 */

import React, { useEffect, useState, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Badge,
} from 'react-bootstrap';

//import { useTable, useFilters, useSortBy } from '@tanstack/react-table';

import {
    createColumnHelper,
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table';

import {
  fetchDbLabels,
  updateDbLabel,
  deleteDbLabel,
  addDbLabel,
} from '../components/db_mgmt/db_config';

import { UserInformation } from '../components/auth_mgmt/auth_handling';
import { getCurrentDateTime } from '../components/utils/utils_date';

const formatFirestoreTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
    // Convert Firestore timestamp to a JavaScript Date
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
    // Format the date as a readable string
    return date.toLocaleString(); // Adjust options as needed
    } else if (timestamp) {
    return timestamp; // Adjust options as needed
    }
    return 'N/A'; // Return fallback if timestamp is not valid
};

const DatabaseTable = ({ dbLabels, handleEdit, handleDeleteClick }) => {
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  
    // Define columns
    const columnHelper = createColumnHelper();
    const columns = [
      columnHelper.accessor((row, i) => i + 1, {
        id: 'number',
        header: '#',
      }),
      columnHelper.accessor('id', {
        header: 'DB-ID',
        cell: (info) => (
          <span
            style={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
              cursor: 'pointer',
            }}
            title={info.getValue()}
          >
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor('dbName', { header: 'Name' }),
      columnHelper.accessor('type', { header: 'Type' }),
      columnHelper.accessor('applicationArea', { header: 'Area' }),
      columnHelper.accessor('stage', { header: 'Stage' }),
      columnHelper.accessor('active', {
        header: 'Status',
        cell: (info) =>
          info.getValue() ? (
            <Badge bg="success">Active</Badge>
          ) : (
            <Badge bg="warning">Inactive</Badge>
          ),
      }),
      columnHelper.accessor('schema', { header: 'Meta (Schema)' }),
      columnHelper.accessor('purpose', { header: 'Purpose' }),
      columnHelper.accessor('createdBy', { header: 'Owner' }),
      columnHelper.accessor('created', {
        header: 'Created',
        cell: (info) => formatFirestoreTimestamp(info.getValue()),
      }),
      columnHelper.accessor('updated', {
        header: 'Updated',
        cell: (info) => formatFirestoreTimestamp(info.getValue()),
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: (info) => {
          const { id, ...data } = info.row.original;
          return (
            <div>
              <Button
                variant="outline-warning"
                size="sm"
                onClick={() => handleEdit(id, data)}
                className="me-2"
                style={{ marginRight: '5px', minWidth: '60px' }}
              >
                Edit
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => handleDeleteClick(id)}
                style={{ minWidth: '60px' }}
              >
                Delete
              </Button>
            </div>
          );
        },
      }),
    ];
  
    // Prepare data
    const data = useMemo(
      () =>
        Object.entries(dbLabels).map(([db, data]) => ({
          id: db,
          ...data,
          type: data.meta?.type || 'N/A',
          applicationArea: data.meta?.applicationArea || 'N/A',
          stage: data.meta?.stage || 'N/A',
          schema: data.meta?.schema || 'N/A',
          purpose: data.meta?.purpose || 'N/A',
          created: data.created,
          updated: data.updated,
        })),
      [dbLabels]
    );
  
    // Use React Table
    const table = useReactTable({
      columns,
      data,
      state: {
        globalFilter,
        sorting,
        pagination,
      },
      onGlobalFilterChange: setGlobalFilter,
      onSortingChange: setSorting,
      onPaginationChange: setPagination,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    });
  
    return (
      <div>
        {/* Global Search */}
        <div className="mb-3" align='right'>
          <input
            value={globalFilter || ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            style={{
                fontSize: '1.1rem',
                padding: '5px',
                marginBottom: '10px',
                width: '300px',
                border: '1px solid #ccc', // Optional: Customize the border
                borderRadius: '10px', // Makes the input rounded
                outline: 'none', // Removes focus outline
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', // Optional: Adds subtle shadow
              }}
          />
        </div>
  
        {/* Table */}
        <Table bordered hover className="small">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      cursor: header.column.getCanSort() ? 'pointer' : 'default',
                      whiteSpace: 'nowrap', // Prevent line breaks
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      {header.isPlaceholder ? null : header.column.columnDef.header}
                      {header.column.getIsSorted() === 'asc' ? (
                        <span> 🔼</span>
                      ) : header.column.getIsSorted() === 'desc' ? (
                        <span> 🔽</span>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{cell.column.columnDef.cell(cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
  
        {/* Pagination */}
        <div className="pagination d-flex justify-content-between align-items-center mt-3">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <span>
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </span>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>

      </div>
    );
};
  
// Simple table (Not used)
/*
const SimpleTable = ({ dbLabels, handleEdit, handleDeleteClick }) => {

    return (
        <Row>
        <Col>
        <Table bordered hover className='small'>
            <thead>
            <tr>
                <th>#</th>
                <th>DB-ID</th>
                <th>Name</th>
                <th>Type</th>
                <th>Area</th>
                <th>Stage</th>
                <th>Status</th>               
                <th>Meta (Schema)</th>
                <th>Purpose</th>
                <th>Owner</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody className='small' style={{ verticalAlign: 'middle' }}>
            {Object.entries(dbLabels).map(([db, data]) => (
                <tr key={db}>
                <td>{Object.keys(dbLabels).indexOf(db) + 1}</td>
                <td>
                    <span
                        style={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100px', // Adjust this to control how many letters are shown
                        cursor: 'pointer',
                        }}
                        title={db} // Display the full database ID on hover tooltip
                    >
                        {db}
                    </span>
                </td>
                <td>{data?.dbName || 'N/A'}</td>
                <td>{data?.meta?.type || 'N/A'}</td>
                <td>{data?.meta?.applicationArea || 'N/A'}</td>
                <td>{data?.meta?.stage || 'N/A'}</td>
                <td>
                    {data.active ? (
                    <Badge bg="success" style={ { minWidth:'50px' } }>Active</Badge>
                    ) : (
                    <Badge bg="warning" style={ { minWidth:'50px' } }>Inactive</Badge>
                    )}
                </td>
                <td>{data?.meta?.schema || 'N/A'}</td>
                <td>{data?.meta?.purpose || 'N/A'}</td>
                <td>{data?.createdBy || 'N/A'}</td>                    
                <td>{formatFirestoreTimestamp(data.created) || 'N/A'}</td> 
                <td>{formatFirestoreTimestamp(data.updated) || 'N/A'}</td>
                <td style={ { minWidth:'140px' } }>
                    <div>
                        <Button
                            variant="outline-warning"
                            size="sm"
                            onClick={() => handleEdit(db, data)}
                            className="me-2"
                            style={{ marginRight: '5px', minWidth: '60px' }}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeleteClick(db)}
                            style={{ minWidth: '60px' }}
                        >
                            Delete
                        </Button>                            
                    </div>
                </td>
                </tr>
            ))}
            </tbody>
        </Table>
        </Col>
    </Row>
    );
};
*/

export default function DB() {
    
    const [dbLabels, setDbLabels] = useState({}); // Object to store all DB labels, document IDs
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
    const [currentDb, setCurrentDb] = useState(null);

    const [newMeta, setNewMeta] = useState({});

    const [newDbName, setNewDbName] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [active, setActive] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dbToDelete, setDbToDelete] = useState(null);

    // Fetch all DB labels when the component mounts
    useEffect(() => {    
        fetchDbLabels().then((labels) => setDbLabels(labels));
    }, []);

    const handleSave = async () => {

        if (newDbName) {

        const meta = {
            dbName: newDbName,
            meta: newMeta, // { type, applicationArea, stage, schema, purpose }
            createdBy: UserInformation().displayName || createdBy,
            active,
            //created: getCurrentDateTime(),
            //updated: getCurrentDateTime(), //new Date().toISOString(),
        };
        
        if (modalMode === 'add') {

            meta.created = getCurrentDateTime() //new Date().toISOString();
            await addDbLabel(newDbName, createdBy, active, meta);

        } else if (modalMode === 'edit' && currentDb) {

            meta.updated = getCurrentDateTime();
            await updateDbLabel(currentDb, meta);

        }
        const updatedLabels = await fetchDbLabels();
        setDbLabels(updatedLabels);
        setShowModal(false);
        resetForm();
        }
    };

    const handleDelete = async (dbName) => {
        await deleteDbLabel(dbName);
        const updatedLabels = await fetchDbLabels();
        setDbLabels(updatedLabels);
    };

    const handleEdit = (dbName, labelData) => {
        setModalMode('edit');
        setShowModal(true);
        setCurrentDb(dbName); // document id
        setNewDbName(labelData.dbName || '');
        setNewMeta(labelData.meta || {});
        setCreatedBy(labelData.createdBy || '');
        setActive(labelData.active || false);
    };

    const resetForm = () => {
        setNewDbName('');
        setNewMeta({});
        setCreatedBy(UserInformation().displayName);
        setActive(false);
        setCurrentDb(null);
        setModalMode('add');
    };

    const handleDeleteClick = (db) => {
        setDbToDelete(db); // Store the DB to be deleted
        setShowDeleteModal(true); // Show the confirmation modal
    };

    const handleConfirmDelete = async () => {
        if (dbToDelete) {
        try {
            await handleDelete(dbToDelete); // Call the delete handler
            setShowDeleteModal(false); // Close the modal
            setDbToDelete(null); // Reset the DB to delete
        } catch (error) {
            console.error('Error deleting database:', error);
        }
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteModal(false); // Close the modal
        setDbToDelete(null); // Reset the DB to delete
    };

    //console.log('dbLabels:', dbLabels);    

    return (
        <Container>
            <Row style={ { marginTop:'30px', marginBottom:'30px' } }>
                <Col>
                    <h2>UCM Database Management</h2>
                </Col>
                <Col className="text-end">
                <Button
                    className='btn-sm'
                    variant='outline-primary'
                    onClick={() => {
                    resetForm();
                    setShowModal(true);
                    }}
                >
                    Add New Database
                </Button>
                </Col>
            </Row>

            {/* Display the database table */}
            <Row>
                <DatabaseTable
                    dbLabels={dbLabels}
                    handleEdit={handleEdit}
                    handleDeleteClick={handleDeleteClick}
                />
            </Row>

            {/* Modal for adding/editing a database */}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg" // Makes the modal larger
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalMode === 'add' ? 'Add New Database' : 'Edit Database'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Row>
                        {/* Database Name */}
                        <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Database Name</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder='db_name'
                            value={newDbName}
                            onChange={(e) => setNewDbName(e.target.value)}
                            //disabled={modalMode === 'edit'}
                            />
                        </Form.Group>
                        </Col>

                        {/* Type DB Dropdown */}
                        <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Form.Select
                            value={newMeta?.type || ''}
                            onChange={(e) =>
                                setNewMeta((prev) => ({
                                    ...prev,
                                    type: e.target.value,
                                }))
                            }
                            >
                                <option value="">Select a type</option>
                                <option value="Test">Test</option>
                                <option value="Meta">Meta</option>
                                <option value="Ops">Ops</option>
                                <option value="Study">Study</option>
                            </Form.Select>
                        </Form.Group>
                        </Col>

                        {/* Application Area Dropdown */}
                        <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Application Area</Form.Label>
                            <Form.Select
                            value={newMeta.applicationArea || ''}
                            onChange={(e) =>
                                setNewMeta((prev) => ({
                                    ...prev,
                                    applicationArea: e.target.value,
                                }))
                            }
                            >
                                <option value="">Select an application area</option>
                                <option value="db_use_case">Use Case</option>
                                <option value="db_participants">Participants</option>
                                <option value="db_ontology">Ontology</option>
                                <option value="db_survey">Survey</option>
                                <option value="db_interview">Interview</option>
                                <option value="db_metadata">Metadata</option>
                            </Form.Select>
                        </Form.Group>
                        </Col>    

                        {/* Stage Dropdown (alpha, beta, study, operations, general) */}      
                        <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>Stage</Form.Label>
                            <Form.Select
                            value={newMeta.stage || ''}
                            onChange={(e) =>
                                setNewMeta((prev) => ({
                                    ...prev,
                                    stage: e.target.value,
                                }))
                            }
                            >
                            <option value="">Select</option>
                            <option value="Alpha">Alpha</option>
                            <option value="Beta">Beta</option>
                            <option value="Study">Study</option>
                            <option value="Ops">Operations</option>
                            <option value="General">General</option>
                            </Form.Select>
                        </Form.Group>
                        </Col>          
                    </Row>
                    <Row>
                        {/* Purpose Text Area */}
                        <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Purpose</Form.Label>
                            <Form.Control
                            as="textarea"
                            rows={3}
                            value={newMeta.purpose || ''}
                            onChange={(e) =>
                                setNewMeta((prev) => ({
                                ...prev,
                                purpose: e.target.value,
                                }))
                            }
                            placeholder="Describe the purpose of this database."
                            />
                        </Form.Group>
                        </Col>

                        {/* Meta Information Text Area */}
                        <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Meta Information</Form.Label>
                            <Form.Control
                            as="textarea"
                            rows={3}
                            value={newMeta.schema || ''}
                            onChange={(e) =>
                                setNewMeta((prev) => ({
                                ...prev,
                                schema: e.target.value,
                                }))
                            }
                            placeholder="Additional metadata or notes for this database."
                            />
                        </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        {/* Created By */}
                        <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Created By</Form.Label>
                            <Form.Control
                            type="text"
                            value={createdBy}
                            onChange={(e) => setCreatedBy(e.target.value)}
                            placeholder="Enter the creator's name or email."
                            />
                        </Form.Group>
                        </Col>

                        {/* Active Toggle */}
                        <Col md={6} className="d-flex align-items-center">
                        <Form.Check
                            style={{ marginTop: '15px' }}
                            type="switch"
                            id="active-switch"
                            label="Active DB in the system"
                            checked={active}
                            onChange={(e) => setActive(e.target.checked)}
                        />                    
                        </Col>
                    </Row>
                    </Form>

                    {/* Display the current DB ID if in edit mode */}
                    {modalMode === 'edit' && (
                    <div className="small">
                        <i><strong>Database ID:</strong> {currentDb}</i>
                    </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={handleCancelDelete} centered>
                <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Are you sure you want to delete <b>{dbToDelete}</b>? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCancelDelete}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleConfirmDelete}>
                    Delete
                </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export { default as DB } from './DB';