/**
 * Prompt page
 *
 * Description: This module contains functions to generate prompts for the chat.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-23 (last updated on June 30,2024)
 * 
 */


import React, { useState } from 'react';
import { Container, Button, Row, Col, Tab, Accordion, Badge, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import '../Approach.css';
  
const CustomNav = () => {
    const [activeKey, setActiveKey] = useState('1');

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };

    const step_names = ['Motivation', 'Problem statement', 'SoTa', 'Approach to PS', 'Methodology', 'Findings', 'Current status'];

    return (
        <Nav
        className="flex-column me-3"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
        style={{ textAlign: 'left', width: '250px' }}
        activeKey={activeKey}
        onSelect={handleSelect}
        >
        {['1', '2', '3', '4', '5', '6', '7'].map((step, index) => (
            <Nav.Item className="mb-2" key={step}>
                <Nav.Link
                    eventKey={step}
                    style={{
                    border: activeKey === step ? '2px solid #007bff' : '2px solid transparent',
                    borderRadius: '5px',
                    }}
                >
                    {index + 1}. {step_names[index]}
                </Nav.Link>
            </Nav.Item>
        ))}
        </Nav>
    );
};
  
const AccordionComponent = ({ step_name, description, status, paper, scheduled, review, content }) => (
<Accordion defaultActiveKey="1">

    <Accordion.Item eventKey="1">
        <Accordion.Header>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>            
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <Badge bg="info" text="dark" style={{ marginRight: '10px' }}>{step_name}</Badge>
            <span style={{ marginRight: '10px' }}>{description}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginRight:'15px' }}>
            <Badge bg="success" text="light" style={{ marginLeft: 'auto' }}>Status: {status}</Badge>
            <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>Paper: {paper}</Badge>
            <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>Scheduled: {scheduled}</Badge>
            <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>Validate: {review}</Badge>
            </div>
        </div>
        </Accordion.Header>
        <Accordion.Body>
            {content}
        </Accordion.Body>
    </Accordion.Item>

</Accordion>
);

const DetailsApproach = () => (
    <Tab.Container id="v-pills-tab" defaultActiveKey="1">
    <div className="d-flex align-items-start">
        <CustomNav />
        <div style={{ width: '100%' }}>
        <Tab.Content>
            <Tab.Pane eventKey="1">
                <AccordionComponent 
                    step_name='Scope'
                    description='Platform Economy for Autonomous Mobility Reference Model'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                        The reference model is a comprehensive framework for the development of platform-based solutions in the context of autonomous mobility. 
                        It includes the identification of the main actors, components, and interactions, as well as the classification of the involved entities, 
                        means of transport, and transport modes. The model is based on the Distributed Artificial Intelligence (DAI) approach and is designed to 
                        support the development of Cooperative, Connected, and Automated Mobility (CCAM) solutions.
                        </p>
                    }
                />            
            </Tab.Pane>
            <Tab.Pane eventKey="2">
                <AccordionComponent
                    step_name='Addressed'
                    description='Pose a model for AM use cases in the PE'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                            The model is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative,
                            Connected, and Automated Mobility (CCAM) solutions. It provides a framework for building DAI-based CCAM solutions in the PE and comprises
                            the creation of actor maps, identification of the involved entities, means of transport and transport mode, assuming different degrees of
                            digitalization, data points and flows across entities as well as platform economy principles within which such a solution is placed in.
                        </p>
                    }
                />                    
            </Tab.Pane>
            {/* SoTa */}
            <Tab.Pane eventKey="3">
                <AccordionComponent
                    step_name='Classification'
                    description='Theoretical Background of the Research'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <div>
                        <p>
                            ... reference to the theoretical background of the research ...                           
                        </p>
                        <p>
                            What is the status in modeling use cases?
                            What is the role of prompt engineering and LLMs? what current work exists
                            in the field of prompt engineering and LLMs?
                        </p>
                        </div>
                    } 
                />
            </Tab.Pane>
            {/* Approach to PS */}
            <Tab.Pane eventKey="4">
                <AccordionComponent
                    step_name='Approach'
                    description='Use Case Engineering and Qualitative Inquiry'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                            The tool is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative, 
                            Connected and Automated Mobility (CCAM) solutions.

                            It provides a framework for building DAI-based CCAM solutions in the PE and comprises the creation of actor maps, identification of the involved entities, 
                            means of transport and transport mode, assuming different degrees of digitalization, data points and flows across entities as well as platform economy 
                            principles within which such a solution is placed in.
                            <br />

                            Describe the developed approach; use a chart/figure to visualize the process.
                        </p>
                    } 
                />
            </Tab.Pane>
            {/* Methodology */}
            <Tab.Pane eventKey="5">
                <AccordionComponent 
                    step_name='Method'
                    description='UCM Use Case Engineering, Analysis and Qualitative Inquiry'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <div>
                        <p>
                            The tool is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative, 
                            Connected and Automated Mobility (CCAM) solutions.

                            It provides a framework for building DAI-based CCAM solutions in the PE and comprises the creation of actor maps, identification of the involved entities, 
                            means of transport and transport mode, assuming different degrees of digitalization, data points and flows across entities as well as platform economy 
                            principles within which such a solution is placed in.
                        </p>
                        <p>
                            Develop a survey page as reference; questions are: 1) how easy did you find the process for creating a use case; 
                            2) what is your domain of expertise? 3) how valuable in general do you find identifying/framing use cases? 
                            4) with regards to the use case mapper: how would you classify it: helpful, not helpful; 
                            5) how helpful were the suggested components? In what areas did it help to model a case? 
                            6) do you think it could be helpful in some of your use case modeling activities? 7) what is missing? 
                            8) what would be helpful? 
                            9) are you satifified with the result given by the matter? 
                            10) did the modeled components help you further develop your case?
                        </p>
                        <p>
                            Ask users about the tool: 
                            1) how easy did you find the process for creating a use case; <br />
                            2) what is your domain of expertise? <br />
                            3) how valuable in general do you find identifying/framing use cases? <br />
                            4) with regards to the use case mapper: how would you classify it: helpful, not helpful; <br />
                            5) how helpful were the suggested components? In what areas did it help to model a case? <br />
                            6) do you think it could be helpful in some of your use case modeling activities? <br />
                            7) what is missing? <br />
                            8) what would be helpful? <br />
                            9) are you satifified with the result given by the matter? <br />
                            10) did the modeled components help you further develop your case?

                            General about use case engineering
                            Then about the tool and how it addresses it
                            Then about the specific use case modeled in the session
                        </p>
                        </div>
                    }
                />
            </Tab.Pane>
            <Tab.Pane eventKey="6">
                <AccordionComponent 
                    step_name='Method'
                    description='UCM Use Case Engineering, Analysis and Qualitative Inquiry'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                            The tool is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative, 
                            Connected and Automated Mobility (CCAM) solutions.

                            It provides a framework for building DAI-based CCAM solutions in the PE and comprises the creation of actor maps, identification of the involved entities, 
                            means of transport and transport mode, assuming different degrees of digitalization, data points and flows across entities as well as platform economy 
                            principles within which such a solution is placed in.
                        </p>
                    }
                />
            </Tab.Pane>
            <Tab.Pane eventKey="7">
                <AccordionComponent 
                    step_name='Method'
                    description='UCM Use Case Engineering, Analysis and Qualitative Inquiry'
                    status='Framed'
                    paper='[2021, 2022, 2024]'
                    scheduled='Aug, 2024'
                    review='Sept, 2024'
                    content={
                        <p>
                            The tool is based on the Distributed Artificial Intelligence (DAI) approach and is designed to support the development of Cooperative, 
                            Connected and Automated Mobility (CCAM) solutions.

                            It provides a framework for building DAI-based CCAM solutions in the PE and comprises the creation of actor maps, identification of the involved entities, 
                            means of transport and transport mode, assuming different degrees of digitalization, data points and flows across entities as well as platform economy 
                            principles within which such a solution is placed in.
                        </p>
                    }
                />
            </Tab.Pane>
            
        </Tab.Content>
        </ div>
    </div>
    </Tab.Container>
);

const SemanticModelWorkflow = () => {
  const [modalContent, setModalContent] = useState(null);

  const handleOpenModal = (content) => {
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setModalContent(null);
  };

  const modalData = {
    semanticModel: {
      title: "Semantic Model",
      body: 
        "A semantic model represents a tree-like structure of concepts and relationships. " +
        "This is where class hierarchies are defined, starting from high-level classes (e.g., Components, Data) " +
        "to their subclasses (e.g., Middleware, Control). The model serves as the foundation for semantic enrichment and mapping.",
    },
    matchingModule: {
      title: "Matching Module",
      body: 
        "The Matching Module connects the Semantic Model with the UCM component architecture. " + 
        "Each concept is mapped to 'Primary,' 'Secondary,' and 'Meta' fields for specific components, " + 
        "such as Operations Management, Producer Analytics, and Technical Layer. " + 
        "This step ensures that the semantic model aligns with the use case requirements.",
    },
    generatorModule: {
      title: "Generator Module",
      body: 
        "The Generator Module takes a given expert's input prompt into the UCM method, " +
        "combining pre-defined prompts of the UCM method with LLM interactions, while " + 
        "taking into considerations the mappings executed from the previous module to create a structured output. " + 
        "This output represents a component architecture specific to the selected use case.",
    },
    architecture: {
      title: "Generated Architecture",
      body: 
        "The Generated Architecture is the final outcome of the UCM method." + 
        "It is a schematic representation of the use case-specific architecture, " + 
        "showcasing interconnected components for Operations, Producer, Consumer, Technical Layer, and Regulator domains. " + 
        "This detailed architecture helps experts visualize the system design, and help validating or building novel solutions.",
    },
  };

  return (
    <div className="container my-5">
        <div className="row align-items-center">
            {/* Semantic Model */}
            <div
            className="col-md-2 module clickable"
            onClick={() => handleOpenModal(modalData.semanticModel)}
            >
            <h4 className="text-center module-title">Semantic Model</h4>
            <div className="semantic-tree">
                <ul>
                <li>
                    <strong>Class A</strong>
                    <ul>
                    <li>Subclass A1</li>
                    <li>
                        Subclass A2
                        <ul>
                        <li>Subclass A2.1</li>
                        <li>Subclass A2.2</li>
                        </ul>
                    </li>
                    </ul>
                </li>
                <li>
                    <strong>Class B</strong>
                    <ul>
                    <li>Subclass B1</li>
                    <li>Subclass B2</li>
                    </ul>
                </li>
                </ul>
            </div>
            </div>

            {/* Arrow */}
            <div className="col-md-1 text-center">
            <i className="bi bi-arrow-right-circle-fill fancy-arrow"></i>
            </div>

            {/* Matching Module */}
            <div
            className="col-md-2 module clickable"
            onClick={() => handleOpenModal(modalData.matchingModule)}
            >
            <h4 className="text-center module-title">Matching Module</h4>
            <div className="matching-module small">
                <ul>
                <li>
                    <strong>Operations:</strong>
                    <ul>
                    <li>Operations Management</li>
                    <li>Operations Analytics</li>
                    <li>Operations Platform</li>
                    </ul>
                </li>
                <li>
                    <strong>Producer:</strong>
                    <ul>
                    <li>Producer Analytics</li>
                    <li>Producer Management</li>
                    <li>Producer Platform</li>
                    </ul>
                </li>
                <li>
                    <strong>Technical Layer:</strong>
                    <ul>
                    <li>Hardware</li>
                    <li>Middleware</li>
                    <li>Control</li>
                    <li>...</li>
                    </ul>
                </li>
                </ul>
            </div>
            </div>

            {/* Arrow */}
            <div className="col-md-1 text-center">
            <i className="bi bi-arrow-right-circle-fill fancy-arrow"></i>
            </div>

            {/* Generator Module */}
            <div
            className="col-md-2 module clickable"
            onClick={() => handleOpenModal(modalData.generatorModule)}
            >
            <h4 className="text-center module-title">Generator Module</h4>
            <div className="generator-module">
                <p>
                <i className="bi bi-brain"></i> <strong>Combining Elements</strong>
                </p>
                <ul>
                <li>Expert Input</li>
                <li>Pre-defined Prompts</li>
                <li>UCM 4-Step-Approach</li>
                <li>LLM Interaction</li>
                <li>Generated Outputs</li>
                </ul>
            </div>        
            </div>
            

            {/* Arrow */}
            <div className="col-md-1 text-center">
            <i className="bi bi-arrow-right-circle-fill fancy-arrow"></i>
            </div>

            {/* Architecture */}
            <div
            className="col-md-3 module clickable"
            onClick={() => handleOpenModal(modalData.architecture)}
            >
            <h4 className="text-center module-title">Generated Architecture</h4>
            <div className="architecture">
                <div className="architecture-box">Operations</div>
                <div className="architecture-box">Producer</div>
                <div className="architecture-box">Consumer</div>
                <div className="architecture-box">Technical Layer</div>
                <div className="architecture-box">Regulator</div>
            </div>
            </div>
        </div>

        {/* Modal */}
        {modalContent && (
            <div className="modal fade show d-block" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">{modalContent.title}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={handleCloseModal}
                    ></button>
                    </div>
                    <div className="modal-body">
                    {modalContent.title === "Matching Module" ? (
                        <div>
                        <p>{modalContent.body}</p>
                        <h6 className="text-center mb-3">Matching Matrix</h6>                    
                        <table className="table table-bordered text-center">
                            <thead className="table-light">
                            <tr>
                                <th>Component</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                                <th>Meta</th>
                            </tr>
                            </thead>
                            <tbody>
                            {[
                                { component: "Operations Management" },
                                { component: "Operations Analytics" },
                                { component: "..." },
                                { component: "Technical Layer" },
                                { component: "Regulator" },
                            ].map((row, index) => (
                                <tr key={index}>
                                <td>{row.component}</td>
                                <td>
                                    <div className="minimal-field">
                                    <i className="bi bi-pencil-fill"></i>
                                    </div>
                                </td>
                                <td>
                                    <div className="minimal-field">
                                    <i className="bi bi-pencil-fill"></i>
                                    </div>
                                </td>
                                <td>
                                    <div className="minimal-field">
                                    <i className="bi bi-pencil-fill"></i>
                                    </div>
                                </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    ) : (
                        <p>{modalContent.body}</p>
                    )}
                    {modalContent.title === "Generator Module" && (
                        <div>
                        <h6 className="text-center mb-3">Process</h6>
                            <div className="generator-module">
                            <div className="row justify-content-center align-items-center generator-flow">
                                {/* Expert Input */}
                                <div className="col-md-2 text-center">
                                <div className="flow-box small-box">
                                    <i className="bi bi-person-fill"></i>
                                    <h6>Expert Input</h6>
                                </div>
                                </div>
                                {/* Arrows */}
                                <div className="col-md-1 text-center">
                                <i className="bi bi-arrow-right-circle-fill flow-arrow"></i>
                                </div>
                                {/* Pre-defined Prompts */}
                                <div className="col-md-2 text-center">
                                <div className="flow-box small-box">
                                    <i className="bi bi-list-task"></i>
                                    <h6>Pre-defined Prompts</h6>
                                </div>
                                </div>
                                {/* Arrows */}
                                <div className="col-md-1 text-center">
                                <i className="bi bi-arrow-left-right flow-arrow"></i>
                                </div>
                                {/* UCM 4-Step-Approach */}
                                <div className="col-md-2 text-center">
                                <div className="flow-box small-box">
                                    <i className="bi bi-diagram-3-fill"></i>
                                    <h6>UCM 4-Step Approach</h6>
                                </div>
                                </div>
                                {/* Arrows */}
                                <div className="col-md-1 text-center">
                                <i className="bi bi-arrow-left-right flow-arrow"></i>
                                </div>
                                {/* LLM Interaction */}
                                <div className="col-md-2 text-center">
                                <div className="flow-box small-box">
                                    <i className="bi bi-cpu"></i>
                                    <h6>LLM Interaction</h6>
                                </div>
                                </div>
                                {/* Arrows */}
                                <div className="col-md-1 text-center">
                                <i className="bi bi-arrow-right-circle-fill flow-arrow"></i>
                                </div>
                                {/* Generated Outputs */}
                                <div className="col-md-2 text-center">
                                <div className="flow-box small-box">
                                    <i className="bi bi-file-earmark-code"></i>
                                    <h6>Generated Outputs</h6>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                    </div>
                    <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleCloseModal}
                    >
                        Close
                    </button>
                    </div>
                </div>
                </div>
            </div>
        )}

    </div>
  );
};


export default function Approach () {

    const [isApproach, setIsApproach] = useState(false);

    const navigate = useNavigate();

    const navigateStudy = () => {
        navigate('/Participate');
    }

    const navigateAbout = () => {
        navigate('/About');
    }

    const handleApproach = () => {
        setIsApproach(!isApproach);
    }

    return (
        <Container fluid>
            {/* Intro */}
            <Row align='center'>      
                <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Context and the Underlying Approach</b></h1>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                    <i>ucm</i> leverages LLMs and semantic models to support Ontology-Driven Use Case Mapping with emphasis on Autonomous Mobility (AM). The purpose of this tool is to provide a structured approach to developing platform-based solutions.
                    Modeled use cases will be analyzed and validated in order to propose an AM reference model.
                </p>
            </Row>

            <Row>
                <Col md={12}>
                    <SemanticModelWorkflow />
                </Col>
            </Row>


            {/* Classification */}         
            <Row align='center'>
                <h3 style={{marginTop: '30px', marginBottom: '30px'}}>Structure and Approach</h3>         
            </Row>
            {/* Learn about the approach */}
            <Row align='center'>
                <div>
                    <OverlayTrigger overlay={<Tooltip>Back to About page</Tooltip>}>
                        <Button variant="outline-secondary" style={{ marginRight: '10px' }} onClick={navigateAbout} disabled>
                        <i className="bi bi-arrow-left"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Learn about the approach</Tooltip>}>
                    <Button variant="outline-primary" style={{ marginRight: '10px' }} onClick={handleApproach} disabled>
                        {isApproach ? 
                            <>
                            <i className='bi bi-x-circle'></i> Hide
                            </>
                            : 
                            <>
                            <i className="bi bi-pencil"></i> Learn about the approach
                            </>
                        }
                    </Button>
                    </OverlayTrigger>
                </div>            
            </Row>

            {/* Approach to Problem Solving and Contribution */}
            {isApproach && (
                <>
                <h3 align='center' style={ { marginTop:'30px', marginBottom:'30px' } }>Approach to Problem Solving and Contribution</h3>
                <Row style={ { marginTop:'15px'} }>
                    <Col md={1}></Col>
                    <Col md={10}>
                        <DetailsApproach />       
                    </Col>
                    <Col md={1}></Col>
                </Row>
                <Row align='center'>
                    <Col>
                        <OverlayTrigger overlay={<Tooltip>Participate in the Study</Tooltip>}>
                        <Button variant="success" style={{ marginRight: '10px', minHeight:'50px', width:'300px', marginTop: '30px' }} onClick={navigateStudy}>
                            <i className="bi bi-pencil"></i> Participate in the Study
                        </Button>
                        </OverlayTrigger>
                    </Col>
                </Row>
                </>
            )}
        </Container>
    );
}

export { default as Approach } from './Approach';