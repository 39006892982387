/**
 * Transform data from old to new state if applicable
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-26
 * 
 */

import { getCurrentDate } from '../utils/utils_date';

export const transformToNewStructure = (selectedUseCase) => {

    // Check if the use case is already in the new structure
    if (selectedUseCase?.user?.value) {
        // Already in the new structure, no need to transform

        // Update the date object (can be removed later)
        selectedUseCase.created = {
            value: selectedUseCase.created || getCurrentDate(),
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        };
        selectedUseCase.updated = {
            value: selectedUseCase.updated || getCurrentDate(),
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        };

        return selectedUseCase;
    }
  
    const formattedActorList = {
        Producer: {
            value: selectedUseCase?.actors?.list.Producer || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        Consumer: {
            value: selectedUseCase?.actors?.list.Consumer || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        Regulator: {
            value: selectedUseCase?.actors?.list.Regulator || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        Technical: {
            value: selectedUseCase?.actors?.list.Technical || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        Operator: {
            value: selectedUseCase?.actors?.list.Operator || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        Platform: {
            value: selectedUseCase?.actors?.list.Platform || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        }
    };
  
    const formattedData = {
        id: selectedUseCase.id || '',
        title: {
            value: selectedUseCase.title,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        acronym: {
            value: selectedUseCase.acronym,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        maturity: {
            value: selectedUseCase.maturity,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        application: {
            value: selectedUseCase.application,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        status: {
            value: selectedUseCase.status,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        tags: {
            value: selectedUseCase.tags || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        city: {
            value: selectedUseCase.city || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        country: {
            value: selectedUseCase.country || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        continents: {
            value: selectedUseCase.continents || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        country_code: {
            value: selectedUseCase.country_code || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        country_flag: {
            value: selectedUseCase.country_flag || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        description: {
            value: selectedUseCase.description || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        actions: {
            value: selectedUseCase.actions || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        conditions: {
            value: selectedUseCase.conditions || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        actors: {
            value: {
            list: formattedActorList,
            nodes: {
                value: selectedUseCase?.actors?.nodes || [],
                source: 'manual',
                modified: false,
                created_timestamp: getCurrentDate(),
                updated_timestamp: null,
                updated_by: '',
                version: 1,
                priority: 'normal'
            },
            edges: {
                value: selectedUseCase?.actors?.edges || [],
                source: 'manual',
                modified: false,
                created_timestamp: getCurrentDate(),
                updated_timestamp: null,
                updated_by: '',
                version: 1,
                priority: 'normal'
            },
            },
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        components: {
            value: selectedUseCase?.components || [],
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        created: {
            value: selectedUseCase?.created || getCurrentDate(),
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        updated: {
            value: selectedUseCase?.updated || getCurrentDate(),
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        originally_created: {
            value: selectedUseCase?.originally_created || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        created_by: {
            value: selectedUseCase?.created_by || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        originally_created_by: {
            value: selectedUseCase?.originally_created_by || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        ucid: {
            value: selectedUseCase?.ucid || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        visibility: {
            value: selectedUseCase?.visibility || true,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        adoption: {
            value: selectedUseCase?.adoption || false,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        share: {
            value: selectedUseCase?.share || false,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        semantic_model: {
            value: selectedUseCase?.semantic_model || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        domain_model: {
            value: selectedUseCase?.domain_model || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        llm_model: {
            value: selectedUseCase?.llm || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        file: {
            value: selectedUseCase?.file || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        user: {
            value: selectedUseCase?.user || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        user_first_name: {
            value: selectedUseCase?.user_first_name || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        },
        user_id: {
            value: selectedUseCase?.user_id || '',
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDate(),
            updated_timestamp: null,
            updated_by: '',
            version: 1,
            priority: 'normal',
            relevance: 'normal'
        }
    };
  
    return formattedData;
};  