/**
 * Database Management - Write interview data
 *
 * Description: This module contains functions to write data to the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 * 
 */

import { doc, updateDoc, deleteDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

import { fetchDbConfigurations } from '../db_config_adapter';

//const db_name = 'beta_interview';

// retrieve the database configuration
const identifyInterviewDbConfig = async () => {
  try {

    // Fetch the database configuration
    const dbConfigs = await fetchDbConfigurations();
    const interviewDbConfig = dbConfigs.find(config => config.meta.applicationArea === "db_interview" && config.active);
    return interviewDbConfig.dbName;

  } catch (error) {
    console.error('Error fetching database configurations:', error);
    return null;
  }
};

const saveInterviewData = async (interviewData) => {
  
  try {

    const db_name = await identifyInterviewDbConfig();

    await addDoc(collection(db, db_name), {
      ...interviewData,
      created: serverTimestamp(), // Use Firestore server timestamp
      updated: serverTimestamp()
    });
    console.log('Interview data saved successfully!');
  } catch (error) {
    console.error('Error saving interview data: ', error);
  }
};

const updateInterviewData = async (interviewData) => {
  
  try {
    const db_name = await identifyInterviewDbConfig();
    const interviewDataDocRef = doc(db, db_name, interviewData.id);
    await updateDoc(interviewDataDocRef, {
      ...interviewData,
      updated: serverTimestamp()
    });
    console.log('Interview data updated successfully!');
  } catch (error) {
    console.error('Error updating interview data: ', error);
  }
  
};

// Delete a use case
const deleteInterviewData = async (interviewID) => {
  
  try {
    const db_name = await identifyInterviewDbConfig();
    const interviewDataDocRef = doc(db, db_name, interviewID);
    await deleteDoc(interviewDataDocRef);
    console.log('Interview data deleted successfully!');
  } catch (error) {
    console.error('Error deleting interview data: ', error);
  }
};

export { saveInterviewData, updateInterviewData, deleteInterviewData };