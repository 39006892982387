/**
 * Database Management - Write ontology data
 *
 * Description: This module contains functions to write data to the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-11-21
 * 
 */

import { doc, updateDoc, deleteDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

import { fetchDbConfigurations } from '../db_config_adapter';

//const db_name = 'ontologies';

// retrieve the database configuration
const identifyInterviewDbConfig = async () => {
  try {

    // Fetch the database configuration
    const dbConfigs = await fetchDbConfigurations();
    const interviewDbConfig = dbConfigs.find(config => config.meta.applicationArea === "db_ontology" && config.active);
    return interviewDbConfig.dbName;

  } catch (error) {
    console.error('Error fetching database configurations:', error);
    return null;
  }
};

const saveOntologyData = async (ontologyData) => {
  try {

    const db_name = await identifyInterviewDbConfig();

    await addDoc(collection(db, db_name), {
      ...ontologyData,
      created: serverTimestamp(), // Use Firestore server timestamp
      updated: serverTimestamp()
    });
    console.log('Ontology data saved successfully!');
  } catch (error) {
    console.error('Error saving ontology data: ', error);
  }
};

const updateOntologyData = async (ontologyData) => {
  
  try {

    const db_name = await identifyInterviewDbConfig();

    const ontologyDataDocRef = doc(db, db_name, ontologyData.id);
    await updateDoc(ontologyDataDocRef, {
      ...ontologyData,
      updated: serverTimestamp()
    });
    console.log('Ontology data updated successfully!');
  } catch (error) {
    console.error('Error updating ontology data: ', error);
  }
  
};

// Delete a use case
const deleteOntologyData = async (ontologyID) => {
  
  try {

    const db_name = await identifyInterviewDbConfig();

    const ontologyDataDocRef = doc(db, db_name, ontologyID);
    await deleteDoc(ontologyDataDocRef);
    console.log('Ontology data deleted successfully!');
  } catch (error) {
    console.error('Error deleting ontology data: ', error);
  }
};

export { saveOntologyData, updateOntologyData, deleteOntologyData };