/**
 * Navbar handling
 *
 * Description: Applies both destop and mobile navigation handling
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-31
 * 
 */


import React, { useEffect, useState} from 'react';
import { Container, Nav, Navbar, NavDropdown, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import {SignOut, UserInformation} from '../auth_mgmt/auth_handling'; // AuthStateListener
import { useNavigate } from 'react-router-dom';

import Papierboot from '../../assets/img/Papierboot.png';

import ModalCreateMode from '../create_handling/create_modal_mode';

// Feedback button
const Feedback = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Provide feedback and report bugs</Tooltip>}>
    <Button
      variant="success"
      className="nav-link"
      onClick={() => openInNewTab('https://docs.google.com/document/d/1Fgf6wwdcm5HPvBLl9LTJtZcBVOReEcBQ5laCI0q9OE4/edit?usp=sharing')}
      style={{
        marginTop: '5px', 
        marginLeft: '5px',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        // fix bottom right
        position: 'fixed',
        bottom: '50px',
        right: '50px',
      }}
    >
      <i className="bi bi-chat-square-text" style={{ color: 'white', marginBottom:'-2px' }}></i>
    </Button>
    </OverlayTrigger>
  );
};

export default function NavBar() {

  // provides the current user's name and id
  //AuthStateListener();

  const navigate = useNavigate();
  const [showModalCreateMode, setShowModalCreateMode] = useState(false);

  const navigatePrompts = () => {
    navigate('/Prompts');
  };

  const navigateKnowledge = () => {
    navigate('/KB');
  };

  const handleCreateMode = () => {
    setShowModalCreateMode(true);
  }

  const navigateRepository = () => {
    navigate('/Repository');
  };

  const navigateAnalytics = () => {
    navigate('/Analytics');
  };

  const navigateAbout = () => { 
    navigate('/About');
  };

  const navigateParticipate = () => {
    navigate('/Participate');
  };

  const navigateApproach = () => {
    navigate('/Approach');
  };

  /*
  const navigateParticipate = () => {
    navigate('/Participate');
  };*/

  const navigateDashboard = () => {
    navigate('/Dashboard');
  };

  const navigateAccount = () => {
    navigate('/Account');
  };

  const navigateDB = () => {
    navigate('/DB');
  };

  // returns the current user's first name
  const user = UserInformation();

  const handleSignOut = () => {  
      SignOut();
  }

  const [activeButton, setActiveButton] = useState('');
  const [activeDropdownItem, setActiveDropdownItem] = useState('');
  const [activeDropdown, setActiveDropdown] = useState('');

  const handleNavActiveBtn = window.location.pathname.split('/')[1];

  useEffect(() => {
      if (handleNavActiveBtn) {
        switch (handleNavActiveBtn) {
          case 'Create':
            setActiveButton('creator');
            break;
          case 'Repository':
            setActiveButton('repository');
            break;
          case 'Analytics':
            setActiveButton('analytics');
            break;
          case 'About':
            setActiveButton('about');
            break;
          case 'Participate':
            setActiveButton('participate');
            break;
          case 'Approach':
            setActiveDropdownItem('Agnostic UCM Method');
            setActiveDropdown('Explorer');
            break;
          case 'KB':
            setActiveDropdownItem('Semantic Model Mapper');
            setActiveDropdown('Explorer');
            break;
          case 'Prompts':
            setActiveDropdownItem('Prompt Engineering');
            setActiveDropdown('Explorer');
            break;
          case 'Dashboard':
            setActiveDropdownItem('Dashboard');
            setActiveDropdown('Account');
            break;
          case 'Account':
            setActiveDropdownItem('Account Settings');
            setActiveDropdown('Account');
            break;
          case 'UCM':
            setActiveButton('');
            setActiveDropdownItem('');
            setActiveDropdown('');
            break;
          case 'DB':
            setActiveDropdownItem('Database Management');
            setActiveDropdown('Account');
            break;
          default:
            setActiveButton('');
            setActiveDropdownItem('');
            setActiveDropdown('');
            break;
        }
      }
  }, [handleNavActiveBtn]);

  const handleButtonClick = (buttonName, navigateFunction) => {
    if (activeButton !== buttonName) {
      setActiveButton(  );
      setActiveDropdownItem('');
      setActiveDropdown('');
      navigateFunction();
    }
  };

  const handleDropdownItemClick = (dropdownName, itemName, navigateFunction) => {
    if (activeDropdownItem !== itemName) {
      setActiveDropdownItem(itemName);
      setActiveDropdown(dropdownName);
      setActiveButton('');
      navigateFunction();
    }
  };

  const getButtonStyle = (buttonName) => {
    return activeButton === buttonName ? 'primary' : 'btn-link';
  };

  const getDropdownStyle = (dropdownName) => {
    return activeDropdown === dropdownName ? { textDecoration: 'underline', textDecorationColor: '#007bff' } : {};
  };

  const getDropdownItemStyle = (itemName) => {
    return activeDropdownItem === itemName ? 'bg-primary text-white' : '';
  };

  const handleResetActiveButton = () => {
    if (activeButton !== '' || activeDropdownItem !== '' || activeDropdown !== '') {
      setActiveButton('');
      setActiveDropdownItem('');
      setActiveDropdown('');
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);

  const handleNavSelect = () => setExpanded(false);

  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" sticky="top" bg="white" expanded={expanded}> {/*bg="light" variant="light"*/}
      <Container fluid>
        <Navbar.Brand as={Link} to="/Start" onClick={handleResetActiveButton}>
          <img src={Papierboot} width="70" height="70" alt="" />
          <b>UCM</b>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* Logo, Dev Status and About */}
          <Nav className="me-auto">
            {/*<Nav.Link to="/About" href="#About">about</Nav.Link>*/}
            <div style={{ marginTop: '5px', marginLeft: '0px' }}><span className="badge badge-pill bg-success">beta</span></div>                         
            
            <div className="d-none d-lg-block" style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '15px', marginRight:'15px' }}></div> 
            
            <div className="d-none d-lg-block">
              <Button
                variant={getButtonStyle('about')}
                onClick={() => handleButtonClick('about', navigateAbout)}
              >
                About
              </Button>
            </div>
            <Link to="/About" className="nav-link d-block d-lg-none" onClick={handleNavSelect}>About</Link>
            {/*<Link to="/About" className="nav-link">About</Link>*/}
            {/*<Nav.Link as={Link} to="/Approach">Approach</Nav.Link> */}
            <div className="d-none d-lg-block">
              <Button
                variant={getButtonStyle('participate')}
                onClick={() => handleButtonClick('participate', navigateParticipate)}
              >
                Participate
              </Button>
            </div>
            <Link to="/Participate" className="nav-link d-block d-lg-none" onClick={handleNavSelect}>Participate</Link>
          </Nav>          
          {/* Navigation Links */}
          <Nav> 
            <div className="d-lg-none">
              <Nav.Link as={Link} to="/Create" onClick={() => { handleButtonClick('creator', handleCreateMode); handleNavSelect(); }}>Creator</Nav.Link>
              <Nav.Link as={Link} to="/Repository" onClick={() => { handleButtonClick('repository', navigateRepository); handleNavSelect(); }}>Repository</Nav.Link>
              <Nav.Link as={Link} to="/Analytics" onClick={() => { handleButtonClick('analytics', navigateAnalytics); handleNavSelect(); }}>Analytics</Nav.Link>
            </div>

            <div className="d-none d-lg-flex">
              <Button
                variant={getButtonStyle('creator')}
                onClick={() => handleButtonClick('creator', handleCreateMode)}
              >
                Creator
              </Button>
            </div>
            {/*<Nav.Link onClick={navigateCreator}>Creator</Nav.Link>*/}
      
            <div className="d-none d-lg-flex">
              <Button
                variant={getButtonStyle('repository')}
                onClick={() => handleButtonClick('repository', navigateRepository)}
              >
                Repository
              </Button>
            </div>
            {/*<Nav.Link onClick={navigateRepository}>Repository</Nav.Link>*/}
            
            <div className="d-none d-lg-flex">
              <Button
                variant={getButtonStyle('analytics')}
                onClick={() => handleButtonClick('analytics', navigateAnalytics)}
              >
                Analytics
              </Button>
            </div>
            {/*<Nav.Link onClick={navigateAnalytics}>Analytics</Nav.Link>*/}
            
            <div className="d-none d-lg-block" style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '15px', marginRight:'15px' }}></div> 
            
            <NavDropdown title="Explorer" id="collasible-nav-dropdown" className='custom-dropdown-menu' style={getDropdownStyle('Explorer')}>
              <NavDropdown.Item
                className={getDropdownItemStyle('Agnostic UCM Method')}
                onClick={() => { handleDropdownItemClick('Explorer', 'Agnostic UCM Method', navigateApproach); handleNavSelect(); } }                
              >
                Agnostic UCM Method
              </NavDropdown.Item>
              <NavDropdown.Item
                className={getDropdownItemStyle('Semantic Model Mapper')}
                onClick={() => { handleDropdownItemClick('Explorer', 'Semantic Model Mapper', navigateKnowledge); handleNavSelect(); } }
              >
                Semantic Model Mapper
              </NavDropdown.Item>
              <NavDropdown.Item
                className={getDropdownItemStyle('Prompt Engineering')}
                onClick={() => { handleDropdownItemClick('Explorer', 'Prompt Engineering', navigatePrompts); handleNavSelect(); } }
              >
                Prompt Engineering
              </NavDropdown.Item>
            </NavDropdown>
            
            {/*
            <NavDropdown title="Explorer" id="collasible-nav-dropdown">
                <NavDropdown.Item onClick={navigatePrompts}>Prompt Engineering</NavDropdown.Item>
                <NavDropdown.Item onClick={navigateKnowledge}>Semantic Models</NavDropdown.Item>
            </NavDropdown>    
            */}
            <NavDropdown title="Tools" id="collasible-nav-dropdown" className='custom-dropdown-menu' style={getDropdownStyle('Tools')}>              
              <NavDropdown.Item
                disabled
                className={getDropdownItemStyle('Scenario Builder')}
                onClick={() => handleDropdownItemClick('Tools', 'Scenario Builder', () => navigate('/'))}
              >
                Scenario Builder
              </NavDropdown.Item>
              <NavDropdown.Item
                disabled
                className={getDropdownItemStyle('Actor Modeler')}
                onClick={() => { handleDropdownItemClick('Tools', 'Actor Modeler', () => navigate('/')); ; handleNavSelect(); }}
              >
                Actor Modeler
              </NavDropdown.Item>
              <NavDropdown.Item
                disabled
                className={getDropdownItemStyle('Component Architect')}
                onClick={() => { handleDropdownItemClick('Tools', 'Component Architect', () => navigate('/')); handleNavSelect(); }}
              >
                Component Architect
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                disabled
                className={getDropdownItemStyle('Use Case Analysis')}
                onClick={() => { handleDropdownItemClick('Tools', 'Use Case Analysis', () => navigate('/')); handleNavSelect(); }}
              >
                Use Case Analysis
              </NavDropdown.Item>
              <NavDropdown.Item
                disabled
                className={getDropdownItemStyle('BPMN Builder')}
                onClick={() => { handleDropdownItemClick('Tools', 'BPMN Builder', () => navigate('/')); handleNavSelect(); }}
              >
                BPMN Builder
              </NavDropdown.Item>
              <NavDropdown.Item
                disabled
                className={getDropdownItemStyle('Data Modeler')}
                onClick={() => { handleDropdownItemClick('Tools', 'Data Modeler', () => navigate('/')); handleNavSelect(); }}
              >
                Reporting
              </NavDropdown.Item>
              {/*
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={navigateApproach}>Learn More</NavDropdown.Item>              
              <NavDropdown.Item href="#action/3.1">Actor mapping</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Entity capturing</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Data capturing</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">Network effect capturing</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.5">Component builder</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.6">Solution modelling</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.7">Validate & Test</NavDropdown.Item>
              */}
            </NavDropdown>
            
            <div className="d-none d-lg-block" style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '15px', marginRight:'15px' }}></div> 
            {/*
            <Nav.Link onClick={navigateRepository}>use cases</Nav.Link>              
            <NavDropdown title="use cases" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Smart valet Parking</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Vehicle-on-Demand</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">GLOSA</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Platooning</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">XAI</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Tele-Operation</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Last-Mile-Delivery</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Hub2Hub Transportation</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Facility Automated Vehicles</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/CreateUC">Model Use Case</NavDropdown.Item>
            </NavDropdown>
            */}
            {/*}
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-bottom">Participate in the study to explore a Platform Economy Model</Tooltip>}>
              <Button to="/Participate" variant="outline-primary" onClick={navigateParticipate}>participate</Button>     
            </OverlayTrigger>
            */}
            <NavDropdown 
              title={
                <span>
                  <i className="bi bi-person"></i> {/* style={{ marginRight:'5px' }} */}
                  {/*{user_name}*/}
                </span>
              }
              id="collasible-nav-dropdown" 
              //className="custom-dropdown"
              style={getDropdownStyle('Account')}
              className="custom-dropdown"
            >
              <NavDropdown.Item disabled>
              <b>{user.displayName}</b>
              <br />
              <small>{user.email}</small>
              <hr />
              </NavDropdown.Item >
              <NavDropdown.Item 
                className={getDropdownItemStyle('Dashboard')}
                onClick={() => { handleDropdownItemClick('Account', 'Dashboard', navigateDashboard); handleNavSelect(); }}
              >
                Dashboard
              </NavDropdown.Item>              
              <NavDropdown.Item
                className={getDropdownItemStyle('Models')}
                onClick={() => { handleDropdownItemClick('Account', 'Models', navigateKnowledge); handleNavSelect(); }}
              >
                Custom Domain Models
              </NavDropdown.Item>         

              <NavDropdown.Divider />

              <NavDropdown.Item 
                className={getDropdownItemStyle('Account Settings')}
                onClick={() => { handleDropdownItemClick('Account', 'Account Settings', navigateAccount); handleNavSelect(); }}
              >
                Account Settings
              </NavDropdown.Item>              

              <NavDropdown.Item onClick={handleSignOut}>Log Out</NavDropdown.Item>

              {/* Admin view if specific email */}
              {user.email === 'marc.gaugusto@gmail.com' && (
                <NavDropdown.Item
                  className='bg-danger text-white'
                  onClick={() => { handleDropdownItemClick('Account', 'Database Management', navigateDB); handleNavSelect(); }}
                >
                  Database Management
                </NavDropdown.Item>
              )}

            </NavDropdown>     
            
            {/* Feedback Button */}       
            <Feedback />

          </Nav>
        </Navbar.Collapse>
      </Container>
      {/* Modal for Create Mode */}
      {showModalCreateMode && (
        <ModalCreateMode 
          show={showModalCreateMode} 
          onHide={() => setShowModalCreateMode(false)}         
        />  
      )}
    </Navbar>
  );
}

export { default as NavBar } from './NavBar';