/**
 * Prompt page
 *
 * Description: This module contains functions to generate prompts for the chat.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-23
 * 
 */

import React, { useState } from 'react';
import { Accordion, Button, Tooltip, OverlayTrigger, Col, Row, Tab, Nav, Badge } from 'react-bootstrap';

import InfoModal from '../elems/elem_info_modal';
import { prompts_info, prompts_actions, prompts_conditions, prompts_actor, prompts_components } from './chat_prompts';

const PromptDetails = ({ prompt }) => (
  <div align='left'>
      <p><strong>Prompt:</strong> {prompt.prompt}</p>
      <p><strong>Category:</strong> {prompt?.category_key || 'N/A'}</p>
      <p><strong>Context:</strong> {prompt.context}</p>
      <p><strong>Expected Format:</strong> {prompt.expected_format}</p>
      <p><strong>Items:</strong> {prompt.items}</p>
      <p><strong>Example:</strong> {prompt.example}</p>
  </div>
);

const CustomNav = () => {
  const [activeKey, setActiveKey] = useState('step1');

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };

  const step_names = ['Information', 'Meta Data', 'Actions', 'Actors', 'Components'];

  return (
    <Nav
      className="flex-column me-3"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
      style={{ textAlign: 'left', width: '250px' }}
      activeKey={activeKey}
      onSelect={handleSelect}
    >
      {['step1', 'step2', 'step3', 'step4', 'step5'].map((step, index) => (
        <Nav.Item className="mb-2" key={step}>
          <Nav.Link
            eventKey={step}
            style={{
              border: activeKey === step ? '2px solid #007bff' : '2px solid transparent',
              borderRadius: '5px',
            }}
          >
            Step {index + 1}: {step_names[index]}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

const AccordionComponent = ({ step_name, prompts }) => (
  <Accordion>
    {prompts.map((prompt, index) => (
      <Accordion.Item eventKey={index.toString()} key={index}>
        <Accordion.Header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Badge bg="info" text="dark" style={{ marginRight: '10px' }}>{step_name}</Badge>
              <span style={{ marginRight: '10px' }}>{prompt.context}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginRight:'15px' }}>
              <Badge bg="success" text="light" style={{ marginLeft: 'auto' }}>In Use</Badge>
              <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>Semantic Model</Badge>
              <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>{prompt.expected_format}</Badge>
              <Badge bg="secondary" text="light" style={{ marginLeft: '5px' }}>{prompt.items}</Badge>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <PromptDetails prompt={prompt} />
        </Accordion.Body>
      </Accordion.Item>
    ))}
  </Accordion>
);

const PromptView = ( meta_info ) => (
    <Tab.Container id="v-pills-tab" defaultActiveKey="step1">
      <div className="d-flex align-items-start">
        <CustomNav />
        <div style={{ width: '100%' }}>
        <Tab.Content>
          <Tab.Pane eventKey="step1">
            <AccordionComponent step_name='Information' prompts={prompts_info} />
          </Tab.Pane>
          <Tab.Pane eventKey="step2">
            <AccordionComponent step_name='Meta Data' prompts={meta_info.meta_info} />
          </Tab.Pane>
          <Tab.Pane eventKey="step3">
            <AccordionComponent step_name='Actions' prompts={prompts_actions} />
            <AccordionComponent step_name='Conditions' prompts={prompts_conditions} />
          </Tab.Pane>
          <Tab.Pane eventKey="step4">
            <AccordionComponent step_name='Actors' prompts={prompts_actor} />
          </Tab.Pane>
          <Tab.Pane eventKey="step5">
            <AccordionComponent step_name='Components' prompts={prompts_components} />
          </Tab.Pane>
        </Tab.Content>
        </ div>
      </div>
    </Tab.Container>
);

const PromptGenerator = () => {

    const [showModal, setShowModal] = useState(false);

    const info = 
        'This page provides a list of the prompts applied in the use case modelling process and how these are enriched by semantic information.' +         
        'You can search for specific prompts, filter them, and sort them.' +
        'You can also add new prompts or create a new use case.';

    const meta_info = [
      {
          prompt: 'No Prompt',
          context: 'Visibility',
          expected_format: 'Boolean',
          items: '1',
          example: 'true; Use case is made available in the repository.'
      },
      {
          prompt: 'No Prompt',
          context: 'Adoption',
          expected_format: 'Boolean',
          items: '1',
          example: 'true; Allow community to use the created use case as a template for modeling new use cases.'
      },
      {
          prompt: 'No Prompt',
          context: 'Share',
          expected_format: 'Boolean',
          items: '1',
          example: 'false; Allow usage of manually added linked data in the community.'
      },
    ];

    const handleModalInfo = () => {        
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Row style={{ marginBottom:'15px'}}>
                <Col md={1}></Col>
                <Col md={4}>
                    <div>
                        <h3 className="text-start">Use Case Prompt Engineering</h3>
                        <h6 className="text-start">Look up, compare or use as template</h6>                    
                    </div>
                </Col>  
                <Col md={6} className="d-flex justify-content-end align-items-center">
                    {/* toolbar */}
                    <div className="d-flex justify-content-end">                        
                        {/*}
                        <OverlayTrigger placement="top" overlay={<Tooltip>Add prompt.</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary">
                                <i className="bi bi-magic"></i>
                            </Button>
                        </OverlayTrigger>
                        
                        <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>       
                        */}
                        <OverlayTrigger placement="top" overlay={<Tooltip>Learn more about this page.</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleModalInfo}>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row>
                <Col md={1}></Col>
                <Col md={10}>
                    <PromptView meta_info={meta_info} />                    
                </Col>
                <Col md={1}></Col>
            </Row>

            {showModal && (
                <InfoModal onClose={handleCloseModal} info={info} />
            )}
        </div>
  );
};

export default PromptGenerator;