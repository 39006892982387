/**
 * Load bar
 * 
 * Description: Used for any page that needs to load data.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-11-10
 * 
 */

import React from 'react';
import { Spinner, Container, Row, Col } from 'react-bootstrap';

const Loading = () => {
  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ marginTop:'200px' }}>
      <Row>
        <Col xs={12} className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
};

export default Loading;
