/**
 * Visualizes the study interview
 *
 * Description: Provides an interface to analyze the interview data.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-27
 */

import React, { useState, useEffect } from 'react';
import { Row, Col, ButtonGroup, Button, Card, Table, Form, Dropdown, DropdownButton, Modal, Accordion } from 'react-bootstrap';

import { StatisticsCard } from '../../eco_analytics_handling/eco_stats';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

import { Bar, Radar, Scatter, PolarArea, Doughnut, Line } from 'react-chartjs-2';

// Register necessary components for all chart types
ChartJS.register(
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend
);

const chartTypes = {
    Bar: Bar,
    Radar: Radar,
    Scatter: Scatter,
    Polar: PolarArea,
    Doughnut: Doughnut,
    Line: Line,
};

// Aggregate survey data for chart preparation and calculations
const aggregateInterviewData = ( interviewData ) => {

    // General demographics
    let totalParticipants = interviewData.length;
    const experienceData = {};
    const roleData = {};
    const placeData = {};

    interviewData.forEach((data) => {

        // Aggregate years of work experience
        experienceData[data.experience?.value] = (experienceData[data.experience?.value] || 0) + 1;

        // Aggregate `role` responses
        roleData[data.role?.value] = (roleData[data.role?.value] || 0) + 1;

        // Aggregate `place` responses
        placeData[data.place?.value] = (placeData[data.place?.value] || 0) + 1;
    });

    return { 
        // General demographics
        roleData, 
        placeData, 
        experienceData, 
        totalParticipants, 
    };
};

// Data Analysis Component across all interviews
const DataAnalysis = ({ interviewData }) => {
    const [themes, setThemes] = useState([]);
    const [keywordCounts, setKeywordCounts] = useState({});
    const [sentimentCounts, setSentimentCounts] = useState({ positive: 0, neutral: 0, negative: 0 });
    const [chartType, setChartType] = useState('Bar');

    const [languageCounts, setLanguageCounts] = useState({});
    const [averageDuration, setAverageDuration] = useState(0);
    const [durationStats, setDurationStats] = useState({ min: 0, max: 0 });
    const [averageSentimentScore, setAverageSentimentScore] = useState(0);

    useEffect(() => {
        if (interviewData.length) {

            setThemes(extractThemes(interviewData));
            const { keywordCounts, sentimentCounts } = analyzeKeywordsAndSentiments(interviewData);
            setKeywordCounts(keywordCounts);
            setSentimentCounts(sentimentCounts);
            setAverageSentimentScore(calculateAverageSentimentScore(interviewData));
            setLanguageCounts(extractOriginalDetectedLanguage(interviewData));
            setAverageDuration(calculateAverageDuration(interviewData).average);
            setDurationStats(calculateAverageDuration(interviewData));
        }
    }, [interviewData]);

    // Extract Themes Function
    const extractThemes = (data) => {
        const themes = {};
        data.forEach(interview => {
            Object.values(interview.questions || {}).forEach(questionSet => {
                Object.values(questionSet || {}).forEach(question => {
                    const theme = question.theme || 'No theme identified';
                    themes[theme] = (themes[theme] || 0) + 1;
                });
            });
        });
        return Object.entries(themes).sort((a, b) => b[1] - a[1]);
    };

    // Extract Original Detected Language
    const extractOriginalDetectedLanguage = (data) => {
        const languages = {};
        data.forEach(interview => {
            Object.values(interview.questions || {}).forEach(questionSet => {
                Object.values(questionSet || {}).forEach(question => {
                    const language = question.audio_detected_original_language || 'Unknown';
                    languages[language] = (languages[language] || 0) + 1;
                });
            });
        });
        return languages;
    };

    // Analyze Keywords and Sentiments
    const analyzeKeywordsAndSentiments = (data) => {
        const keywordCounts = {};
        const sentimentCounts = { positive: 0, neutral: 0, negative: 0 };
        data.forEach(interview => {
            Object.values(interview.questions || {}).forEach(questionSet => {
                Object.values(questionSet || {}).forEach(question => {
                    const keywords = typeof question.keywords === 'string' ? question.keywords.split(', ') : [];
                    keywords.forEach(keyword => {
                        keywordCounts[keyword] = (keywordCounts[keyword] || 0) + 1;
                    });
                    const sentiment = question.sentiment ? question.sentiment.toLowerCase() : 'neutral';
                    if (sentimentCounts[sentiment] !== undefined) {
                        sentimentCounts[sentiment]++;
                    }
                });
            });
        });
        return { keywordCounts, sentimentCounts };
    };

    // Calculate Average Sentiment Score
    const calculateAverageSentimentScore = (data) => {
        let totalSentimentScore = 0;
        let sentimentScoreCount = 0;
        data.forEach(interview => {
            Object.values(interview.questions || {}).forEach(questionSet => {
                Object.values(questionSet || {}).forEach(question => {
                    if (typeof question.sentiment_score === 'number') {
                        totalSentimentScore += question.sentiment_score;
                        sentimentScoreCount++;
                    }
                });
            });
        });

        return (totalSentimentScore / sentimentScoreCount).toFixed(2);
    };

    // Calculate Average Duration
    const calculateAverageDuration = (data) => {
        let totalDuration = 0;
        let durationValues = [];

        data.forEach(interview => {
            Object.values(interview.questions || {}).forEach(questionSet => {
                Object.values(questionSet || {}).forEach(question => {
                    if (question.audio_duration) {
                        const [minutes, seconds] = question.audio_duration.split(':').map(Number);
                        const durationInSeconds = minutes * 60 + seconds;
                        totalDuration += durationInSeconds;
                        durationValues.push(durationInSeconds);
                    }
                });
            });
        });

        const average = totalDuration / durationValues.length || 0;
        const min = Math.min(...durationValues) || 0;
        const max = Math.max(...durationValues) || 0;
        return { average: average.toFixed(2), min: min.toFixed(2), max: max.toFixed(2) };
    };

    // Prepare data for Chart.js
    const prepareChartData = (dataObj, label) => ({
        labels: Object.keys(dataObj),
        datasets: [
            {
                label,
                data: Object.values(dataObj),
                backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(255, 159, 64, 0.6)'],
                borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
                borderWidth: 1,
            },
        ],
    });

    // Toggle Chart Type
    const ChartComponent = chartTypes[chartType];

    return (
        <div>
            {/* Chart Type Selection */}
            <ButtonGroup className="mb-4">
                {Object.keys(chartTypes).map((type) => (
                    <Button key={type} onClick={() => setChartType(type)} active={chartType === type}>
                        {type}
                    </Button>
                ))}
            </ButtonGroup>

            <Row>
                {/* Themes Chart */}
                <Col md={6} className="mb-4">
                    <Card>
                        <Card.Header>Themes</Card.Header>
                        <Card.Body>
                            <ChartComponent data={prepareChartData(Object.fromEntries(themes), 'Themes')} options={{ maintainAspectRatio: false }} />
                        </Card.Body>
                    </Card>
                </Col>

                {/* Keywords Chart */}
                <Col md={6} className="mb-4">
                    <Card>
                        <Card.Header>Keyword Frequency</Card.Header>
                        <Card.Body>
                            <ChartComponent data={prepareChartData(keywordCounts, 'Keyword Frequency')} options={{ maintainAspectRatio: false }} />
                        </Card.Body>
                    </Card>
                </Col>

                {/* Sentiment Chart */}
                <Col md={6} className="mb-4">
                    <Card>
                        <Card.Header>Sentiment Analysis (Average Sentiment Score: {averageSentimentScore})</Card.Header>
                        <Card.Body>
                            <ChartComponent data={prepareChartData(sentimentCounts, 'Sentiment Analysis')} options={{ maintainAspectRatio: false }} />
                        </Card.Body>
                    </Card>
                </Col>

                {/* Original detected language */}
                <Col md={6} className="mb-4">
                    <Card>
                        <Card.Header>Original Detected Language</Card.Header>
                        <Card.Body>
                            <ChartComponent data={prepareChartData(languageCounts, 'Languages')} options={{ maintainAspectRatio: false }} />
                        </Card.Body>
                    </Card>                    
                </Col>

                {/* Audio Duration */}
                <Col md={6} className="mb-4">
                    <Card>
                        <Card.Header>Audio Duration (seconds)</Card.Header>
                        <Card.Body>
                            <p>Average Duration: {averageDuration} seconds</p>
                            <p>Min Duration: {durationStats.min} seconds</p>
                            <p>Max Duration: {durationStats.max} seconds</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

// Conducts analysis per question across all interviews, with filtering by role and expert_field.
const analyzeByQuestionAcrossInterviews = (data, roleFilter, expertFieldFilter, questionKey = null) => {
    const questionAnalysis = {};

    data.forEach(interview => {
        const { role, expert_field } = interview;

        // Apply filters for role and expert_field; only skip if filter is specified and does not match
        if ((roleFilter && role.value !== roleFilter) || (expertFieldFilter && expert_field.value !== expertFieldFilter)) {
            return;
        }

        Object.entries(interview.questions || {}).forEach(([questionSetKey, questionSet]) => {
            Object.entries(questionSet || {}).forEach(([currentQuestionKey, question]) => {
                // If a specific question is selected, skip non-matching questions
                if (questionKey && currentQuestionKey !== questionKey) return;

                // Initialize question analysis if not already present
                if (!questionAnalysis[questionKey || currentQuestionKey]) {
                    questionAnalysis[questionKey || currentQuestionKey] = {
                        themes: {},
                        keywordCounts: {},
                        sentimentCounts: { positive: 0, neutral: 0, negative: 0 },
                        durations: [],
                        languageCounts: {},
                        sentimentScores: [],
                    };
                }

                const analysis = questionAnalysis[questionKey || currentQuestionKey];

                // Aggregate themes
                const theme = question.theme || 'No theme identified';
                analysis.themes[theme] = (analysis.themes[theme] || 0) + 1;

                // Aggregate keywords
                const keywords = typeof question.keywords === 'string' ? question.keywords.split(', ') : [];
                keywords.forEach(keyword => {
                    analysis.keywordCounts[keyword] = (analysis.keywordCounts[keyword] || 0) + 1;
                });

                // Aggregate sentiments
                const sentiment = question.sentiment ? question.sentiment.toLowerCase() : 'neutral';
                analysis.sentimentCounts[sentiment]++;

                // Aggregate sentiment scores
                if (typeof question.sentiment_score === 'number') {
                    analysis.sentimentScores.push(question.sentiment_score);
                }

                // Aggregate language counts
                const language = question.audio_detected_original_language || 'Unknown';
                analysis.languageCounts[language] = (analysis.languageCounts[language] || 0) + 1;

                // Aggregate durations
                if (question.audio_duration) {
                    const [minutes, seconds] = question.audio_duration.split(':').map(Number);
                    const durationInSeconds = minutes * 60 + seconds;
                    analysis.durations.push(durationInSeconds);
                }
            });
        });
    });

    // Calculate averages for each question in questionAnalysis
    Object.entries(questionAnalysis).forEach(([questionKey, analysis]) => {
        analysis.averageSentimentScore = (
            analysis.sentimentScores.reduce((a, b) => a + b, 0) / (analysis.sentimentScores.length || 1)
        ).toFixed(2);
        analysis.averageDuration = (
            analysis.durations.reduce((a, b) => a + b, 0) / (analysis.durations.length || 1)
        ).toFixed(2);
        analysis.minDuration = Math.min(...analysis.durations).toFixed(2);
        analysis.maxDuration = Math.max(...analysis.durations).toFixed(2);
    });

    return questionAnalysis;
};

const DataAnalysisByQuestion = ({ interviewData }) => {

    const [roleFilter, setRoleFilter] = useState('All');
    const [expertFieldFilter, setExpertFieldFilter] = useState('All');
    const [selectedQuestion, setSelectedQuestion] = useState('All');
    const [chartType, setChartType] = useState('Bar');
    const [questionAnalysis, setQuestionAnalysis] = useState({});
    
    const [uniqueRoles, setUniqueRoles] = useState([]);
    const [filteredExpertFields, setFilteredExpertFields] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const chartTypes = { Bar: Bar, Doughnut: Doughnut, Radar: Radar };

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        // Populate unique roles based on interview data
        const roles = getUniqueRoles(interviewData);
        setUniqueRoles(['All', ...roles]);
    }, [interviewData]);

    // Set roleFilter if not 'All' to allow more intuitive filtering
    useEffect(() => {
        if (uniqueRoles.length > 1 && uniqueRoles.length < 3) {
            setRoleFilter(uniqueRoles[1]);
        } else {
            setRoleFilter('All');
        }
    }, [uniqueRoles]);


    useEffect(() => {
        // Update Expert Fields based on selected Role
        const expertFields = getFilteredExpertFields(interviewData, roleFilter);
        setFilteredExpertFields(['All', ...expertFields]);
        setExpertFieldFilter('All'); // Reset expert field filter when role changes
    }, [roleFilter, interviewData]);

    useEffect(() => {
        // Update Questions based on Role and Expert Field
        const questions = getFilteredQuestions(interviewData, roleFilter, expertFieldFilter);
        setFilteredQuestions(['All', ...questions]);
        setSelectedQuestion('All'); // Reset question selection when expert field changes
    }, [roleFilter, expertFieldFilter, interviewData]);

    useEffect(() => {
        // Update question analysis based on filters and selected question
        const isQuestionAll = selectedQuestion === 'All';
        const analysis = analyzeByQuestionAcrossInterviews(
            interviewData,
            roleFilter === 'All' ? null : roleFilter,
            expertFieldFilter === 'All' ? null : expertFieldFilter,
            isQuestionAll ? null : selectedQuestion
        );
        setQuestionAnalysis(analysis);
    }, [interviewData, roleFilter, expertFieldFilter, selectedQuestion]);

    const getUniqueRoles = (data) => {
        const roles = new Set();
        data.forEach((interview) => {
            if (interview.role && interview.role.value) roles.add(interview.role.value);
        });
        return Array.from(roles);
    };

    const getFilteredExpertFields = (data, roleFilter) => {
        const expertFields = new Set();
        data.forEach((interview) => {
            if ((roleFilter === 'All' || interview.role?.value === roleFilter) && interview.expert_field?.value) {
                expertFields.add(interview.expert_field.value);
            }
        });
        return Array.from(expertFields);
    };

    const getFilteredQuestions = (data, roleFilter, expertFieldFilter) => {
        const questions = new Set();
        data.forEach((interview) => {
            if (
                (roleFilter === 'All' || interview.role?.value === roleFilter) &&
                (expertFieldFilter === 'All' || interview.expert_field?.value === expertFieldFilter)
            ) {
                Object.keys(interview.questions || {}).forEach((questionSetKey) => {
                    Object.keys(interview.questions[questionSetKey] || {}).forEach((questionKey) => {
                        questions.add(questionKey);
                    });
                });
            }
        });
        return Array.from(questions);
    };

    const handleRoleFilterChange = (role) => {
        setRoleFilter(role);
        setExpertFieldFilter('All'); // Reset expert field filter when role changes
    };

    const handleExpertFieldFilterChange = (field) => {
        setExpertFieldFilter(field);
    };

    const handleQuestionChange = (question) => {
        setSelectedQuestion(question);
    };

    const getQuestionLabel = (data, questionKey) => {
        for (const interview of data) {
            for (const questionSet of Object.values(interview.questions || {})) {
                if (questionSet[questionKey]?.question) {
                    return questionSet[questionKey].question;
                }
            }
        }
        return questionKey; // Fallback to key if label is not found
    };

    const ChartComponent = chartTypes[chartType];

    const prepareChartData = (dataObj, label) => ({
        labels: Object.keys(dataObj),
        datasets: [
            {
                label,
                data: Object.values(dataObj),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    });

    // Chart click and hover handlers
    const handleChartClick = (evt, elems, chartData, topic) => {
        if (elems.length > 0) {
            const clickedIndex = elems[0].index;
            const clickedCategory = chartData.labels[clickedIndex];
            const clickedData = {
                topic,
                category: clickedCategory,
                res: interviewData.filter((interview) => {
                    for (const questionSet of Object.values(interview.questions || {})) {
                        for (const question of Object.values(questionSet || {})) {
                            if (question[topic] === clickedCategory) {
                                return true;
                            }
                        }
                    }
                    return false;
                }
            )
            };
            setModalData(clickedData);
            setShowModal(true);
        }
    };

    const handleChartHover = (evt, elems) => {
        evt.native.target.style.cursor = elems.length > 0 ? 'pointer' : 'default';
    };

    return (
        <div>
            {/* Filter Controls */}
            <Row className="mb-4">
                <Col xs="auto">
                    <DropdownButton title={`Role: ${roleFilter}`} onSelect={handleRoleFilterChange} variant="secondary">
                        {uniqueRoles.map((role) => (
                            <Dropdown.Item key={role} eventKey={role}>{role}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
                <Col xs="auto">
                    <DropdownButton title={`Expert Field: ${expertFieldFilter}`} onSelect={handleExpertFieldFilterChange} variant="secondary">
                        {filteredExpertFields.map((field) => (
                            <Dropdown.Item key={field} eventKey={field}>{field}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
                <Col xs="auto">
                    <DropdownButton title={`Question: ${selectedQuestion}`} onSelect={handleQuestionChange} variant="secondary">
                        {filteredQuestions.map((q) => (
                            <Dropdown.Item key={q} eventKey={q}>{q}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
                <Col xs="auto">
                    <ButtonGroup>
                        {Object.keys(chartTypes).map((type) => (
                            <Button key={type} onClick={() => setChartType(type)} active={chartType === type}>
                                {type}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Col>
            </Row>

            {/* Display Selected Question Analysis */}
            {selectedQuestion && selectedQuestion !== 'All' && questionAnalysis[selectedQuestion] ? (
                <Row>
                    {/* Display Selected Question Label */}
                    <Col md={12} className="mb-4">
                        <h4>Question: {getQuestionLabel(interviewData, selectedQuestion)}</h4>
                        <h6 style={{ color:'green'}}>
                            <b>
                            The participant(s) `{roleFilter}` in the 
                            sample with expert field `{expertFieldFilter}`
                            for question `{selectedQuestion}` provide the following insights:
                            </b>
                        </h6>
                    </Col>                    

                    <Col md={6} className="mb-4">
                        <Card>
                            <Card.Header>Themes</Card.Header>
                            <Card.Body>
                                <ChartComponent 
                                    data={prepareChartData(questionAnalysis[selectedQuestion].themes, 'Themes')} 
                                    options={{ 
                                        maintainAspectRatio: false,
                                        onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(questionAnalysis[selectedQuestion].themes, 'Themes'), 'Themes'),
                                        onHover: handleChartHover
                                    }} 
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card>
                            <Card.Header>Keyword Frequency</Card.Header>
                            <Card.Body>
                                <ChartComponent 
                                    data={prepareChartData(questionAnalysis[selectedQuestion].keywordCounts, 'Keyword Frequency')} 
                                    options={{ 
                                        maintainAspectRatio: false,
                                        onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(questionAnalysis[selectedQuestion].keywordCounts, 'Keyword Frequency'), 'Keywords'),
                                        onHover: handleChartHover        
                                    }} 
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card>
                            <Card.Header>Language Distribution</Card.Header>
                            <Card.Body>
                                <ChartComponent data={prepareChartData(questionAnalysis[selectedQuestion].languageCounts, 'Languages')} options={{ maintainAspectRatio: false }} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card>
                            <Card.Header>Sentiment Analysis (Avg. Score: {questionAnalysis[selectedQuestion].averageSentimentScore})</Card.Header>
                            <Card.Body>
                                <ChartComponent data={prepareChartData(questionAnalysis[selectedQuestion].sentimentCounts, 'Sentiments')} options={{ maintainAspectRatio: false }} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card>
                            <Card.Header>Audio Duration (seconds)</Card.Header>
                            <Card.Body>
                                <p>Average: {questionAnalysis[selectedQuestion].averageDuration} seconds</p>
                                <p>Min: {questionAnalysis[selectedQuestion].minDuration} seconds</p>
                                <p>Max: {questionAnalysis[selectedQuestion].maxDuration} seconds</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Row className="justify-content-center align-items-center" style={{ minHeight: '300px' }}>
                    <Col xs="auto" className="text-center">
                        <i className="bi bi-bar-chart text-muted" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <p className="text-muted" style={{ fontSize: '1.5rem', fontStyle: 'italic' }}>
                            Please select a role, expert field, and question to view detailed analysis.
                        </p>
                    </Col>
                </Row>
            )}

            {/* Detail Modal */}
            {modalData && (
                <DetailModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} />
            )}
        </div>
    );
};

const InterviewDataDisplay = ({ interviewData }) => {
    const [roleFilter, setRoleFilter] = useState('All');
    const [expertFieldFilter, setExpertFieldFilter] = useState('All');
    const [placeFilter, setPlaceFilter] = useState('All');

    const uniqueRoles = ['All', ...new Set(interviewData.map((interview) => interview.role?.value).filter(Boolean))];
    const uniqueExpertFields = ['All', ...new Set(interviewData.map((interview) => interview.expert_field?.value).filter(Boolean))];
    const uniquePlaces = ['All', ...new Set(interviewData.map((interview) => interview.place?.value).filter(Boolean))];

    const handleFilterChange = (setter) => (eventKey) => {
        setter(eventKey);
    };

    const filteredData = interviewData.filter(interview => 
        (roleFilter === 'All' || interview.role?.value === roleFilter) &&
        (expertFieldFilter === 'All' || interview.expert_field?.value === expertFieldFilter) &&
        (placeFilter === 'All' || interview.place?.value === placeFilter)
    );

    return (
        <div>            
            {/* Filter Controls */}
            <Row className="mb-4">
                <Col xs="auto">
                    <DropdownButton title={`Role: ${roleFilter}`} onSelect={handleFilterChange(setRoleFilter)} variant="secondary">
                        {uniqueRoles.map((role) => (
                            <Dropdown.Item key={role} eventKey={role}>{role}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
                <Col xs="auto">
                    <DropdownButton title={`Expert Field: ${expertFieldFilter}`} onSelect={handleFilterChange(setExpertFieldFilter)} variant="secondary">
                        {uniqueExpertFields.map((field) => (
                            <Dropdown.Item key={field} eventKey={field}>{field}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
                <Col xs="auto">
                    <DropdownButton title={`Place: ${placeFilter}`} onSelect={handleFilterChange(setPlaceFilter)} variant="secondary">
                        {uniquePlaces.map((place) => (
                            <Dropdown.Item key={place} eventKey={place}>{place}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
            </Row>

            <Accordion defaultActiveKey="0">
                {filteredData.map((interview, index) => (
                    <Accordion.Item key={index} eventKey={`${index}`}>
                        <Accordion.Header>
                            <span>
                                <b>Participant:</b> {interview.place?.anonymous_id || 'No ID available'}
                            </span>
                            <span className="small text-muted ms-3">
                                Role: {interview.role?.value || 'No role available'} | 
                                Expert field: {interview.expert_field?.value || 'No field available'} | 
                                Experience: {(interview.experience?.value + ' years') || 'No experience available'}
                            </span>
                        </Accordion.Header>
                        
                        <Accordion.Body>
                            <p className="small mb-3">
                                Place: {interview.place?.value || 'No place available'} |
                                Mode: {interview.mode?.value || 'No mode available'}
                            </p>
                            
                            {Object.entries(interview.questions || {}).map(([questionSetKey, questions]) => (
                                <div key={questionSetKey} className="mb-4">
                                    <h5 className="font-weight-bold mt-4 mb-2">
                                        Set: {questionSetKey.replace(/_/g, ' ')}
                                    </h5>
                                    
                                    <Row>
                                        {Object.entries(questions || {}).map(([qKey, question]) => (
                                            <Col md={4} key={qKey} style={{ marginBottom: '15px' }}>
                                                <Card className="h-100">
                                                    <Card.Header>{question.question || 'No question available'}</Card.Header>
                                                    <Card.Body>
                                                        <p className="small mb-2"><b>Summary:</b> {question.summary || 'No summary available'}</p>
                                                        <p className="small mb-2"><b>Sentiment:</b> {question.sentiment || 'No sentiment data'}</p>
                                                        <p className="small mb-2"><b>Theme:</b> {question.theme || 'No theme identified'}</p>
                                                        <p className="small"><b>Key Findings:</b></p>
                                                        <ul className="small">
                                                            {question.findings && question.findings.length > 0 ? (
                                                                question.findings.map((finding, idx) => (
                                                                    <li key={idx}>{finding}</li>
                                                                ))
                                                            ) : (
                                                                <li>No findings available</li>
                                                            )}
                                                        </ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export const InterviewAnalysis = ({ interviewData, handleClear }) => {
    const [setSelectedParticipant] = useState(null); // selectedParticipant, 
    const [selectedRole, setSelectedRole] = useState(null);

    const {
        // General demographics
        roleData, 
        placeData, 
        experienceData, 
        totalParticipants } = aggregateInterviewData(interviewData);

    // Filter data by selected participant or role
    const filteredInterviewData = selectedRole || interviewData;
    /*
    const filteredInterviewData = interviewData.filter(data => 
        (!selectedParticipant || data.anonymous_id.value === selectedParticipant) &&
        (!selectedRole || data.role.value === selectedRole)
    );
    */    

    // Event handlers
    const handleParticipantSelect = (event) => setSelectedParticipant(event.target.value);

    //const handleParticipantsSelectByRole = (event) => setSelectedRole(event.target.value);
    const handleParticipantsSelectByRole = (event) => {
        const selectedRole = event.target.value;
        const participantData = interviewData.filter(data => data.role.value === selectedRole);
        
        if (selectedRole !== '') {
            setSelectedRole(participantData || null);
        } else {
            setSelectedRole(event.target.value);
        }
    };

    let totalExperience = 0;
    Object.entries(experienceData).forEach(([years, count]) => {
        totalExperience += years * count;
    });

    return (
        <Row>
            <Col md={12} style={{ textAlign: 'left' }} id="content">
                {/* Title */}
                <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Col>
                        <span>Interview Analysis</span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-primary" className="btn-sm" onClick={handleClear}>
                            <i className="bi bi-arrow-left"></i> Go back
                        </Button>
                    </Col>
                </Row>

                {/* General Demographics */}
                <Row style={{ marginBottom: '30px' }}>
                    <StatisticsCard targetCount={totalParticipants} caption="Total Participants" />
                    <StatisticsCard targetCount={Object.keys(roleData).length} caption="Roles" />
                    <StatisticsCard targetCount={Object.keys(placeData).length} caption="Locations" />
                    <StatisticsCard targetCount={totalExperience || -1} caption="Years of Experience" />
                </Row>

                {/* Participant and Role Selection */}
                <Row style={{ marginBottom: '30px' }}>
                    {/* Individual Participant or Role Selection */}
                    <Col md={3}>
                        <Card className="h-100">
                            <Card.Header>Participant Selection</Card.Header>
                            <Card.Body>
                                <Form.Group controlId="participantSelect">
                                    {/* Select by Anonymized ID */}
                                    <Form.Label className="small">Select by Anonymized ID:</Form.Label>
                                    <Form.Control as="select" onChange={handleParticipantSelect}>
                                        <option value="">All Participants</option>
                                        {interviewData.map((data, idx) => (
                                            <option key={idx} value={data.anonymous_id?.value}>
                                                {data.anonymous_id?.value || `Participant ${idx + 1}`}
                                            </option>
                                        ))}
                                    </Form.Control>

                                    {/* Select by Role */}
                                    <Form.Label className="small" style={{ marginTop: '15px' }}>Select by Role:</Form.Label>
                                    <Form.Control as="select" onChange={handleParticipantsSelectByRole}>
                                        <option value="">All Roles</option>
                                        {Object.entries(roleData || {}).map(([role, count]) => (
                                            <option key={role} value={role}>
                                                {role} ({count})
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Text className="text-muted small">Select a role to filter participants</Form.Text>
                                </Form.Group>
                                <div className="mt-3">
                                    <span className="text-muted small">Select a participant to view detailed responses.</span>
                                    <br />
                                    <span className="text-muted small">All data is anonymized.</span>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Interview Data Overview */}
                    <Col md={6}>
                        <Card className="h-100">
                            <Card.Header>Interview Data</Card.Header>
                            <Card.Body>
                                <p className="small">
                                    This analysis includes {totalParticipants} participants, each contributing insights on:
                                </p>
                                <Table bordered className="small">
                                    <tbody>
                                        <tr><th>#1</th><th>Theme</th><td>Overall aspects embodied by the answer</td></tr>
                                        <tr><th>#2</th><th>Keywords</th><td>Keywords and phrases used in the response</td></tr>
                                        <tr><th>#3</th><th>Summary</th><td>Summary of the given response</td></tr>
                                        <tr><th>#4</th><th>Sentiment</th><td>Positive, Neutral, Negative classifications</td></tr>
                                        <tr><th>#5</th><th>Duration</th><td>Duration of the audio response</td></tr>
                                        <tr><th>#6</th><th>Language</th><td>Detected language of the audio response</td></tr>
                                        <tr><th>#7</th><th>Key findings</th><td>List of findings in the response</td></tr>
                                        <tr><th>#8</th><th>Argument</th><td>Positive, Neutral, Negative classifications</td></tr>
                                        <tr><th>#9</th><th>Quotes</th><td>Positive, Neutral, Negative classifications</td></tr>
                                        <tr><th>#10</th><th>Relevance</th><td>Positive, Neutral, Negative classifications</td></tr>
                                        <tr><th>#11</th><th>Meta</th><td>i.e. original transcript and target transcript</td></tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Context and Disclaimer */}
                    <Col md={3}>
                        <Card
                            className="h-100"
                            style={{
                                backgroundColor: '#f1f8e9',
                                border: '1px solid #81c784',
                                color: '#2e7d32',
                            }}
                        >
                            <Card.Header style={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#1b5e20', backgroundColor: '#a5d6a7' }}>
                                Interview Context
                            </Card.Header>
                            <Card.Body>
                                <p className="small" style={{ marginBottom: 0 }}>
                                    This interview is conducted as part of PhD research, aiming to propose a PEAM model.
                                    The acquired data is analyzed for key themes, sentiment, and insights to understand AMS.
                                    <br /><br />
                                    All participants are experts in fields such as engineering, urban planning, administration, computing, and AI,
                                    each approaching autonomous mobility and transportation from unique roles and experiences.
                                    <br /><br />
                                    The data is anonymized and used solely for research purposes.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Data Analysis */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'60px', marginBottom:'60px'}}>
                        Overarching Interview Data Analysis
                    </h4>
                    <DataAnalysis interviewData={filteredInterviewData} />
                    <hr />
                </Row>

                {/* Data Analysis by Question */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'60px', marginBottom:'60px'}}>
                        Interview Data Analysis by Question and across all Participants / Interviews
                    </h4>
                    <DataAnalysisByQuestion interviewData={filteredInterviewData} />
                    <hr />
                </Row>

                {/* Data Display by Question Set */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'60px', marginBottom:'60px'}}>
                        Interview Data Display by Question Set and Participant
                    </h4>
                    <InterviewDataDisplay interviewData={filteredInterviewData} />
                </Row>                

            </Col>
        </Row>
    );
};

// Modal shows the appearance of the item in the dataset
const DetailModal = ({ show, handleClose, data }) => (

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{data.topic}: {data.category}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h6>Clicked: "{data.category}"</h6>
            <p>Roles selecting this:</p>

            <p>Expert fields selecting this:</p>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);