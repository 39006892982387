/**
 * Summarizes general use case data
 *
 * Description: used in sidebars to provide a summary of the use case data.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-15
 * 
 */

import React from 'react';
import { Accordion, Button } from 'react-bootstrap';

const countTotalItems = (components) => {
    return components.reduce((total, component) => {
        return total + component.components.reduce((sum, comp) => {
            return sum + (comp.items ? comp.items.length : 0);
        }, 0);
    }, 0);
};

const countComponentsAndItemsByCategory = (components) => {
    return components.map(category => {
        const totalComponents = category.components.length;
        const totalItems = category.components.reduce((sum, comp) => {
            return sum + (comp.items ? comp.items.length : 0);
        }, 0);
        
        return {
            category: category.category,
            totalComponents,
            totalItems
        };
    });
};

// Use Case details
export const UseCaseDetails = ({ data }) => {

    const formatTimestamp = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000);
            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        }
        return 'N/A';
    };

    // return Use Case Elements and key data
    return (     
        <>
        <h6>Use Case details</h6>   
        <Accordion style={{ marginTop: '15px' }}>
        {/* Use case information */}
        <Accordion.Item eventKey="0">
            <Accordion.Header>Use Case Information</Accordion.Header>
            <Accordion.Body>
            <div style={{ fontSize: '12px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Title:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.title.value}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Acronym:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.acronym.value}</div>
                </div>                            
                <hr />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Created:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{formatTimestamp(data.created.value)}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Updated:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{formatTimestamp(data.updated.value)}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Usage:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>
                    {data.adoption.value ? 
                    <span className="badge badge-pill bg-success">Open Access</span>
                    :
                    <span className="badge badge-pill bg-warning">Restricted Access</span>
                    }
                </div>
                </div>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Location:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.city.value}, {data.country_code.value}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Status:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.status.value}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Maturity:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.maturity.value}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Application:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.application.value}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Keywords:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>                                
                    {data.tags.value.map(tag => (
                    <span key={tag} className="badge badge-pill bg-light text-dark" style={{marginRight:'10px'}}>
                        {tag}
                    </span>
                    ))}                                
                </div>
                </div>
            </div>
            </Accordion.Body>
        </Accordion.Item>
        {/* Meta data */}
        <Accordion.Item eventKey="1">
            <Accordion.Header>Meta Data</Accordion.Header>
            <Accordion.Body>
            <div style={{ fontSize: '12px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Visibility:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>
                    {data.visibility.value ? 
                    <span className="badge badge-pill bg-success">Publicly visible</span>
                    :
                    <span className="badge badge-pill bg-warning">Restricted visibility</span>
                    }
                </div>                
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Adoption:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>
                    {data.adoption.value ? 
                    <span className="badge badge-pill bg-success">Open access</span>
                    :
                    <span className="badge badge-pill bg-warning">No access</span>
                    }
                </div>                
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Share:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>
                    {data.share.value ? 
                    <span className="badge badge-pill bg-success">Share Data</span>
                    :
                    <span className="badge badge-pill bg-warning">Hide Data</span>
                    }
                </div>                
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Base Ontology:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '38px' }}>
                    {data.semantic_model.value ? 
                    <span className="badge badge-pill bg-success">Applied</span>
                    :
                    <span className="badge badge-pill bg-warning">No model adopted</span>
                    }
                </div> 
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Domain model:</div>
                <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '40px' }}>{data.domain_model?.value?.acronym}</div>
                </div>
            </div>                              
            </Accordion.Body>
        </Accordion.Item>
        {/* Actions */}
        <Accordion.Item eventKey="2">
            <Accordion.Header>Actions</Accordion.Header>
            <Accordion.Body>
            {/* Provide some statistics */}
            <div style={{ fontSize: '12px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Scenarios:</div>
                        <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.actions.value.length}</div>
                    </div>
                </div>
                <Button variant="outline-primary" size="sm" style={{ marginTop: '10px' }} disabled>
                    <i className="bi bi-eye"></i> View Actions
                </Button>                         
            </Accordion.Body>
        </Accordion.Item>
        {/* Actors */}
        <Accordion.Item eventKey="3">
            <Accordion.Header>Actors</Accordion.Header>
            <Accordion.Body>
                {/* Provide some statistics */}
                <div style={{ fontSize: '12px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Total:</div>
                        <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.actors.value.nodes.value.length + data.actors.value.edges.value.length}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Categories:</div>
                        <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{Object.keys(data.actors.value.list).length}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Actors:</div>
                        <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.actors.value.nodes.value.length}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Connections:</div>
                        <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '50px' }}>{data.actors.value.edges.value.length}</div>
                    </div>
                </div>
                <Button variant="outline-primary" size="sm" style={{ marginTop: '10px' }} disabled>
                    <i className="bi bi-eye"></i> View Actors
                </Button>
            </Accordion.Body>
        </Accordion.Item>
        {/* Components */}
        <Accordion.Item eventKey="4">
            <Accordion.Header>Components</Accordion.Header>
            <Accordion.Body>
                {/* Provide some statistics */}
                <div style={{ fontSize: '12px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Total:</div>
                        <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '20px' }}>{countTotalItems(data.components.value)}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left' }}>Types:</div>
                        <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '20px' }}>{data.components.value.length}</div>
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <div style={{ minWidth: '80px', fontWeight: 'bold', textAlign: 'left', marginBottom:'15px' }}>Components:</div>
                        <div style={{ textAlign: 'left', paddingLeft: '20px' }}>              
                            {countComponentsAndItemsByCategory(data.components.value).map((category, index) => (       
                                <p key={index}>{category.category}: {category.totalComponents} comp.; {category.totalItems} items</p>
                            ))} 
                        </div>
                    </div>                                                        
                </div>     
                <Button variant="outline-primary" size="sm" style={{ marginTop: '10px' }} disabled>
                    <i className="bi bi-eye"></i> View Components
                </Button>                     
            </Accordion.Body>
        </Accordion.Item>
        </Accordion>   
        </>    
    );
};