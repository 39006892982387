/**
 * Chat
 *
 * Description: This component provides the functionality for a chat-like interface to interact with the OpenAI API.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-13
 * Notes: This component is used to bundle the chat-like interface for the use case creator.
 */

import React, { useState} from 'react';
//import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Papierboot from '../../assets/img/Papierboot.png';

/**
 * Provides the chat-like interface for the use case creator.
 */
const Chat = ( { user, messages, handleSubmit, inputValue, handleChange } ) => {

    // Track the expanded state of each system message
    const [expandedMessages, setExpandedMessages] = useState({});

    // Handle the expand of the system message
    const handleExpand = (index) => {
        setExpandedMessages(prevState => ({
            ...prevState,
            // Toggle the expanded state of the message
            [index]: !prevState[index]
        }));
    };

    return (
        <div className="container border rounded p-4 d-flex flex-column" style={{ minHeight: '400px', backgroundColor: '#e3f2fd'  }}>
            {/* Visualize system and user message */}
            <div className="row">
                <div className="col">
                    <div className="messages-container" style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {messages.slice(0).map((message, index) => (
                        <div key={index} className={`message ${message.role} row mb-2`}>
                        {message.role === 'user' && 
                            <div className="col-9 offset-3">
                                <div className="bg-light p-2 rounded text-end">{user}: {message.content}</div>
                            </div>
                        }
                        {message.role === 'system' && 
                            <div className="col-8">

                                <div className="d-flex align-items-start">

                                    <img src={Papierboot} className="img-fluid" alt="Responsive" style={{ width: '20%', height: 'auto' }}/> 

                                    <div className="bg-primary text-white p-2 rounded text-start">

                                        <div className="d-inline">
                                            {expandedMessages[index] ? message.content : message.content.substring(0, 110)}
                                        </div>
                                        {message.content.length > 110 && (
                                            <button 
                                                className="btn btn-link btn-sm text-white ms-2 d-inline" 
                                                onClick={() => handleExpand(index)} 
                                                style={{ marginLeft: '5px' }}
                                            >
                                                {expandedMessages[index] ? "Collapse" : "Expand"}
                                            </button>
                                        )}
                                    </div>
                                    {/*
                                    <div className="bg-info text-white p-2 rounded text-start">UCM: {message.content}</div>
                                    */}

                                </div>

                            </div>
                        }
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Visualize input message */}
            <div className="row mt-auto">
                <div className="col">
                <form onSubmit={handleSubmit} className="input-form mt-3">
                    <div className="input-group">
                        {/*
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Hey {user}! Start by entering a brief description what use case you aim to model.</Tooltip>}
                        >    
                        */}
                            <textarea 
                                type="text" 
                                rows={3}
                                placeholder='Start by entering a brief description what use case you aim to model.'
                                value={inputValue} 
                                onChange={handleChange} 
                                className="form-control" />
                        {/*}
                        </OverlayTrigger>
                        */}
                        {/*
                        <OverlayTrigger placement="top" overlay={<Tooltip>Click here and UCM will apply its method and semantic model on your initial prompt to build up your entered use case in question</Tooltip>}>                                                        
                        <button 
                            type="submit" 
                            className="btn btn-primary"
                            disabled={!inputValue.trim()}
                        >
                            <i className="bi bi-send"></i>
                        </button>                        
                        </OverlayTrigger> 
                        */}
                    </div>
                </form>
                </div>
            </div>
        </div>
    );
};

export default Chat;
