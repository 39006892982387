/**
 * Actor mapping for the use case
 * 
 * Description: Model the actors for the use case
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-12
 * 
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Row, Col, Tooltip, OverlayTrigger, Badge, ListGroup } from 'react-bootstrap';
import vis from 'vis-network';

import AddEdgeModal from '../actor_handling/ModalAddEdge';
import EditEdgeModal from '../actor_handling/ModalEditEdge';
import AddNodeModal from '../actor_handling/ModalAddNode';
import EditNodeModal from '../actor_handling/ModalEditNode';

import { getCurrentDate } from '../utils/utils_date';
import { UserInformation } from '../auth_mgmt/auth_handling';

const ContextMenu = ({ position, options, onOptionClick, closeMenu }) => {
    const menuRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          closeMenu();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [closeMenu]);
  
    return (
      <div
        ref={menuRef}
        className="context-menu"
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
          position: 'absolute',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
          zIndex: 1000,
        }}
      >
        {options.map((option, index) => (
          <div
            key={index}
            className="context-menu-item"
            onClick={() => onOptionClick(option)}
            style={{ padding: '8px 12px', cursor: 'pointer' }}
          >
            {option.label}
          </div>
        ))}
      </div>
    );
};

// Define initial nodes and edges
const initialNodes = [    
    { id: 'Actor', label: 'Actors', group: 'Initial', font: { size: 20, bold: true, color: 'white' }, color: { background: 'black', border: 'black' }, widthConstraint: { minimum: 100 } },
    /*
    { id: 'Commerce', label: 'Commerce', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Governance', label: 'Governance', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Infrastructure', label: 'Infrastructure', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Public', label: 'Public', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },    
    */
    { id: 'Producer', label: 'Producer / Supplier', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Consumer', label: 'Consumer / Recipient', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Regulator', label: 'Regulator', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Technical', label: 'Technical Enabler', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Operator', label: 'Operator', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
    { id: 'Platform', label: 'Platform Provider', group: 'Initial', font: { size: 20 }, color: { background: 'lightgray', border: 'lightgray' }, widthConstraint: { minimum: 100 } },
];

const initialEdges = [
    /*
    { from: 'Actor', to: 'Commerce', dashes: true },
    { from: 'Actor', to: 'Governance', dashes: true },
    { from: 'Actor', to: 'Infrastructure', dashes: true },
    { from: 'Actor', to: 'Public', dashes: true },
    */
    { from: 'Actor', to: 'Producer', dashes: true },
    { from: 'Actor', to: 'Consumer', dashes: true },
    { from: 'Actor', to: 'Regulator', dashes: true },
    { from: 'Actor', to: 'Technical', dashes: true },
    { from: 'Actor', to: 'Operator', dashes: true },
    { from: 'Actor', to: 'Platform', dashes: true },    
];

const locales = {
        en: {
          edit: 'Edit',
          del: 'Delete selected',
          back: 'Back',
          addNode: 'Add Node',
          addEdge: 'Add Edge',
          editNode: 'Edit Node',
          editEdge: 'Edit Edge',
          addDescription: 'Click in an empty space to place a new node.',
          edgeDescription: 'Click on a node and drag the edge to another node to connect them.',
          editEdgeDescription: 'Click on the control points and drag them to a node to connect to it.',
          createEdgeError: 'Cannot link edges to a cluster.',
          deleteClusterError: 'Clusters cannot be deleted.',
          editClusterError: 'Clusters cannot be edited.'
        }
};

const ListView = ({ nodes }) => {
    // Group nodes by their categories
    const categoryNodes = nodes.reduce((acc, node) => {
      if (!acc[node.group]) {
        acc[node.group] = [];
      }
      acc[node.group].push(node);
      return acc;
    }, {});
  
    return (
      <ListGroup>
        {Object.keys(categoryNodes).map((category) => (
          <ListGroup.Item key={category} className="d-flex justify-content-between align-items-center">
            <div>
              <strong>{category}</strong>
              <div>
                {categoryNodes[category].map((node) => (
                  <Badge key={node.id} bg="light" text="dark" style={{ margin: '5px' }}>
                    {node.label}
                  </Badge>
                ))}
              </div>
            </div>
            <Badge pill bg="primary">
              {categoryNodes[category].length}
            </Badge>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
};

const Actor = ({ data, setData, useCaseDetails }) => {

    const networkRef = useRef(null);
    const containerRef = useRef(null);

    const [contextMenu, setContextMenu] = useState(null);

    const [currentLayout, setCurrentLayout] = useState('circle');

    const layoutOptions = useMemo(() => ({
        /*
        normal: {
            physics: {
                enabled: true,
                solver: 'barnesHut',
                barnesHut: {
                    gravitationalConstant: -5000,
                    centralGravity: 0.3,
                    springLength: 200,
                    springConstant: 0.04,
                    damping: 0.09,
                    avoidOverlap: 1,
                },
                stabilization: {
                    iterations: 200,
                },
            },
        },*/
        normal: {
            physics: {
                enabled: true,
                solver: 'forceAtlas2Based',
                forceAtlas2Based: {
                    gravitationalConstant: -50,
                    centralGravity: 0.01,
                    springLength: 100,
                    springConstant: 0.08,
                    damping: 0.4,
                    avoidOverlap: 1,
                },
                stabilization: {
                    iterations: 200,
                },
            },
        },
        hierarchical: {
            layout: {
                hierarchical: {
                    enabled: true,
                    levelSeparation: 150,
                    nodeSpacing: 100,
                    treeSpacing: 200,
                    blockShifting: true,
                    edgeMinimization: true,
                    parentCentralization: true,
                    direction: 'UD', // UD, DU, LR, RL
                    sortMethod: 'hubsize', // hubsize, directed
                    shakeTowards: 'leaves' // roots, leaves
                },
            },
            physics: {
                enabled: false,
            },
        },
        grid: {
            layout: {
                improvedLayout: false,
            },
            physics: {
                enabled: false,
            },
        },
        circular: {
            layout: {
                improvedLayout: false,
                hierarchical: false,
            },
            physics: {
                enabled: false,
            },
        },
    }), []);

    const [nodes, setNodes] = useState([...initialNodes]);
    const [edges, setEdges] = useState([...initialEdges]);

    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalAddEdge, setShowModalAddEdge] = useState(false);
    const [showModalEditEdge, setShowModalEditEdge] = useState(false);

    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedEdge, setSelectedEdge] = useState(null);

    // Update the network data
    useEffect(() => {

        const updateNetwork = () => {
            const { nodes, edges } = data.actors.value;
            setNodes(nodes.value);
            setEdges(edges.value);
        };

        updateNetwork();
    }, [data.actors]);

    // Initialize the vis.js network
    useEffect(() => {
        if (containerRef.current) {
            // Save current node positions
            const positions = networkRef.current?.getPositions() || {};
    
            // Create data object with nodes and edges
            const data = {
                nodes: nodes.map(node => ({
                    ...node,
                    x: positions[node.id]?.x ?? node.x,
                    y: positions[node.id]?.y ?? node.y,
                })),
                edges: edges,
            };
    
            // Define options for the network
            const options = {
                locales: locales,
                locale: 'en',
                nodes: {
                    shape: 'box', //'dot',
                    margin: 10,
                    widthConstraint: {
                        maximum: 200,
                    },
                    size: 20,
                },
                edges: {
                    arrows: 'to',
                },
                interaction: {
                    hover: true,
                    hoverConnectedEdges: true,
                    selectConnectedEdges: false,
                },
                ...layoutOptions[currentLayout],
            };
    
            // Initialize or update the network
            networkRef.current = new vis.Network(containerRef.current, data, options);
    
            // Set up event listeners for context menu and click
            networkRef.current.on('oncontext', (params) => {
                params.event.preventDefault();
                const { clientX, clientY } = params.event; // Use clientX and clientY for accurate positioning
                setContextMenu({
                    position: { x: clientX, y: clientY },
                    nodeId: params.nodes[0] || null,
                });
            });

            // Double click on a node
            networkRef.current.on('doubleClick', (params) => {
                if (params.nodes.length > 0) {
                    const clickedNodeId = params.nodes[0];                                            
    
                    // Call the add node function
                    //handleAddNode(null, clickedNodeId);
                    setSelectedNode(nodes.find(n => n.id === clickedNodeId)); // Pre-select the source node
                    setShowModalAdd(true);
                }
            });
    
            networkRef.current.on('click', () => {
                closeContextMenu();
            });
        }
    
    }, [nodes, edges, currentLayout, layoutOptions, data.actors, setShowModalAdd]);
    

    const handleContextMenuOptionClick = (option) => {
        //console.log(`Selected option: ${option.label} for node ${contextMenu.nodeId}`);

        /*
        if (!contextMenu?.nodeId) {
            alert('Please select an actor first.');
            return;
        }*/

        //console.log('Selected option:', option.label);

        switch (option.label) {
            case 'Add Node':
                //console.log('Add Node');                
                //console.log('Node ID:', contextMenu);
                if (contextMenu?.nodeId) {
                    handleAddNode(null, contextMenu?.nodeId);
                } else {
                    setShowModalAdd(true);
                }
                break;
            case 'Edit Node':
                handleEditNode();
                break;
            case 'Delete Node':
                handleDeleteNode();
                break;
            case 'Add Edge':
                handleAddEdge();
                break;
            case 'Edit Edge':
                handleEditEdge();
                break;
            case 'Delete Edge':
                handleDeleteEdge();
                break;
            default:
                break;
        }
        closeContextMenu();
        setContextMenu(null);
    };

    const closeContextMenu = () => {
        setContextMenu(null);
    };

    const changeLayout = (layout) => {
        setCurrentLayout(layout);
        if (layout !== 'list') {
            networkRef.current.setOptions(layoutOptions[layout]);
        }
    };

    const handleAddNode = (newNodeLabel = null, connectedNodeId = null) => {
        /*
        if (!newNodeLabel) {
          newNodeLabel = prompt(locales.en.addNode);
          if (!newNodeLabel) return; // Exit if no label is provided
        }
      
        if (!connectedNodeId) {
          const checkNodeExists = () => {
            connectedNodeId = prompt('Enter the ID of the actor to which this node should be connected:');
            if (!connectedNodeId) {
              return false; // User cancelled the operation
            }
      
            const nodeExists = nodes.some(node => node.id === connectedNodeId);
            if (!nodeExists) {
              alert('The entered node ID does not exist. Please enter a valid node ID.');
            }
      
            return nodeExists;
          };
      
          while (!checkNodeExists()) {
            if (!connectedNodeId) {
              return; // Exit if user cancels the operation
            }
          }
        }

        */
      
        // Check if the new node label already exists
        const labelExists = nodes.some(node => node.label === newNodeLabel);
        if (labelExists) {
            //const editOrNew = 
            alert('Node with this label already exists. Enter "edit" to edit the existing node or "new" to enter a new node name:');
            /*
            if (editOrNew === 'edit') {
                const existingNode = nodes.find(node => node.label === newNodeLabel);
                if (existingNode) {
                const newLabel = prompt('Enter the new name for the existing node:', existingNode.label);
                if (newLabel) {
                    setNodes(prevNodes => prevNodes.map(node => node.id === existingNode.id ? { ...node, label: newLabel } : node));
                }
                }
                return;
            } else if (editOrNew === 'new') {
                newNodeLabel = prompt(locales.en.addNode);
                if (!newNodeLabel) return;
            } else {
                return; // Exit if user doesn't provide a valid option
            }
            */
           return;
        }

        setSelectedNode(nodes.find(n => n.id === connectedNodeId)); // Pre-select the source node
        setShowModalAdd(true);

        // Check if newNodeLabel is provided
        if (!newNodeLabel) {
            return;
        }

        // Find the connected node to determine its category
        const connectedNode = nodes.find(node => node.id === connectedNodeId);
        const category = connectedNode.id;
      
        const newNode = {
            id: `Manual_${crypto.randomUUID()}`, // `Manual_${newNodeLabel}`,
            label: newNodeLabel,
            group: category,
        };
        const newEdge = {
            from: connectedNodeId,
            to: newNode.id,
        };
        setNodes(prevNodes => [...prevNodes, newNode]);
        setEdges(prevEdges => [...prevEdges, newEdge]);

        // Update the data
        /*
        setData(prevData => ({
            ...prevData,
            actors: {
                list: {
                    ...prevData.actors.list,
                    [category]: [...prevData.actors.list[category], newNodeLabel],
                },
                nodes: [...prevData.actors.nodes, newNode],
                edges: [...prevData.actors.edges, newEdge],
            },
        }));
        */
        setData(prevData => ({
            ...prevData,
            actors: {
                value: {
                    list: {
                        ...prevData.actors.value.list,
                        [category]: {
                            ...prevData.actors.value.list[category],
                            value: [...prevData.actors.value.list[category].value, newNodeLabel],
                            source: 'manual',
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: prevData.actors.value.list[category].version + 1,
                        }
                    },
                    nodes: {
                        ...prevData.actors.value.nodes,
                        value: [...prevData.actors.value.nodes.value, newNode],
                        source: 'manual',
                        modified: true,
                        updated_timestamp: getCurrentDate(),
                        updated_by: UserInformation().displayName,
                        uid: UserInformation().uid,
                        version: prevData.actors.value.nodes.version + 1,                
                    },
                    edges: {
                        ...prevData.actors.value.edges,
                        value: [...prevData.actors.value.edges.value, newEdge],
                        source: 'manual',
                        modified: true,
                        updated_timestamp: getCurrentDate(),
                        updated_by: UserInformation().displayName,
                        uid: UserInformation().uid,
                        version: prevData.actors.value.edges.version + 1,                    
                    }
                }
            }
        }));  
        
    };
    
    const handleEditNode = (newNodeLabel = null) => {
        /*
        const selectedNodeId = networkRef.current.getSelectedNodes()[0];
        if (selectedNodeId) {
        const newLabel = prompt('Enter the new name for the actor:');
        if (newLabel) {
            setNodes(prevNodes => prevNodes.map(node => (node.id === selectedNodeId ? { ...node, label: newLabel } : node)));
        }
        }
        */

        const fromNodeId = networkRef.current.getSelectedNodes()[0];
        //console.log(fromNodeId)
        if (!fromNodeId) {
            alert('Please select the source actor first.');
            return;
        } else if ( fromNodeId === 'Actor' 
            ||      fromNodeId === 'Producer'
            ||      fromNodeId === 'Consumer'
            ||      fromNodeId === 'Regulator'
            ||      fromNodeId === 'Technical'
            ||      fromNodeId === 'Operator'
        ) {
            alert('Baseline nodes cannot be edited');
            return;
        } else {
            setSelectedNode(nodes.find(n => n.id === fromNodeId)); // Pre-select the source node
            //console.log('selectednode', selectedNode);
            //console.log('out', nodes.find(n => n.id === fromNodeId))
            // Show the modal to edit the node
            setShowModalEdit(true);
        }

        // Update the data
        if (selectedNode) {
            //console.log('Selected node:', selectedNode);
            //console.log('New node label:', newNodeLabel);
            setNodes(prevNodes => prevNodes.map(n => 
                n.id === selectedNode.id ? { ...n, label: newNodeLabel } : n
            ));
            setShowModalEdit(false);

            /*
            setData(prevData => ({
                ...prevData,
                actors: {
                    ...prevData.actors,
                    nodes: prevData.actors.nodes.map(n =>
                        n.id === selectedNode.id ? { ...n, label: newNodeLabel } : n
                    ),
                    list: {
                        ...prevData.actors.list,
                        [selectedNode.group]: prevData.actors.list[selectedNode.group].map(label =>
                            label === selectedNode.label ? newNodeLabel : label
                        ),
                    },
                },
            }));
            */

            setData(prevData => ({
                ...prevData,
                actors: {
                    value: {
                        nodes: {
                            ...prevData.actors.value.nodes,
                            value: prevData.actors.value.nodes.value.map(n =>
                                n.id === selectedNode.id ? { ...n, label: newNodeLabel } : n
                            ),
                            source: 'manual',
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: prevData.actors.value.nodes.version + 1,
                        },
                        edges: {
                            ...prevData.actors.value.edges,
                            value: prevData.actors.value.edges.value.map(edge =>
                                edge.from === selectedNode.id ? { ...edge, from: newNodeLabel } : edge
                            ),
                            source: 'manual',
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: prevData.actors.value.edges.version + 1,
                        },
                        list: {
                            ...prevData.actors.value.list,
                            [selectedNode.group]: {
                                ...prevData.actors.value.list?.[selectedNode.group],
                                value: prevData.actors.value.list?.[selectedNode.group]?.value.map(label =>
                                    label === selectedNode.label ? newNodeLabel : label
                                ),
                                source: 'manual',
                                modified: true,
                                updated_timestamp: getCurrentDate(),
                                updated_by: UserInformation().displayName,
                                uid: UserInformation().uid,
                                version: prevData.actors.value.list?.[selectedNode.group]?.version + 1,
                            }
                        }
                    }
                }
            }));

            setSelectedNode(null)
        }

        /*
        const node = nodes.find(n => n.id === networkRef.current.getSelectedNodes()[0]);
        if (node) {
            setSelectedNode(node);
            setShowModalEdit(true);
        } else {
            alert('Please select an actor first.');
        }*/

        //setShowModalEdit(true);    
    };

    const handleDeleteNode = () => {
        const selectedNodeId = networkRef.current.getSelectedNodes()[0];
        const selectedNode = nodes.find(n => n.id === selectedNodeId);
        
        if (!selectedNode) return;

        // Do not allow deletion of baseline nodes
        if (   selectedNodeId === 'Actor'
            || selectedNodeId === 'Producer'
            || selectedNodeId === 'Consumer'
            || selectedNodeId === 'Regulator'
            || selectedNodeId === 'Technical'
            || selectedNodeId === 'Platform'
            || selectedNodeId === 'Operator'
        ) {
            alert('Baseline nodes cannot be deleted');
            return;
        }

        if (selectedNodeId) {
        setNodes(prevNodes => prevNodes.filter(node => node.id !== selectedNodeId));
        setEdges(prevEdges => prevEdges.filter(edge => edge.from !== selectedNodeId && edge.to !== selectedNodeId));
        } else {
            alert('Please select an actor first.');
        }

        setData(prevData => ({
            ...prevData,
            actors: {
                value: {
                    list: {
                        ...prevData.actors.value.list,
                        [selectedNode.group]: {
                            ...prevData.actors.value.list[selectedNode.group],
                            value: prevData.actors.value.list[selectedNode.group].value.filter(label => label !== selectedNode.label),
                            source: 'manual',
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: prevData.actors.value.list[selectedNode.group].version + 1,
                        }
                    },          
                    nodes: {
                        ...prevData.actors.value.nodes,
                        value: prevData.actors.value.nodes.value.filter(node => node.id !== selectedNodeId),
                        modified: true,
                        updated_timestamp: getCurrentDate(),
                        updated_by: UserInformation().displayName,
                        uid: UserInformation().uid,
                        version: prevData.actors.value.nodes.version + 1,
                    },
                    edges: {
                        ...prevData.actors.value.edges,
                        value: prevData.actors.value.edges.value.filter(edge => edge.from !== selectedNodeId && edge.to !== selectedNodeId),
                        modified: true,
                        updated_timestamp: getCurrentDate(),
                        updated_by: UserInformation().displayName,
                        uid: UserInformation().uid,
                        version: prevData.actors.value.edges.version + 1,
                    },    
                }                            
            }
        }));

    };

    /*
    const handleAddEdge0 = (newEdge = null) => {

        const fromNode = networkRef.current.getSelectedNodes()[0];

        setSelectedNode(nodes.find(n => n.id === fromNode));

        // update the data
        if (selectedNode && newEdge) {

            setEdges(prevEdges => [...prevEdges, newEdge]);
            setShowModalAddEdge(false);

            setData(prevData => ({
                ...prevData,
                actors: {
                    value: {
                        list: {
                            ...prevData.actors.value.list,
                            [selectedNode.group]: {
                                ...prevData.actors.value.list[selectedNode.group],
                                value: prevData.actors.value.list[selectedNode.group].value,
                                source: 'manual',
                                modified: true,
                                updated_timestamp: getCurrentDate(),
                                updated_by: UserInformation().displayName,
                                uid: UserInformation().uid,
                                version: prevData.actors.value.list[selectedNode.group].version + 1,
                            }
                        },
                        nodes: {
                            ...prevData.actors.value.nodes,
                            value: prevData.actors.value.nodes.value,
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: prevData.actors.value.nodes.version + 1,
                        },
                        edges: {
                            ...prevData.actors.value.edges,
                            value: [...prevData.actors.value.edges.value, newEdge],
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: prevData.actors.value.edges.version + 1,
                        }
                    }

                }
            }));
        }

        setShowModalAddEdge(true);
    };
    */

    const handleAddEdge = () => {
        // Get the selected node from the network
        const fromNodeId = networkRef.current.getSelectedNodes()[0];  
        if (!fromNodeId) {
            alert('Please select a source actor first.');
            return;
        }
    
        // Find the selected node based on its ID
        const selectedNode = nodes.find(n => n.id === fromNodeId);
        if (!selectedNode) {
            alert('Source node not found.');
            return;
        }
    
        // Set the selected node and open the modal
        setSelectedNode(selectedNode);
        setShowModalAddEdge(true);  // Open the modal for selecting the target node
    };

    const onAddEdge = (newEdge) => {
        // Add the new edge to the state
        setEdges((prevEdges) => [...prevEdges, newEdge]);
    
        // Update the data structure
        setData((prevData) => ({
            ...prevData,
            actors: {
                value: {
                    ...prevData.actors.value,
                    edges: {
                        ...prevData.actors.value.edges,
                        value: [...prevData.actors.value.edges.value, newEdge],
                        modified: true,
                        updated_timestamp: getCurrentDate(),
                        updated_by: UserInformation().displayName,
                        uid: UserInformation().uid,
                        version: prevData.actors.value.edges.version + 1,
                    },
                },
            },
        }));
    
        setShowModalAddEdge(false);  // Close the modal after edge is added
    };

    /*
    const handleEditEdge0 = (updatedEdge = null) => {

        const selectedEdgeId = networkRef.current.getSelectedEdges()[0];

        // Find the selected edge
        const edgeToDelete = edges.find(edge => edge.id === selectedEdgeId);
        if (!edgeToDelete) {
            alert('Edge not found.');
            return;
        }

        // Check if the edge is part of the initialEdges
        const isInitialEdge = initialEdges.some(
            initialEdge =>
                initialEdge.from === edgeToDelete.from &&
                initialEdge.to === edgeToDelete.to
        );

        if (isInitialEdge) {
            alert('This connection is part of the initial setup and cannot be edited.');
            return;
        }

        // Edit the edge from the state
        if (!selectedEdgeId) {
            alert('Please select the edge you want to edit.');
            return;
        } else {
            const selectedEdge = edges.find(edge => edge.id === selectedEdgeId);
            if (selectedEdge) {
                setSelectedEdge(selectedEdge);
                setShowModalEditEdge(true);
            }
        }

        // Update the data
        if (selectedEdge) {
            setEdges(prevEdges => prevEdges.map(edge =>
                edge.id === updatedEdge.id ? updatedEdge : edge
            ));
            setShowModalEditEdge(false);

            setData(prevData => ({
                ...prevData,
                actors: {
                    value: {
                        nodes: {
                            ...prevData.actors.value.nodes,
                            value: prevData.actors.value.nodes.value,
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: prevData.actors.value.nodes.version + 1,
                        },
                        edges: {
                            ...prevData.actors.value.edges,
                            value: prevData.actors.value.edges.value.map(edge =>
                                edge.id === updatedEdge.id ? updatedEdge : edge
                            ),
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: prevData.actors.value.edges.version + 1,
                        }
                    }
                }
            }));

            setSelectedEdge(null);
        }
    };    
    */

    const handleEditEdge = (updatedEdge = null) => {

        const selectedEdgeId = networkRef.current.getSelectedEdges()[0];
    
        // Find the selected edge
        const edgeToEdit = edges.find(edge => edge.id === selectedEdgeId);
        if (!edgeToEdit) {
            alert('Edge not found.');
            return;
        }
    
        // Check if the edge is part of the initialEdges
        const isInitialEdge = initialEdges.some(
            initialEdge =>
                initialEdge.from === edgeToEdit.from &&
                initialEdge.to === edgeToEdit.to
        );
    
        if (isInitialEdge) {
            alert('This connection is part of the initial setup and cannot be edited.');
            return;
        }

        // Open edit modal
        if (selectedEdgeId) {
            setSelectedEdge(edgeToEdit);
            setShowModalEditEdge(true);            
        }
    
        if (selectedEdge) {
            // Update edges in the state
            setEdges(prevEdges =>
                prevEdges.map(edge => (edge.id === updatedEdge.id ? updatedEdge : edge))
            );
    
            setData(prevData => {
                const updatedActors = {
                    ...prevData.actors,
                    value: {
                        ...prevData.actors.value,
                        edges: {
                            ...prevData.actors.value.edges,
                            value: prevData.actors.value.edges?.value.map(edge =>
                                edge.id === updatedEdge.id ? updatedEdge : edge
                            ) || [], // Ensure edges are properly initialized
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: (prevData.actors.value.edges?.version || 0) + 1,
                        },
                        nodes: {
                            ...prevData.actors.value.nodes,
                            modified: true,
                            updated_timestamp: getCurrentDate(),
                            updated_by: UserInformation().displayName,
                            uid: UserInformation().uid,
                            version: (prevData.actors.value.nodes?.version || 0) + 1,
                        },
                    },
                };
    
                return {
                    ...prevData,
                    actors: updatedActors,
                };
            });
    
            setSelectedEdge(null);
            setShowModalEditEdge(false);
        }
    };    

    const handleDeleteEdge = () => {

        const selectedEdgeId = networkRef.current.getSelectedEdges()[0];

        // Find the selected edge
        const edgeToDelete = edges.find(edge => edge.id === selectedEdgeId);
        if (!edgeToDelete) {
            alert('Edge not found.');
            return;
        }

        // Check if the edge is part of the initialEdges
        const isInitialEdge = initialEdges.some(
            initialEdge =>
                initialEdge.from === edgeToDelete.from &&
                initialEdge.to === edgeToDelete.to
        );

        if (isInitialEdge) {
            alert('This connection is part of the initial setup and cannot be deleted.');
            return;
        }

        // Delete the edge from the state
        if (selectedEdgeId) {
            setEdges(prevEdges => prevEdges.filter(edge => edge.id !== selectedEdgeId));
        }

        setData(prevData => ({
            ...prevData,
            actors: {
                value: {
                    ...prevData.actors.value,
                    edges: {
                        ...prevData.actors.value.edges,
                        value: prevData.actors.value.edges.value.filter(edge => edge.id !== selectedEdgeId),
                        modified: true,
                        updated_timestamp: getCurrentDate(),
                        updated_by: UserInformation().displayName,
                        uid: UserInformation().uid,
                        version: prevData.actors.value.edges.version + 1,                    
                    }
                }
            }
        }));
    };

    const contextMenuOptions = [
        { label: 'Add Node' },
        { label: 'Edit Node' },                
        { label: 'Delete Node' },
        { label: 'Add Edge' },
        { label: 'Edit Edge' },        
        { label: 'Delete Edge' },        
    ];

    const handleZoomIn = () => {
        if (networkRef.current) {
            const scale = networkRef.current.getScale();
            networkRef.current.moveTo({
                scale: scale * 1.2, // Zoom in
            });
        }
    };
    
    const handleZoomOut = () => {
        if (networkRef.current) {
            const scale = networkRef.current.getScale();
            networkRef.current.moveTo({
                scale: scale * 0.8, // Zoom out
            });
        }
    };

    return (
        <div>
            <Row>
                {/* Headline and Toolbar */}
                <Col md={3}>
                    <div>
                        <h3 className="text-start">Model Actors</h3>
                        <h6 className="text-start">Look up, adjust or add manually</h6>
                    </div>
                </Col>
                {/* Toolbar */}
                <Col md={9} className="d-flex justify-content-end align-items-center">
                    {/*
                    <OverlayTrigger placement="top" overlay={<Tooltip>Search for a specific actor in the landscape</Tooltip>}>
                        <Form>
                            <Form.Group>
                                <Form.Control
                                    disabled
                                    style={{ maxWidth: '200px' }}
                                    // size='sm'
                                    type="text"
                                    placeholder="Search actor"
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            // handleSearch(e);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </OverlayTrigger>   
                    <OverlayTrigger placement="top" overlay={<Tooltip>Filter a category of actors</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                            <i className="bi bi-funnel"></i>
                        </Button>
                    </OverlayTrigger>                     
                    <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
                    */}
                    <OverlayTrigger placement="top" overlay={<Tooltip>Add an actor and link it.</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={() => setShowModalAdd(true)}>
                            <i className="bi bi-plus"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Select an actor first. Click this button to edit the name.</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleEditNode}>
                            <i className="bi bi-pencil"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Select an actor first. Click this button to delete it.</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleDeleteNode}>
                            <i className="bi bi-trash"></i>
                        </Button>
                    </OverlayTrigger>                    
                    <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Add relation</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleAddEdge}>
                            <i className="bi bi-link-45deg"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Edit relation</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleEditEdge}>
                            <i className="bi bi-arrows-move"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Delete relation</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleDeleteEdge}>
                            <i className="bi bi-x-octagon"></i>
                        </Button>                    
                    </OverlayTrigger>
                    <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
                    <Button onClick={() => handleZoomIn()} style={{ marginLeft: '10px' }} variant="outline-secondary">
                            <i className="bi bi-zoom-in"></i>
                    </Button>
                    <Button onClick={() => handleZoomOut()} style={{ marginLeft: '10px' }} variant="outline-secondary">
                        <i className="bi bi-zoom-out"></i>
                    </Button>
                    <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Star View</Tooltip>}>
                    <Button
                        style={{ marginLeft: '10px' }}
                        variant={currentLayout === 'normal' ? 'secondary' : 'outline-secondary'}
                        onClick={() => changeLayout('normal')}
                    >
                        <i className="bi bi-asterisk"></i>
                    </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Hierarchy View</Tooltip>}>
                        <Button
                            style={{ marginLeft: '10px' }}
                            variant={currentLayout === 'hierarchical' ? 'secondary' : 'outline-secondary'}
                            onClick={() => changeLayout('hierarchical')}
                        >
                            <i className="bi bi-diagram-3"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Circle View</Tooltip>}>
                        <Button
                            style={{ marginLeft: '10px' }}
                            variant={currentLayout === 'circle' ? 'secondary' : 'outline-secondary'}
                            onClick={() => changeLayout('circle')}
                        >
                            <i className="bi bi-circle"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>List View</Tooltip>}>
                        <Button
                            style={{ marginLeft: '10px' }}
                            variant={currentLayout === 'list' ? 'secondary' : 'outline-secondary'}
                            onClick={() => changeLayout('list')}
                        >
                            <i className="bi bi-list-nested"></i>
                        </Button>
                    </OverlayTrigger>                
                    <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>

                    <OverlayTrigger placement="top" overlay={<Tooltip>Download actor landscape</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                            <i className="bi bi-download"></i>
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger placement="top" overlay={<Tooltip>Upload actor landscape</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                            <i className="bi bi-upload"></i>
                        </Button>
                    </OverlayTrigger>         

                </Col>
            </Row>
            {/* Sidebar and Network Visualization */}
            <Row>
                {/* Network Visualization */}
                <Col md={12} style={{ textAlign: 'left', marginTop: '15px' }}>
                    {currentLayout === 'list' ? (
                        <ListView nodes={nodes} />
                    ) : (
                        <>
                            <div ref={containerRef} style={{ height: '500px', width: '100%', border: '0.5px solid black' }}></div>
                            {contextMenu && (
                                <ContextMenu
                                    position={contextMenu.position}
                                    options={contextMenuOptions}
                                    onOptionClick={handleContextMenuOptionClick}
                                    closeMenu={() => setContextMenu(null)}
                                />
                            )}
                        </>
                    )}                    
                </Col>
            </Row>
            {/* Add Node Modal */}       
            <AddNodeModal
                show={showModalAdd}
                onHide={() => setShowModalAdd(false)}
                nodes={nodes}
                targetNode={selectedNode}
                onAddNode={handleAddNode}                
            />
            {/* Edit Node Modal */}
            <EditNodeModal
                show={showModalEdit}
                onHide={() => setShowModalEdit(false)}
                node={selectedNode}
                onEditNode={handleEditNode}
            />
            {/* Add Edge Modal */}
            <AddEdgeModal
                show={showModalAddEdge}
                onHide={() => { setShowModalAddEdge(false); }}
                nodes={nodes}
                onAddEdge={onAddEdge}
                fromNode={selectedNode}
            />
            {/* Edit Edge Modal */}
            <EditEdgeModal
                show={showModalEditEdge}
                onHide={() => { setShowModalEditEdge(false); }}
                nodes={nodes}
                edge={selectedEdge}
                onEditEdge={handleEditEdge}
            />                        
        </div>
    );
    
}

export default Actor;