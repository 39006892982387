/**
 * Database Management - Write
 *
 * Description: This module contains functions to write data to the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-22
 * 
 */

import { doc, updateDoc, deleteDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

import { fetchDbConfigurations } from '../db_config_adapter';

//const db_name = 'alpha_use_cases';


// retrieve the database configuration
const identifyInterviewDbConfig = async () => {
  try {

    // Fetch the database configuration
    const dbConfigs = await fetchDbConfigurations();
    const interviewDbConfig = dbConfigs.find(config => config.meta.applicationArea === "db_use_case" && config.active);
    return interviewDbConfig.dbName;

  } catch (error) {
    console.error('Error fetching database configurations:', error);
    return null;
  }
};

const saveUseCaseData = async (useCaseData) => {
  try {

    const db_name = await identifyInterviewDbConfig();

    await addDoc(collection(db, db_name), {
      ...useCaseData,
      created: serverTimestamp(), // Use Firestore server timestamp
      updated: serverTimestamp()
    });
    console.log('Use case data saved successfully!');
  } catch (error) {
    console.error('Error saving use case data: ', error);
  }
};

const updateUseCaseData = async (useCaseData) => {
  
  try {

    const db_name = await identifyInterviewDbConfig();

    const useCaseDocRef = doc(db, db_name, useCaseData.id);
    await updateDoc(useCaseDocRef, {
      ...useCaseData,
      updated: serverTimestamp()
    });
    console.log('Use case data updated successfully!');
  } catch (error) {
    console.error('Error updating use case data: ', error);
  }
  
};

// Delete a use case
const deleteUseCaseData = async (useCaseId) => {
  
  try {

    const db_name = await identifyInterviewDbConfig();

    const useCaseDocRef = doc(db, db_name, useCaseId);
    await deleteDoc(useCaseDocRef);
    console.log('Use case data deleted successfully!');
  } catch (error) {
    console.error('Error deleting use case data: ', error);
  }
};

export { saveUseCaseData, updateUseCaseData, deleteUseCaseData };