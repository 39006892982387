import React, { useEffect } from 'react';

import { Route, Routes, Navigate, useLocation } from 'react-router-dom'; // Navigate

import { NavBar } from './NavBar';

import { About } from '../../views/About';
import { UCM } from '../../views/UCM';
import { Approach } from '../../views/Approach';
import { KB } from '../../views/KB';
import { Create } from '../../views/Create';
import { Participate } from '../../views/Participate';
import { Instructions } from '../../views/Instructions';
import { Analytics } from '../../views/Analytics';
import { Repository } from '../../views/Repository';
import { Dashboard } from '../../views/Dashboard';
import { Prompts } from '../../views/Prompts';
import { Account } from '../../views/Account';
import { Survey } from '../../views/Survey';
import { Interview } from '../../views/Interview';
import { Impressum } from '../../views/Legal/Impressum';
import { Privacy } from '../../views/Legal/Privacy';
import { DB } from '../../views/DB';

export const CallRoutes = () => {

  const ScrollToTopOnNavigate = () => {
    const { pathname } = useLocation(); // React Router provides the current path

    useEffect(() => {

      setTimeout(() => {
        //console.log('ScrollToTopOnNavigate: ', pathname);
        // Scroll to the top of the page whenever the path changes
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);

    }, [pathname]); // Only re-run the effect if the path changes

    return null; // This component doesn't render anything
  };

  return (
    <div>
      <NavBar />
      <ScrollToTopOnNavigate />
      <Routes>  
        <Route exact path="/" element={ <Navigate to="/Start" /> }></Route> {/* /UCM */}
        <Route exact path="/Start" element={<UCM/>} />        
        <Route exact path="/UCM" element={<UCM/>} />
        <Route exact path="/About/*" element={<About/>} />
        <Route exact path="/Approach" element={<Approach/>} />
        <Route exact path="/KB" element={<KB/>} />
        <Route exact path="/Create" element={<Create/>} />
        <Route exact path="/Participate" element={<Participate/>} />
        <Route exact path="/Instructions" element={<Instructions/>} />
        <Route exact path="/Analytics" element={<Analytics/>} />
        <Route exact path="/Repository" element={<Repository/>} />
        <Route exact path="/Dashboard" element={<Dashboard/>} />
        <Route exact path="/Prompts" element={<Prompts/>} />
        <Route exact path="/Account" element={<Account/>} />
        <Route exact path="/Survey" element={<Survey/>} />
        <Route exact path="/Interview" element={<Interview/>} />
        <Route exact path="/Legal/Impressum" element={<Impressum/>} />
        <Route exact path="/Legal/Privacy" element={<Privacy/>} />
        <Route exact path="/DB" element={<DB/>} />
      </Routes>
    </div>
  );
};
