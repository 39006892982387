/**
 * Database Management - Read survey data
 *
 * Description: This module contains functions to read data from the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 * 
 */

import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

//import { doc, getDoc } from 'firebase/firestore';

import { fetchDbConfigurations } from '../db_config_adapter';

//const db_name = 'beta_survey';

/**
 * Reads the use case data
 * @returns 
 */
const fetchSurveyData = async () => {
    try {

      // Fetch the database configuration
      const dbConfigs = await fetchDbConfigurations();
      const surveyDbConfig = dbConfigs.find(config => config.meta.applicationArea === "db_survey" && config.active);
      if (!surveyDbConfig) {
        throw new Error('No survey database configuration found!');
      }

      const querySnapshot = await getDocs(collection(db, surveyDbConfig.dbName)); //db_name
      const surveys = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched survey data: ', surveys);
      return surveys;
    } catch (error) {
      console.error('Error fetching survey data: ', error);
    }
  };

export { fetchSurveyData };