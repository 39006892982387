/**
 * Ontology matching module
 * 
 * Description: Provides the functionaliy to match concepts to a given structure
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-09
 * 
 */

import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';


const MatchingModule = ({ enrichment, setEnrichment, isEditing }) => {
    const [sortedComponents, setSortedComponents] = useState([]);
    const [isPredefined, setIsPredefined] = useState(false);

    const predefinedSections = useMemo(() => [
        { section: 'Operations', items: ['Operations Management', 'Operations Analytics', 'Operations Platform'] },
        { section: 'Producer', items: ['Producer Analytics', 'Producer Management', 'Producer Platform'] },
        { section: 'Consumer', items: ['Consumer Analytics', 'Consumer Delivery', 'Consumer Platform'] },
        { section: 'Technical Layer', items: ['Applications', 'Platform', 'Control', 'AI-Middleware', 'Hardware'] },
        { section: 'Regulator', items: ['Directives', 'Reference Cases', 'Standards'] },
    ], []);
    
    useEffect(() => {
        const enrichmentKeys = Object.keys(enrichment);
        const hasPredefinedSections = predefinedSections.some((section) =>
            section.items.some((item) => enrichmentKeys.includes(item))
        );
        setIsPredefined(hasPredefinedSections);

        if (hasPredefinedSections) {
            const orderedComponents = predefinedSections.flatMap((section) =>
                section.items.filter((item) => enrichmentKeys.includes(item))
            );
            const additionalComponents = enrichmentKeys.filter((key) => !orderedComponents.includes(key));
            setSortedComponents([...orderedComponents, ...additionalComponents]);
        } else {
            setSortedComponents(enrichmentKeys);
        }
    }, [enrichment, predefinedSections]);

    const enrichmentValues = Object.values(enrichment);

    const handleSelectChange = (component, field, selectedOption) => {
        if (!enrichment[component] || !selectedOption) return;

        const newValue = selectedOption ? selectedOption.value : '';
        setEnrichment((prevEnrichment) => ({
            ...prevEnrichment,
            [component]: {
                ...prevEnrichment[component],
                [field]: newValue,
            },
        }));
    };

    return (
        <Row align="left">
            <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '30px' }}>
                <h4>
                    Semantic model enrichment of {} to UCM component structure
                </h4>                
            </div>

            <Row className="mb-2 text-center">
                <Col md={3}>
                    <h6 style={{ fontWeight: 'bold', color: '#007bff' }}>UCM Component Category</h6>
                </Col>
                <Col md={1}></Col>
                <Col md={3}>
                    <h6 style={{ fontWeight: 'bold', color: '#007bff' }}>Primary Concept</h6>
                </Col>
                <Col md={3}>
                    <h6 style={{ fontWeight: 'bold', color: '#007bff' }}>Secondary Concept</h6>
                </Col>
                <Col md={2}>
                    <h6 style={{ fontWeight: 'bold', color: '#007bff' }}>Meta Concept</h6>
                </Col>
            </Row>

            {isPredefined ? (
                predefinedSections.map((section, sectionIndex) => {
                    const sectionItems = section.items.filter((item) => sortedComponents.includes(item));
                    if (sectionItems.length === 0) return null;

                    return (
                        <div key={sectionIndex}>
                            <Row className="mt-4 mb-2">
                                <Col md={12}>
                                    <hr />
                                    <h5 style={{ fontWeight: 'bold', color: '#007bff' }}>
                                        {section.section}
                                    </h5>
                                    <hr />
                                </Col>
                            </Row>
                            {sectionItems.map((component, index) => (
                                <Row key={index} className="mb-2 align-items-center">
                                    <Col md={3}>
                                        <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                                            {component}
                                        </p>
                                    </Col>
                                    <Col md={1}>
                                        <i className="bi bi-arrow-left"></i>
                                    </Col>
                                    <Col md={3}>
                                        <Select
                                            isDisabled={!isEditing} // Disable field unless in edit mode
                                            options={enrichmentValues
                                                .map((concept) =>
                                                    concept.Primary ? { value: concept.Primary, label: concept.Primary } : null
                                                )
                                                .filter((option) => option !== null)}
                                            value={
                                                enrichment[component]?.Primary
                                                    ? { value: enrichment[component].Primary, label: enrichment[component].Primary }
                                                    : null
                                            }
                                            onChange={(selectedOption) => handleSelectChange(component, 'Primary', selectedOption)}
                                            isClearable
                                            placeholder="Select or type"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Select
                                            isDisabled={!isEditing}
                                            options={enrichmentValues
                                                .map((concept) =>
                                                    concept.Secondary ? { value: concept.Secondary, label: concept.Secondary } : null
                                                )
                                                .filter((option) => option !== null)}
                                            value={
                                                enrichment[component]?.Secondary
                                                    ? { value: enrichment[component].Secondary, label: enrichment[component].Secondary }
                                                    : null
                                            }
                                            onChange={(selectedOption) => handleSelectChange(component, 'Secondary', selectedOption)}
                                            isClearable
                                            placeholder="Select or type"
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Select
                                            isDisabled={!isEditing}
                                            options={enrichmentValues
                                                .map((concept) =>
                                                    concept.Meta ? { value: concept.Meta, label: concept.Meta } : null
                                                )
                                                .filter((option) => option !== null)}
                                            value={
                                                enrichment[component]?.Meta
                                                    ? { value: enrichment[component].Meta, label: enrichment[component].Meta }
                                                    : null
                                            }
                                            onChange={(selectedOption) => handleSelectChange(component, 'Meta', selectedOption)}
                                            isClearable
                                            placeholder="Select"
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    );
                })
            ) : (
                sortedComponents.map((component, index) => (
                    <Row key={index} className="mb-2 align-items-center">
                        <Col md={3}>
                            <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                                {component}
                            </p>
                        </Col>
                        <Col md={1}>
                            <i className="bi bi-arrow-left"></i>
                        </Col>
                        <Col md={3}>
                            <Select
                                isDisabled={!isEditing}
                                options={enrichmentValues
                                    .map((concept) =>
                                        concept.Primary ? { value: concept.Primary, label: concept.Primary } : null
                                    )
                                    .filter((option) => option !== null)}
                                value={
                                    enrichment[component]?.Primary
                                        ? { value: enrichment[component].Primary, label: enrichment[component].Primary }
                                        : null
                                }
                                onChange={(selectedOption) => handleSelectChange(component, 'Primary', selectedOption)}
                                isClearable
                                placeholder="Select or type"
                            />
                        </Col>
                        <Col md={3}>
                            <Select
                                isDisabled={!isEditing}
                                options={enrichmentValues
                                    .map((concept) =>
                                        concept.Secondary ? { value: concept.Secondary, label: concept.Secondary } : null
                                    )
                                    .filter((option) => option !== null)}
                                value={
                                    enrichment[component]?.Secondary
                                        ? { value: enrichment[component].Secondary, label: enrichment[component].Secondary }
                                        : null
                                }
                                onChange={(selectedOption) => handleSelectChange(component, 'Secondary', selectedOption)}
                                isClearable
                                placeholder="Select or type"
                            />
                        </Col>
                        <Col md={2}>
                            <Select
                                isDisabled={!isEditing}
                                options={enrichmentValues
                                    .map((concept) =>
                                        concept.Meta ? { value: concept.Meta, label: concept.Meta } : null
                                    )
                                    .filter((option) => option !== null)}
                                value={
                                    enrichment[component]?.Meta
                                        ? { value: enrichment[component].Meta, label: enrichment[component].Meta }
                                        : null
                                }
                                onChange={(selectedOption) => handleSelectChange(component, 'Meta', selectedOption)}
                                isClearable
                                placeholder="Select"
                            />
                        </Col>
                    </Row>
                ))
            )}
        </Row>
    );
};


export default MatchingModule;