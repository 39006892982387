/**
 * Database Management - Write Participant data
 *
 * Description: This module contains functions to write data to the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 * 
 */

import { doc, updateDoc, deleteDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

import { fetchDbConfigurations } from '../db_config_adapter';

//const db_name = 'beta_participants';


const identifyInterviewDbConfig = async () => {
  try {

    // Fetch the database configuration
    const dbConfigs = await fetchDbConfigurations();
    const interviewDbConfig = dbConfigs.find(config => config.meta.applicationArea === "db_participants" && config.active);
    return interviewDbConfig.dbName;

  } catch (error) {
    console.error('Error fetching database configurations:', error);
    return null;
  }
};

const saveParticipantData = async (participantData) => {
  try {

    const db_name = await identifyInterviewDbConfig();

    await addDoc(collection(db, db_name), {
      ...participantData,
      created: serverTimestamp(), // Use Firestore server timestamp
      updated: serverTimestamp()
    });
    console.log('Participant data saved successfully!');
  } catch (error) {
    console.error('Error saving participant data: ', error);
  }
};

const updateParticipantData = async (participantData) => {
  
  try {

    const db_name = await identifyInterviewDbConfig();

    const participantDataDocRef = doc(db, db_name, participantData.id);
    await updateDoc(participantDataDocRef, {
      ...participantData,
      updated: serverTimestamp()
    });
    console.log('Participant data updated successfully!');
  } catch (error) {
    console.error('Error updating Participant data: ', error);
  }
  
};

// Delete a use case
const deleteParticipantData = async (participantID) => {
  
  try {

    const db_name = await identifyInterviewDbConfig();

    const participantDataDocRef = doc(db, db_name, participantID);
    await deleteDoc(participantDataDocRef);
    console.log('Participant data deleted successfully!');
  } catch (error) {
    console.error('Error deleting Participant data: ', error);
  }
};

export { saveParticipantData, updateParticipantData, deleteParticipantData };