/**
 * State to hold the study participant data given by the user in the consent form.
 *
 * Description: Provides a state to hold the participant data given by the user in the consent form.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-11-16
 */

import { useState } from 'react';
import { UserInformation, CurrentUser } from '../../auth_mgmt/auth_handling';

/**
 * Advanced state to hold the participant data given by the user in the consent form.
 * @returns {Object} The participant data state and the function to set it.
 */
export const useParticipantDataHookTrack = () => {
    const [participantData, setParticipantData] = useState({
        created_by: {
            value: UserInformation().displayName,
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        user: {
            value: UserInformation().displayName,
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        user_first_name: {
            value: CurrentUser(),
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        user_id: {
            value: UserInformation().uid,
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'high',
            relevance: 'high'
        },
        anonymous_id: {
            value: '',
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
        },
        mode: {
            value: '',
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        role: {
            value: 'user',
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        consent: {
            value: false,
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        consent_date: {
            value: '',
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        place: {
            value: '',
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        experience: {
            value: '',
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        expert_field: {
            value: '',
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        },
        yearOfBirth: {
            value: '',
            source: 'manually',
            modified: false,
            created_timestamp: '',
            updated_timestamp: '',
            updated_by: '',
            uid: '',
            version: 1,
            priority: 'low',
            relevance: 'low'
        }
    });

    return [ participantData, setParticipantData ];
}
        