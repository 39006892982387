/**
 * UCM Landing Page
 *
 * Description: Describes the frame, purpose, research scope and application of the UCM tool.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-12-08
 * 
 */

import React, { useState, useMemo } from 'react';
import { Container, Row, Col, Button, Form, Alert, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { doc, getDoc } from 'firebase/firestore'; // collection, getDocs, 
import { db } from '../components/auth_mgmt/auth_config';

import Papierboot from '../assets/img/Papierboot.png';
import tool from '../assets/img/UC_SP_Repo_Insight.png';

function ContactUsModal({ show, setShow }) {
    return (
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg" // Large modal
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>About Use Case Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'center', padding: '20px' }}>

            <p style={{ fontSize: '1.2rem', lineHeight: '1.8', margin: '20px 0' }} align='left'>
                <i>UCM</i> is a domain-agnostic tool designed to explore required scenarios, actors and components to 
                conceptualize and build use cases. Modeled use cases can be analyzed against each other to find commonalities.
                The initial focus and provided knowledge bases refer to Autonomous Mobility (AM).
            </p>

            <p style={{ fontSize: '1.2rem', lineHeight: '1.8', margin: '20px 0' }} align='left'> 
                <i>UCM</i> provides a unique Four-Step-Approach to use case engineering, embedding LLMs and semantic models for improved usability, thus presenting a comprehensive solution builder.
                
                Thereby, the tool's knowledge base is built upon the two developed semantic models, 'General Use Case Ontology' (<a href="https://marc-ga.github.io/peamonto/guco.owl">GUCO</a>)
                and 'Platform Economy Autonomous Mobility Ontology' (<a href="https://marc-ga.github.io/peamonto/peamonto.owl">PEAMONTO</a>) ontologies,
                both accessible on Github.
            </p>     

            <p style={{ fontSize: '1.2rem', lineHeight: '1.8', margin: '20px 0' }} align='left'>
                This tool is used alongside PhD research efforts to investigate the complexities of AM use cases and to propose a reference model for the development of AM systems.
            </p>  

            <p style={{ fontSize: '1.2rem', lineHeight: '1.8', margin: '20px 0' }} align='left'>
              If you are interested in learning more about this research or exploring potential collaborations, 
              feel free to reach out to us at <a href="mailto:marc.augusto@tu-berlin.de" style={{ textDecoration: 'none' }}>
                marc.augusto@tu-berlin.de
              </a>
              .
            </p>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
}

function KeyEntryModal({ show, setShow, handleKeySubmit, enteredKey, setKey, error }) {

    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent default form submission behavior
  
      const isKeyValid = await handleKeySubmit(e); // Handle key submission and check result
      if (isKeyValid) {
        setShow(false); // Close modal only if the key is valid
      }
    };
  
    return (
      <>
        {/* Key Entry Modal */}
        <Modal
          show={show}
          onHide={() => setShow(false)} // Close modal
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter UCM Access Key</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicKey" className="mb-3">
                <Form.Label>Authentication Key</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Key"
                  value={enteredKey}
                  onChange={(e) => setKey(e.target.value)}
                  required
                />
              </Form.Group>
              {error && (
                <Alert variant="danger" style={{ fontSize: '14px' }}>
                  {error}
                </Alert>
              )}
              <div className="d-flex justify-content-end">
                <Button
                  variant="secondary"
                  onClick={() => setShow(false)}
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
}
/*
function FlyingLogos() {
    const flyingLogos = useMemo(() => {
      return [...Array(17)].map((_, i) => {
        const size = Math.random() * 20 + 40; // Random size between 40px and 60px
        const floatDuration = 8 + Math.random() * 6; // Float duration between 8s and 14s
        const driftDuration = 10 + Math.random() * 8; // Drift duration between 10s and 18s
        const topPosition = Math.random() * 70 + 10; // Ensure no logos are too close to the edges (10%-80%)
        const leftPosition = Math.random() * 70 + 10; // Ensure better horizontal distribution (10%-80%)
  
        return (
          <img
            key={i}
            src={Papierboot}
            alt="logo"
            style={{
              position: 'absolute',
              top: `${topPosition}%`,
              left: `${leftPosition}%`,
              width: `${size}px`,
              opacity: 0.1,
              animation: `
                float ${floatDuration}s ease-in-out infinite,
                drift ${driftDuration}s linear infinite`,
            }}
          />
        );
      });
    }, []); // Only compute once
  
    return <>{flyingLogos}</>;
}
*/

function FlyingLogos() {
  const flyingLogos = useMemo(() => {
    return [...Array(17)].map((_, i) => {
      const size = Math.random() * 20 + 40; // Random size between 40px and 60px
      const floatDuration = 8 + Math.random() * 6; // Float duration between 8s and 14s
      const driftDuration = 10 + Math.random() * 8; // Drift duration between 10s and 18s

      // Top position can be anywhere
      const topPosition = Math.random() * 90; // Anywhere vertically from 0%-90%

      // Left position focuses only on left (0%-20%) or right (80%-100%)
      const leftPosition = Math.random() < 0.5 
        ? Math.random() * 20 // Left 20%
        : 80 + Math.random() * 20; // Right 20%

      return (
        <img
          key={i}
          src={Papierboot}
          alt="logo"
          style={{
            position: 'absolute',
            top: `${topPosition}%`,
            left: `${leftPosition}%`,
            width: `${size}px`,
            opacity: 0.1,
            animation: `
              float ${floatDuration}s ease-in-out infinite,
              drift ${driftDuration}s linear infinite`,
          }}
        />
      );
    });
  }, []); // Only compute once

  return <>{flyingLogos}</>;
}

export default function LP( { onKeyVerified } ) {

    const [showContactModal, setShowContactModal] = useState(false);
    const [showKeyModal, setShowKeyModal] = useState(false);

    const [key, setKey] = useState('');
    const [error, setError] = useState('');

    const handleKeySubmit = async (e) => {
        e.preventDefault();
        setError('');
      
        try {
          const docRef = doc(db, 'auth_keys', key);
          const docSnap = await getDoc(docRef);
      
          if (docSnap.exists()) {
            console.log('Document Data:', docSnap.data());
            onKeyVerified(); // Key verified
          } else {
            setError('Invalid key.');
          }
        } catch (err) {
          setError('Error verifying the key.');
          console.error('Error:', err.message);
        }
    };

    const navigate = useNavigate();

    return (
        <Container
            fluid
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
                minHeight: '100vh',
                background: 'linear-gradient(to bottom, #f9f9f9, #ffffff)',
                textAlign: 'center',
                position: 'relative',
            }}
        >
            {/* Flying Logos */}
            <FlyingLogos />

            {/* Intro Section */}
            <Row className="justify-content-center" align="center" style={{ marginTop: '50px' }}>
                <Col md={1}></Col>
                <Col md={5} align="left" style={{ maxWidth: '700px' }}>
                    <h1
                    className="display-4"
                    style={{
                        marginBottom: '30px',
                        fontWeight: 'bold',
                        color: '#0d6efd',
                    }}
                    >
                    Use Case-Driven Solution Builder
                    </h1>
                    <p style={{ marginBottom: '20px', fontSize: '20px', color: '#555' }}>
                    <i>UCM</i> is a tool to explore scenarios, actors and components to conceptualize solutions,
                    using LLMs and semantic models for use case creation and ecosystem analysis.
                    </p>

                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 mt-3 w-100">
                        <Button
                            className="fancy-button"
                            variant="primary"
                            style={{
                            minWidth: '200px',
                            minHeight: '60px',
                            fontSize: '18px',
                            }}
                            onClick={() => setShowContactModal(true)}
                        >
                            <i className="bi bi-info-circle me-2"></i> About
                        </Button>

                        <Button
                            className="secondary-fancy-button"
                            variant="outline-primary"
                            style={{
                            minWidth: '200px',
                            minHeight: '60px',
                            fontSize: '18px',
                            }}
                            onClick={() => setShowKeyModal(true)}
                        >
                            <i className="bi bi-key-fill me-2"></i> I have a key
                        </Button>
                    </div>
                </Col>
                <Col md={5} style={{ maxWidth: '700px' }}>
                    <img
                        src={tool}
                        alt="UCM Tool"
                        style={{
                            width: '100%',
                            margin: '20px auto',
                            border: '1px solid #052c65',
                            borderRadius: '30px',
                            //padding: '20px',
                            boxShadow: '0px 13px 29px -20px rgb(5, 44, 101)',
                        }}
                    />
                </Col>
                <Col md={1}></Col>
            </Row>
    
            {/* Divider */}
            <div style={{ marginTop: '50px', marginBottom: '30px' }}>
                <hr style={{ maxWidth: '1000px', margin: '0 auto', borderColor: '#0d6efd' }} />
            </div>

            {/* About Section */}
            <Row className="justify-content-center" align="center">
                <Col md={1}></Col>
                <Col md={10} align="left" style={{ maxWidth: '700px' }}>
                    <h2 style={{ marginBottom: '30px', fontWeight: 'bold', color: '#0d6efd' }}>
                    A bit of Context
                    </h2>
                    <p style={{ marginBottom: '20px', fontSize: '20px', color: '#555' }}>
                    <i>UCM</i> is a domain-agnostic tool designed to explore required scenarios, actors and components to 
                    conceptualize and build use cases. Modeled use cases can be analyzed against each other to find commonalities.
                    The initial focus and provided knowledge bases refer to Autonomous Mobility (AM).
                    </p>
                </Col>
                <Col md={1}></Col>
            </Row>

            {/* Approach Section */}
            <Row className="justify-content-center" align="center">
                <Col md={1}></Col>
                <Col md={10} align="left" style={{ maxWidth: '700px' }}>
                    <h2 style={{ marginTop: '30px', marginBottom: '30px', fontWeight: 'bold', color: '#0d6efd' }}>
                    The Underlying Developed Approach
                    </h2>
                    <p style={{ marginBottom: '20px', fontSize: '20px', color: '#555' }}>
                    <i>UCM</i> provides a unique Four-Step-Approach to use case engineering, embedding LLMs and semantic models for improved usability, thus presenting a comprehensive solution builder.
                    
                    Thereby, the tool's knowledge base is built upon the two developed semantic models, 'General Use Case Ontology' (<a href="https://marc-ga.github.io/peamonto/guco.owl">GUCO</a>)
                    and 'Platform Economy Autonomous Mobility Ontology' (<a href="https://marc-ga.github.io/peamonto/peamonto.owl">PEAMONTO</a>) ontologies,
                    both accessible on Github.
                    </p>

                    {/* Visit Semantic Model Mapper */}
                    <div align='right'>
                    <Button
                        className="fancy-button"
                        variant="primary"
                        style={{
                        minWidth: '200px',
                        minHeight: '60px',
                        fontSize: '18px',
                        }}
                        onClick={() => navigate('/KB')}
                    >
                        <i className="bi bi-layers"></i> Semantic Model Mapper Module
                    </Button>
                    </div>

                </Col>
                <Col md={1}></Col>
            </Row>

            {/* Email Signup Section */}
            <Row className="justify-content-center align-items-center" style={{ marginTop: '50px' }}>
              <Col md={1}></Col>
              <Col md={10} align="left" style={{ maxWidth: '700px' }}>
                <h2 style={{ marginBottom: '30px', fontWeight: 'bold', color: '#0d6efd' }}>
                  You want to know when UCM tool is made publicly accessible? Sign up !
                </h2>
                <p style={{ marginBottom: '20px', fontSize: '20px', color: '#555' }}>
                  Be the first to know about when UCM is made publicly accessible, as well as get informed about research insights. Subscribe now!
                </p>
                <Form>
                  <Form.Group controlId="formEmail" className="d-flex justify-content-center">
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      style={{
                        maxWidth: '400px',
                        marginRight: '10px',
                      }}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      style={{
                        minWidth: '120px',
                        backgroundColor: '#0d6efd',
                        borderColor: '#0d6efd',
                      }}
                      disabled
                    >
                      Subscribe
                    </Button>
                  </Form.Group>
                </Form>
                <p style={{ marginTop: '10px', fontSize: '14px', color: '#6c757d' }}>
                  No spam, unsubscribe anytime.
                </p>
              </Col>
              <Col md={1}></Col>
            </Row>

            {/* Contact Us Modal */}
            {showContactModal && (
                <ContactUsModal show={showContactModal} setShow={setShowContactModal} />
            )}

            {/* Key Entry Modal */}
            {showKeyModal && (
                <KeyEntryModal
                    show={showKeyModal}
                    setShow={setShowKeyModal}
                    handleKeySubmit={handleKeySubmit}
                    enteredKey={key} // Rename the key prop
                    setKey={setKey}
                    error={error}
                />
            )}

        </Container>
    );
}

export { default as LP } from './LP';


