/**
 * Database Management - Read
 *
 * Description: This module contains functions to read data from the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-22
 * 
 */

import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

import { doc, getDoc } from 'firebase/firestore';

import { fetchDbConfigurations } from '../db_config_adapter';

//const db_name = 'alpha_use_cases';

/**
 * Reads the use case data
 * @returns 
 */
const fetchUseCaseData = async () => {
  try {

    // Fetch the database configuration
    const dbConfigs = await fetchDbConfigurations();
    const useCaseDbConfig = dbConfigs.find(config => config.meta.applicationArea === "db_use_case" && config.active);
    if (!useCaseDbConfig) {
      throw new Error('No active use case database configuration found!');
    }

    const querySnapshot = await getDocs(collection(db, useCaseDbConfig.dbName));  //db_name
    const useCases = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    console.log('Fetched use case data: ', useCases);
    return useCases;
  } catch (error) {
    console.error('Error fetching use case data: ', error);
  }
};

/**
 * Reads the OpenAI API key
 * @returns 
 */
const getOpenAIKey = async () => {
  
  const docRef = doc(collection(db, 'openai_api'), '6MkGYrIapE6vNTbqEEti');
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    //console.log('Document data:', docSnap.data());
    //console.log('OpenAI key:', docSnap.data().openai);
    return docSnap.data().openai;
  } else {
    throw new Error('No such document!');
  }
};

export { fetchUseCaseData, getOpenAIKey };