/**
 * Handles initial authentication and routing to the appropriate component based on user authentication status.
 *
 * Description: Used just to check if the user is signed in and direct to the Start component if true, otherwise direct to the SignIn component
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-13
 * Updated: 2024-05-01; last updated 2024-07-07, and 2024-12-08
 */

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Papierboot_Footer from './assets/img/Papierboot_Footer.JPG';
import Papierboot from './assets/img/Papierboot.png';

import './App.css';

import { SignIn } from './components/auth_mgmt/auth_handling';
import { onAuthStateChanged, getAuth } from "firebase/auth";

import Loading from './components/elems/elem_load_login';
import Start from './start.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { LP } from './views/LP';
import { Impressum } from './views/Legal/Impressum';
import { Privacy } from './views/Legal/Privacy';
import { KB } from './views/KB';

//import KeyEntry from './components/auth_mgmt/auth_key';

// Header with just the logo to return to the main page
function Header() {

  const { pathname } = useLocation();

  return (
    <header
      style={{
        //background: 'linear-gradient(to bottom, #f9f9f9, #ffffff)', // Matches KB top color
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '150px', // Adjust height as needed
        margin: 0, // Remove any default margin
        padding: 0, // Remove any default padding
        background: ( pathname === '/' ) ? '#f9f9f9' : 'white',
      }}
    >
      <Link to="/" style={{ display: 'inline-block' }}>
        <img
          src={Papierboot}
          className="img-fluid"
          alt="Responsive"
          style={{
            maxWidth: '120px',
            height: 'auto',
          }}
        />
      </Link>
    </header>
  );
}

// Footer component
function Footer() {
  return (
    <footer
      className="footer text-center mt-5"
      style={{ marginTop: '80px', width: '100%' }}
    >
      <div style={{ marginBottom: '15px', marginTop:'50px' }}>
        <hr />
        <img src={Papierboot_Footer} className="img-fluid" alt="Responsive" />
        <Row className="mt-4" style={{ marginTop: '30px', marginLeft: 0, marginRight: 0 }}>
            <Col xs={0} md={1}></Col>
            <Col xs={12} md={5} className="d-flex align-items-center justify-content-center mb-3">
                <img
                src={Papierboot}
                className="img-fluid"
                alt="Responsive"
                style={{ maxWidth: '100px', height: 'auto', marginRight: '10px' }}
                />
                <small>
                    Use Case Mapper for Solution Engineering
                </small>
            </Col>
            <Col xs={0} md={1}></Col>
        </Row>
        <Row
        className="mt-4 align-items-center"
        style={{ marginLeft: 0, marginRight: 0, marginBottom: '50px', overflowY: '0px' }}
        >
            <Col xs={0} md={1}></Col> {/* Left Spacing */}
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
                <small className="text-center">
                &copy; 2020-2024 @ MGA, partially part of the author's PhD research at TU Berlin.
                </small>
            </Col>
            <Col xs={12} md={3} className="d-flex align-items-center justify-content-end">
            <Link to="/Legal/Impressum" className="text-muted">Impressum</Link>
            <Link to="/Legal/Privacy" className="text-muted" style={{ marginLeft: '10px' }}>Privacy</Link>
            </Col>
            <Col xs={0} md={2}></Col> {/* Right Spacing */}
        </Row>
      </div>
    </footer>
  );
}

// Shared layout for all pages
function SharedLayout({ children }) {

  return (
    <div>
      <Header /> {/* Header for all pages */}
      {children} {/* Render the current route's content */}
      <Footer /> {/* Footer for all pages */}
    </div>
  );
}

/**
 * Checks if the user is signed in and directs to the Start component if true, otherwise directs to the SignIn component#
 */
function App() {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [keyVerified, setKeyVerified] = useState(false);

  // Scroll to the top of the page whenever the path changes
  const ScrollToTopOnNavigate = () => {
    const { pathname } = useLocation(); // React Router provides the current path

    useEffect(() => {

      setTimeout(() => {
        // Scroll to the top of the page whenever the path changes
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);

    }, [pathname]); // Only re-run the effect if the path changes

    return null; // This component doesn't render anything
  };

  // Check if user is signed in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Once authentication state is checked, set loading to false
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />; // Show loading indicator while checking authentication state
  }

  if (user) {
    return <Start />;
  }

  if (!keyVerified) {
    return (
      <Router>
        <ScrollToTopOnNavigate />
        <Routes>      

          {/* Main Landing Page */}
          <Route
            path="/"
            element={
              <SharedLayout>
                <LP onKeyVerified={() => setKeyVerified(true)} />
              </SharedLayout>
            }
          />
        
          <Route
            path="/KB"
            element={
              <SharedLayout>
                <KB />
              </SharedLayout>
            }
          />

          <Route
            path="/Legal/Impressum"
            element={
              <SharedLayout>
                <Impressum />
              </SharedLayout>
            }
          />

          <Route
            path="/Legal/Privacy"
            element={
              <SharedLayout>
                <Privacy />
              </SharedLayout>
            }
          />

        </Routes>
      </Router>
    );
  }

  return <SignIn />;

  //return user ? <Start /> : <SignIn />;

}

export default App;
