/**
 * Renders the droppable area of the model handling form
 * 
 * Description: This form contains the components of the use case
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-26
 * 
 */

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Collapse } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import { RenderDroppedItems } from './render_items';

// Define the droppable field
const DroppableFieldDetailed = ({ name, onDrop, children, acceptDrops = true, handleAddElement }) => {
    const [, drop] = useDrop({
        accept: 'COMPONENT',
        drop: (item) => acceptDrops && onDrop(item, name),
        canDrop: () => acceptDrops,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <div ref={drop} style={{ minHeight: '100px', border: '1px dashed gray', padding: '5px', margin: '10px' }}>
            <h4 style={{ fontSize: '12px', margin: '0 0 10px 0', fontWeight: 'bold' }}>{name}</h4>
            {acceptDrops && (
                <Button
                    variant="link"
                    onClick={() => handleAddElement(name)}
                    style={{ float: 'right', marginRight: '0px', padding: '0px' }}
                >
                    <i className="bi bi-plus-circle"></i>
                </Button>
            )}
            {children}
        </div>
    );
};

const DroppableFieldFoldable = ({ name, onDrop, children, acceptDrops = true, handleAddElement, handleDeleteElement, allExpanded }) => {
    const [, drop] = useDrop({
        accept: 'COMPONENT',
        drop: (item) => acceptDrops && onDrop(item, name),
        canDrop: () => acceptDrops,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const [open, setOpen] = useState(true);
    const hasChildren = React.Children.count(children) > 0;

    // Update open state based on allExpanded prop
    useEffect(() => {
        setOpen(allExpanded);
    }, [allExpanded]);

    // Add element without affecting the open state
    const handleAddElementWithNoCollapse = (e) => {
        e.stopPropagation();  // Prevents the click event from propagating to the header and toggling the open state
        handleAddElement(name);
    };

    return (
        <div>
            <Card style={{ marginTop: '15px' }}>
                <Card.Header style={{ backgroundColor: 'transparent' }} onClick={() => setOpen(!open)}>
                    <Button
                        variant="white"
                        onClick={() => setOpen(!open)}
                        aria-controls={name}
                        aria-expanded={open}
                        style={{ backgroundColor: 'transparent', color: 'black', padding: '0px' }}
                    >
                        {name}
                    </Button>
                    {open ? (
                        <i className="bi bi-caret-up-fill" style={{ float: 'right' }} onClick={() => setOpen(!open)}></i>
                    ) : (
                        <i className="bi bi-caret-down-fill" style={{ float: 'right' }} onClick={() => setOpen(!open)}></i>
                    )}
                    {acceptDrops && (
                        <Button
                            variant="link"
                            onClick={handleAddElementWithNoCollapse}  // Use the new function
                            style={{ float: 'right', marginRight: '10px', padding: '0px' }}
                        >
                            <i className="bi bi-plus-circle"></i>
                        </Button>
                    )}
                </Card.Header>
                <Collapse in={open}>
                    <div
                        ref={drop}
                        id={name}
                        style={{
                            padding: '10px',
                            minHeight: hasChildren ? 'auto' : '50px',
                        }}
                    >
                        {children}
                    </div>
                </Collapse>
            </Card>
        </div>
    );
};


// Define the droppable area: Detailed
const DroppableAreaDetailed = ({ onDrop, droppedItems, handleAddElement, handleDeleteElement, labels }) => {

    let fields = {};

    // Iterate over the keys in droppedItems
    Object.keys(droppedItems).forEach((key) => {
        if (key === 'Application') {
            fields[labels.Group_Applications] = 'Application';
        } else if (key === 'Platform') {
            fields[labels.Group_Platform] = 'Platform';
        } else if (key === 'Control') {
            fields[labels.Group_Control] = 'Control';
        } else if (key === 'ADS: Automated Driving System') {
            fields[labels.Group_Control] = 'ADS: Automated Driving System'; // to be checked
        } else if (key === 'Middleware') {
            fields[labels.Group_Middleware] = 'Middleware';
        } else if (key === 'Hardware') {
            fields[labels.Group_Hardware] = 'Hardware';
        }
    });

    Object.keys(droppedItems).forEach((key) => {
        if (key === 'Applications / Interfaces (proprietary, third-party, partially distributed)') {
            fields[labels.Group_Applications] = 'Applications / Interfaces (proprietary, third-party, partially distributed)';
        } else if (key === 'Platform and Cloud (proprietary, third-party, partially distributed)') {
            fields[labels.Group_Platform] = 'Platform and Cloud (proprietary, third-party, partially distributed)';
        } else if (key === 'ADS: Automated Driving System') {
            console.log('key: ', key);
            fields[labels.Group_Control] = 'ADS: Automated Driving System'; // to be checked
        } else if (key === 'AI-Middleware and Edge (Devices)') {
            fields[labels.Group_Middleware] = 'AI-Middleware and Edge (Devices)';
        } else if (key === 'Hardware (Vehicle, Infrastructure)') {
            fields[labels.Group_Hardware] = 'Hardware (Vehicle, Infrastructure)';
        }            
    });

    return (
        <div style={{ minHeight: '500px', padding: '0px' }}> {/* Removed border for consistency */}
            <Row>
                <Col>
                    {/* Operations */}
                    <DroppableFieldDetailed name={labels.Group_Operations} onDrop={onDrop} acceptDrops={false}>
                        {RenderDroppedItems(droppedItems[labels.Group_Operations])}
                        <Row>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Operations_Management} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Operations_Management], labels.Group_Operations_Management, handleDeleteElement)}
                                </DroppableFieldDetailed>
                            </Col>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Operations_Analytics} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Operations_Analytics], labels.Group_Operations_Analytics, handleDeleteElement)}
                                </DroppableFieldDetailed>
                            </Col>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Operations_Platform} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Operations_Platform], labels.Group_Operations_Platform, handleDeleteElement)}    
                                </DroppableFieldDetailed>
                            </Col>
                        </Row>                                  
                    </DroppableFieldDetailed>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    {/* Producer / Supplier / Sender */}
                    <DroppableFieldDetailed name={labels.Group_Producer} onDrop={onDrop} acceptDrops={false}>
                        {RenderDroppedItems(droppedItems[labels.Group_Producer])}
                        <DroppableFieldDetailed name={labels.Group_Producer_Analytics} onDrop={onDrop} handleAddElement={handleAddElement}>
                            {RenderDroppedItems(droppedItems[labels.Group_Producer_Analytics], labels.Group_Producer_Analytics, handleDeleteElement)}
                        </DroppableFieldDetailed>
                        <DroppableFieldDetailed name={labels.Group_Producer_Management} onDrop={onDrop} handleAddElement={handleAddElement}>
                            {RenderDroppedItems(droppedItems[labels.Group_Producer_Management], labels.Group_Producer_Management, handleDeleteElement)}
                        </DroppableFieldDetailed>
                        <DroppableFieldDetailed name={labels.Group_Producer_Platform} onDrop={onDrop} handleAddElement={handleAddElement}>
                            {RenderDroppedItems(droppedItems[labels.Group_Producer_Platform], labels.Group_Producer_Platform, handleDeleteElement)}
                        </DroppableFieldDetailed>
                    </DroppableFieldDetailed>
                </Col>
                {/* Technical Layer */}
                <Col md={6}>
                    <DroppableFieldDetailed name={labels.Group_Technical} onDrop={onDrop} acceptDrops={false}>                    
                        <Row>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Applications} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Applications]], fields[labels.Group_Applications], handleDeleteElement)}
                                </DroppableFieldDetailed>                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Platform} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Platform]], fields[labels.Group_Platform], handleDeleteElement)}
                                </DroppableFieldDetailed>                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Control} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Control]], fields[labels.Group_Control], handleDeleteElement)}
                                </DroppableFieldDetailed>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Middleware} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Middleware]], fields[labels.Group_Middleware], handleDeleteElement)}
                                </DroppableFieldDetailed>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Hardware} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Hardware]], fields[labels.Group_Hardware], handleDeleteElement)}
                                </DroppableFieldDetailed>
                            </Col>
                        </Row>
                    </DroppableFieldDetailed>
                </Col>
                <Col md={3}>
                    {/* Consumer / Customer / Recipient */}
                    <DroppableFieldDetailed name={labels.Group_Consumer} onDrop={onDrop} acceptDrops={false}>
                        {RenderDroppedItems(droppedItems[labels.Group_Consumer])}
                        <DroppableFieldDetailed name={labels.Group_Consumer_Analytics} onDrop={onDrop} handleAddElement={handleAddElement}>
                            {RenderDroppedItems(droppedItems[labels.Group_Consumer_Analytics], labels.Group_Consumer_Analytics, handleDeleteElement)}
                        </DroppableFieldDetailed>
                        <DroppableFieldDetailed name={labels.Group_Consumer_Delivery} onDrop={onDrop} handleAddElement={handleAddElement}>
                            {RenderDroppedItems(droppedItems[labels.Group_Consumer_Delivery], labels.Group_Consumer_Delivery, handleDeleteElement)}
                        </DroppableFieldDetailed>
                        <DroppableFieldDetailed name={labels.Group_Consumer_Platform} onDrop={onDrop} handleAddElement={handleAddElement}>
                            {RenderDroppedItems(droppedItems[labels.Group_Consumer_Platform], labels.Group_Consumer_Platform, handleDeleteElement)}
                        </DroppableFieldDetailed>
                    </DroppableFieldDetailed>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {/* Regulator */}
                    <DroppableFieldDetailed name={labels.Group_Regulator} onDrop={onDrop} acceptDrops={false}>
                        <Row>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Directives} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Directives], labels.Group_Directives, handleDeleteElement)}
                                </DroppableFieldDetailed>
                            </Col>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Reference_Cases} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Reference_Cases], labels.Group_Reference_Cases, handleDeleteElement)}
                                </DroppableFieldDetailed>
                            </Col>
                            <Col>
                                <DroppableFieldDetailed name={labels.Group_Standards} onDrop={onDrop} handleAddElement={handleAddElement}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Standards], labels.Group_Standards, handleDeleteElement)}
                                </DroppableFieldDetailed>
                            </Col>
                        </Row>
                    </DroppableFieldDetailed>
                </Col>
            </Row>
        </div>
    );    
};

// Define the droppable area: Foldable
const DroppableAreaFoldable = ({ onDrop, droppedItems, handleAddElement, handleDeleteElement, allExpanded, labels }) => {

    //console.log('droppedItems in render: ', droppedItems);
    //console.log('droppedItems in render1: ', Object.keys(droppedItems));

    // Map the techn. dropped items to the corresponding labels
    //const droppedItemsKeys = Object.keys(droppedItems);
    // Initialize fields as an empty object
    let fields = {};

    // Iterate over the keys in droppedItems
    Object.keys(droppedItems).forEach((key) => {
        if (key === 'Application') {
            fields[labels.Group_Applications] = 'Application';
        } else if (key === 'Platform') {
            fields[labels.Group_Platform] = 'Platform';
        } else if (key === 'Control') {
            fields[labels.Group_Control] = 'Control';
        } else if (key === 'Middleware') {
            fields[labels.Group_Middleware] = 'Middleware';
        } else if (key === 'Hardware') {
            fields[labels.Group_Hardware] = 'Hardware';
        }
    });

    Object.keys(droppedItems).forEach((key) => {
        if (key === 'Applications / Interfaces (proprietary, third-party, partially distributed)') {
            fields[labels.Group_Applications] = 'Applications / Interfaces (proprietary, third-party, partially distributed)';
        } else if (key === 'Platform and Cloud (proprietary, third-party, partially distributed)') {
            fields[labels.Group_Platform] = 'Platform and Cloud (proprietary, third-party, partially distributed)';
        } else if (key === 'ADS: Automated Driving System') {
            fields[labels.Group_Control] = 'ADS: Automated Driving System'; // to be checked
        } else if (key === 'Control Layer (Central Functionality)') {
            fields[labels.Group_Control] = 'Control Layer (Central Functionality)';            
        } else if (key === 'AI-Middleware and Edge (Devices)') {
            fields[labels.Group_Middleware] = 'AI-Middleware and Edge (Devices)';
        } else if (key === 'Hardware (Vehicle, Infrastructure)') {
            fields[labels.Group_Hardware] = 'Hardware (Vehicle, Infrastructure)';
        } else if (key === 'Hardware (Infrastructure, Computing)') {
            fields[labels.Group_Hardware] = 'Hardware (Infrastructure, Computing)';
        }
    });

    return (
        <div style={{ minHeight: '500px', padding: '0px' }}> {/* border: '1px dashed gray', */}
            <Row>
                <Col>
                    <DroppableFieldFoldable name={labels.Group_Operations} onDrop={onDrop} acceptDrops={false} allExpanded={allExpanded}>
                        {RenderDroppedItems(droppedItems[labels.Group_Operations])}
                        <Row style={{ marginBottom: '15px' }}>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Operations_Management} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Operations_Management], labels.Group_Operations_Management, handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Operations_Analytics} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Operations_Analytics], labels.Group_Operations_Analytics, handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Operations_Platform} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Operations_Platform], labels.Group_Operations_Platform, handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                        </Row>
                    </DroppableFieldFoldable>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <DroppableFieldFoldable name={labels.Group_Producer} onDrop={onDrop} acceptDrops={false} allExpanded={allExpanded}>
                        {RenderDroppedItems(droppedItems[labels.Group_Producer])}
                        <DroppableFieldFoldable name={labels.Group_Producer_Analytics} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                            {RenderDroppedItems(droppedItems[labels.Group_Producer_Analytics], labels.Group_Producer_Analytics, handleDeleteElement)}
                        </DroppableFieldFoldable>
                        <DroppableFieldFoldable name={labels.Group_Producer_Management} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                            {RenderDroppedItems(droppedItems[labels.Group_Producer_Management], labels.Group_Producer_Management, handleDeleteElement)}
                        </DroppableFieldFoldable>
                        <DroppableFieldFoldable name={labels.Group_Producer_Platform} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                            {RenderDroppedItems(droppedItems[labels.Group_Producer_Platform], labels.Group_Producer_Platform, handleDeleteElement)}
                        </DroppableFieldFoldable>
                    </DroppableFieldFoldable>
                </Col>
                <Col md={6}>
                    <DroppableFieldFoldable name={labels.Group_Technical} onDrop={onDrop} acceptDrops={false} allExpanded={allExpanded}>
                        <Row>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Applications} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Applications]], fields[labels.Group_Applications], handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Platform} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Platform]], fields[labels.Group_Platform], handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Control} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Control]], fields[labels.Group_Control], handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Middleware} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Middleware]], fields[labels.Group_Middleware], handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Hardware} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[fields[labels.Group_Hardware]], fields[labels.Group_Hardware], handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                        </Row>
                    </DroppableFieldFoldable>
                </Col>
                <Col md={3}>
                    <DroppableFieldFoldable name={labels.Group_Consumer} onDrop={onDrop} acceptDrops={false} allExpanded={allExpanded}>
                        {RenderDroppedItems(droppedItems[labels.Group_Consumer])}
                        <DroppableFieldFoldable name={labels.Group_Consumer_Analytics} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                            {RenderDroppedItems(droppedItems[labels.Group_Consumer_Analytics], labels.Group_Consumer_Analytics, handleDeleteElement)}
                        </DroppableFieldFoldable>
                        <DroppableFieldFoldable name={labels.Group_Consumer_Delivery} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                            {RenderDroppedItems(droppedItems[labels.Group_Consumer_Delivery], labels.Group_Consumer_Delivery, handleDeleteElement)}
                        </DroppableFieldFoldable>
                        <DroppableFieldFoldable name={labels.Group_Consumer_Platform} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                            {RenderDroppedItems(droppedItems[labels.Group_Consumer_Platform], labels.Group_Consumer_Platform, handleDeleteElement)}
                        </DroppableFieldFoldable>
                    </DroppableFieldFoldable>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <DroppableFieldFoldable name={labels.Group_Regulator} onDrop={onDrop} acceptDrops={false} allExpanded={allExpanded}>
                        <Row>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Directives} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Directives], labels.Group_Directives, handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Reference_Cases} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Reference_Cases], labels.Group_Reference_Cases, handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                            <Col>
                                <DroppableFieldFoldable name={labels.Group_Standards} onDrop={onDrop} handleAddElement={handleAddElement} allExpanded={allExpanded}>
                                    {RenderDroppedItems(droppedItems[labels.Group_Standards], labels.Group_Standards, handleDeleteElement)}
                                </DroppableFieldFoldable>
                            </Col>
                        </Row>
                    </DroppableFieldFoldable>
                </Col>
            </Row>
        </div>
    );
};

export { DroppableAreaDetailed, DroppableAreaFoldable };