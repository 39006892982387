/**
 * Database Management - Read ontology data
 *
 * Description: This module contains functions to read data from the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-11-21
 * 
 */

import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

//import { doc, getDoc } from 'firebase/firestore';

const db_name = 'ontologies';

//import { fetchDbConfigurations } from '../db_config_adapter';

/**
 * Reads the use case data
 * @returns 
 */
const fetchOntologyData = async () => {
    try {

      // Fetch the database configuration
      //const dbConfigs = await fetchDbConfigurations();

      //const ontologyDbConfig = dbConfigs.find(config => config.meta.applicationArea === "db_ontology" && config.active);

      /*
      if (!ontologyDbConfig) {
        throw new Error('No active ontology database configuration found!');
      }
      */

      // Get all documents from the 'ontologies' collection
      const querySnapshot = await getDocs(collection(db, db_name));  //db_name  // ontologyDbConfig.dbName
      const ontologies = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched ontology data: ', ontologies);
      return ontologies;
    } catch (error) {
      console.error('Error fetching ontology data: ', error);
    }
  };

export { fetchOntologyData };