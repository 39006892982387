/**
 * Privacy, German Datenschutzerklärung
 *
 * Description: Privacy page
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-12-07
 * 
 */

import React from 'react';
import { Container, Row } from 'react-bootstrap';


export default function Privacy() {

    return (
        <Container fluid>
        <Row align='center'>      
            <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Datenschutzerklärung</b></h1>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
              <i>UCM</i> is a domain-agnostic tool designed to explore required scenarios, actors and components to 
              conceptualize and build use cases. It is part of my doctoral research.
            </p>
        </Row>
        <Row align='center' style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
            
            <h4>Allgemeine Hinweise</h4>
            <p>Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. Die Verarbeitung Ihrer Daten erfolgt gemäß der Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG).</p>

            <h4>Verantwortlicher</h4>
            <p>Marc Augusto</p>
            <p>Ernst-Reuter-Platz 2, 10587 Berlin</p>
            <p>marc.augusto@tu-berlin.de</p>

            <h4>Erhebung und Speicherung personenbezogener Daten</h4>

            <p>Nutzung der Webseite:</p> 
            <p>Beim Besuch unserer Webseite speichern wir automatisch Daten wie die IP-Adresse, den Browsertyp und die Uhrzeit des Zugriffs.</p>
            
            <p>Registrierung:</p> 
            <p>Bei der Registrierung erheben wir Daten wie Name, E-Mail-Adresse und Passwort.</p>

            <h4>Zweck der Datenverarbeitung</h4>

            <p>Bereitstellung der Funktionen der Webseite.</p>
            <p>Verwaltung von Nutzerkonten.</p>
            <p>Kommunikation mit den Nutzern.</p>

            <h4>Weitergabe von Daten</h4>
            <p>Eine Weitergabe an Dritte erfolgt nur, wenn dies gesetzlich vorgeschrieben ist oder Sie ausdrücklich eingewilligt haben.</p>

            <h4>Ihre Rechte</h4>
            <p>Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung und Datenübertragbarkeit Ihrer gespeicherten personenbezogenen Daten.</p>

            <h4>Kontakt bei Datenschutzfragen</h4>
            <p>Für Fragen zum Datenschutz kontaktieren Sie uns bitte unter: marc.augusto@tu-berlin.de.</p>
        </Row>
    </Container>
    )
}

export { default as Privacy } from './Privacy';