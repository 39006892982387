/**
 * Knowledge Representation Component Step Enrichment Structure
 * 
 * Description: Sets the structure for the knowledge representation component step enrichment
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-20; adopted 2024-09-21
 * 
 */


// GUCO
export const comp_semantic_enrichment_guco = {
    // Information
    'Use Case Information': 'Description',
    'Actions': 'Actions',
    'Conditions': 'Conditions',
    'Actors': 'Actors',
}

export const comp_semantic_enrichment_guco_enhanced = {
    // Information
    'Name': {
        Primary: 'Name',
        Secondary: 'Title',
        Meta: 'N/A',
    },
    'Acronym': {
        Primary: 'Acronym',
        Secondary: 'Short Name',
        Meta: 'N/A',
    },
    'ID': {
        Primary: 'ID',
        Secondary: 'Identifier',
        Meta: 'N/A',
    },
    'Application Area': {
        Primary: 'Application Area',
        Secondary: 'Domain',
        Meta: 'N/A',
    },
    'Maturity Degree': {
        Primary: 'Maturity Degree',
        Secondary: 'Level',
        Meta: 'N/A',
    },
    'Status': {
        Primary: 'Status',
        Secondary: 'State',
        Meta: 'N/A',
    },
    'Keyword': {
        Primary: 'Keyword',
        Secondary: 'Tag',
        Meta: 'N/A',
    },
    'Description': {
        Primary: 'Description',
        Secondary: 'Summary',
        Meta: 'N/A',
    },
    // Scenrario / Actions
    'Action': {
        Primary: 'Action',
        Secondary: 'Task',
        Meta: 'N/A',
    },
    'Pre-Condition': {
        Primary: 'Pre-Condition',
        Secondary: 'Pre-requisite',
        Meta: 'N/A',
    },
    'Post-Condition': {
        Primary: 'Post-Condition',
        Secondary: 'Result',
        Meta: 'N/A',
    },
    'Constraint': {
        Primary: 'Constraint',
        Secondary: 'Limitation',
        Meta: 'N/A',
    },
    'Assumption': {
        Primary: 'Assumption',
        Secondary: 'Hypothesis',
        Meta: 'N/A',
    },
    // Actors / Stakeholders
    'Consumer': {
        Primary: 'Consumer',
        Secondary: 'Recipient',
        Meta: 'N/A',
    },
    'Producer': {
        Primary: 'Producer',
        Secondary: 'Sender',
        Meta: 'N/A',
    },
    'Layer': {
        Primary: 'Layer',
        Secondary: 'Level',
        Meta: 'N/A',
    },
    'Infrastructure Provider': {  
        Primary: 'Infrastructure Provider',
        Secondary: 'Infrastructure Provider',
        Meta: 'N/A',
    },
    'Technical Enabler': {
        Primary: 'Technical Enabler',
        Secondary: 'Technical Enabler',
        Meta: 'N/A',
    },
    'Platform Provider': {
        Primary: 'Platform Provider',
        Secondary: 'Provider',
        Meta: 'N/A',
    },
    'Regulator': {
        Primary: 'Regulator',
        Secondary: 'Authority',
        Meta: 'N/A',
    },
    'Operator': {
        Primary: 'Operator',
        Secondary: 'Manager',
        Meta: 'N/A',
    },
    // Foundation
    // Location
    'Continent': {
        Primary: 'Continent',
        Secondary: 'Continent',
        Meta: 'N/A',
    },
    'Country': {
        Primary: 'Country',
        Secondary: 'Country',
        Meta: 'N/A',
    },
    'City': {
        Primary: 'City',
        Secondary: 'City',
        Meta: 'N/A',
    },
    'Country Code': {
        Primary: 'Country Code',
        Secondary: 'Country Code',
        Meta: 'N/A',
    },
    // Meta data
    'Date Created': {
        Primary: 'Date Created',
        Secondary: 'Creation Date',
        Meta: 'N/A',
    },
    'Date Modified': {
        Primary: 'Date Modified',
        Secondary: 'Modification Date',
        Meta: 'N/A',
    },
    'Author': {
        Primary: 'Author',
        Secondary: 'Creator',
        Meta: 'N/A',
    },
    'Editor': {
        Primary: 'Editor',
        Secondary: 'Modifier',
        Meta: 'N/A',
    },
    'Version': {
        Primary: 'Version',
        Secondary: 'Version',
        Meta: 'N/A',
    },
    'Revision': {
        Primary: 'Revision',
        Secondary: 'Revision',
        Meta: 'N/A',
    },
    'License': {
        Primary: 'License',
        Secondary: 'License',
        Meta: 'N/A',
    },
    'Reference': {
        Primary: 'Reference',
        Secondary: 'Reference',
        Meta: 'N/A',
    },
};

// Baseline mapping
export const comp_semantic_enrichment_baseline = {
    // Operations
    'Operations Management': '',
    'Operations Analytics': '',
    'Operations Platform': '',
    // Producer
    'Producer Analytics': '',
    'Producer Management': '',
    'Producer Platform': '',
    // Consumer
    'Consumer Analytics': '',
    'Consumer Delivery': '',
    'Consumer Platform': '',
    // Technical Layer
    'Applications': '',
    'Platform': '',
    'Control': '',
    'AI-Middleware': '',
    'Hardware': ''
}

export const comp_semantic_enrichment_baseline_enhanced = {
    // Operations
    'Operations Management': {
        'Primary': 'Operations Management',
        'Secondary': '',
        'Meta': '',
    },
    'Operations Analytics': {
        'Primary': 'Operations Analytics',
        'Secondary': '',
        'Meta': '',
    },
    'Operations Platform': {
        'Primary': 'Operations Platform',
        'Secondary': '',
        'Meta': '',
    },
    // Producer
    'Producer Analytics': {
        'Primary': 'Producer Analytics',
        'Secondary': '',
        'Meta': '',
    },
    'Producer Management': {
        'Primary': 'Producer Management',
        'Secondary': '',
        'Meta': '',
    },
    'Producer Platform': {
        'Primary': 'Producer Platform',
        'Secondary': '',
        'Meta': '',
    },
    // Consumer
    'Consumer Analytics': {
        'Primary': 'Consumer Analytics',
        'Secondary': '',
        'Meta': '',
    },
    'Consumer Delivery': {
        'Primary': 'Consumer Delivery',
        'Secondary': '',
        'Meta': '',
    },
    'Consumer Platform': {
        'Primary': 'Consumer Platform',
        'Secondary': '',
        'Meta': '',
    },
    // Technical Layer
    'Applications': {
        'Primary': 'Applications',
        'Secondary': '',
        'Meta': '',
    },
    'Platform': {
        'Primary': 'Platform',
        'Secondary': '',
        'Meta': '',
    },
    'Control': {
        'Primary': 'Control System',
        'Secondary': '',
        'Meta': '',
    },
    'AI-Middleware': {
        'Primary': 'Middleware',
        'Secondary': '',
        'Meta': '',
    },
    'Hardware': {
        'Primary': 'Hardware',
        'Secondary': '',
        'Meta': '',
    },
    // Regulator
    'Directives': {
        'Primary': 'Directive',
        'Secondary': '',
        'Meta': '',
    },
    'Reference Cases': {
        'Primary': 'Reference Case',
        'Secondary': '',
        'Meta': '',
    },
    'Standards': {
        'Primary': 'Standards',
        'Secondary': '',
        'Meta': '',
    },
};

// Mapping for PEAMONTO
export const comp_semantic_enrichment_peamonto = {
    // Operations
    'Operations Management': 'Operations',
    'Operations Analytics': 'Analytics (Global)',
    'Operations Platform': 'Concepts',
    // Producer
    'Producer Analytics': 'Analytics (Sender)',
    'Producer Management': 'Management',
    'Producer Platform': 'Shop (App)',
    // Consumer
    'Consumer Analytics': 'Manage',
    'Consumer Delivery': 'Delivery',
    'Consumer Platform': 'Shop Recipient (App)',
    // Technical Layer
    'Applications': 'Application',
    'Platform': 'Platform',
    'ADS': 'ADS',
    'AI-Middleware': 'Middleware',
    'Hardware': 'Hardware (Assets)'
}

// Enhanced mapping (Primary, Secondary, Meta) concepts for PEAMONTO
export const comp_semantic_enrichment_peamonto_enhanced = {
    // Operations
    'Operations Management': {
        'Primary': 'Operations Management',
        'Secondary': 'Concepts',
        'Meta': 'Management',
    },
    'Operations Analytics': {
        'Primary': 'Operations Analytics',
        'Secondary': 'Data',
        'Meta': 'Analytics',
    },
    'Operations Platform': {
        'Primary': 'Operations Platform',
        'Secondary': 'Platform Economy (PE)',
        'Meta': 'Data',
    },
    // Producer
    'Producer Analytics': {
        'Primary': 'Producer Analytics',
        'Secondary': 'Attributes',
        'Meta': 'Data',
    },
    'Producer Management': {
        'Primary': 'Producer Management',
        'Secondary': 'Operations Management',
        'Meta': 'Concepts',
    },
    'Producer Platform': {
        'Primary': 'Producer Platform',
        'Secondary': 'Platform',
        'Meta': 'Data',
    },
    // Consumer
    'Consumer Analytics': {
        'Primary': 'Consumer Analytics',
        'Secondary': 'Attributes',
        'Meta': 'Data',
    },
    'Consumer Delivery': {
        'Primary': 'Consumer Delivery',
        'Secondary': 'Concepts',
        'Meta': 'N/A',
    },
    'Consumer Platform': {
        'Primary': 'Consumer Platform',
        'Secondary': 'Platform',
        'Meta': 'Data',
    },
    // Technical Layer
    'Applications': {
        'Primary': 'Application',
        'Secondary': 'Functions',
        'Meta': 'Application',
    },
    'Platform': {
        'Primary': 'Platform Economy (PE)',
        'Secondary': 'Data',
        'Meta': 'Functions',
    },
    // ADS
    'Control': {
        'Primary': 'ADS',
        'Secondary': 'Functions',
        'Meta': 'Concepts',
    },
    'AI-Middleware': {
        'Primary': 'Middleware',
        'Secondary': 'Data',
        'Meta': 'Functions',
    },
    'Hardware': {
        'Primary': 'Hardware (Assets)',
        'Secondary': 'N/A',
        'Meta': 'N/A',
    },
    // Regulator
    'Directives': {
        'Primary': 'Directives',
        'Secondary': 'N/A',
        'Meta': 'N/A',
    },
    'Reference Cases': {
        'Primary': 'Reference Cases',
        'Secondary': 'N/A',
        'Meta': 'N/A',
    },
    'Standards': {
        'Primary': 'Standards',
        'Secondary': 'N/A',
        'Meta': 'N/A',
    },
}   

export const comp_semantic_enrichment_goki_enhanced = {
    // Operations
    'Operations Management': {
        'Primary': 'Workplace Operations Management',
        'Secondary': 'Smart Furniture Deployment',
        'Meta': 'Agent-Based Process Automation',
    },
    'Operations Analytics': {
        'Primary': 'Workplace Analytics',
        'Secondary': 'Data-Driven Insights',
        'Meta': 'LLM-Driven Predictive Analytics',
    },
    'Operations Platform': {
        'Primary': 'Integrated Operations Platform',
        'Secondary': 'Workspace Collaboration Tools',
        'Meta': 'Cloud-Based Frameworks',
    },
    // Producer
    'Producer Analytics': {
        'Primary': 'Furniture Production Analytics',
        'Secondary': 'Supply Chain Monitoring',
        'Meta': 'AI-Optimized Manufacturing Processes',
    },
    'Producer Management': {
        'Primary': 'Furniture Lifecycle Management',
        'Secondary': 'Customization Frameworks',
        'Meta': 'Smart Component Integration',
    },
    'Producer Platform': {
        'Primary': 'Production Platform',
        'Secondary': 'Smart Furniture Management Tools',
        'Meta': 'Digital Twins for Furniture',
    },
    // Consumer
    'Consumer Analytics': {
        'Primary': 'Workplace Behavior Analytics',
        'Secondary': 'User Feedback Collection',
        'Meta': 'Adaptive User Modeling',
    },
    'Consumer Delivery': {
        'Primary': 'Smart Furniture Delivery',
        'Secondary': 'Dynamic Deployment Scheduling',
        'Meta': 'AI-Driven Logistics Optimization',
    },
    'Consumer Platform': {
        'Primary': 'User-Centric Workplace Platform',
        'Secondary': 'Intelligent Agent Assistants',
        'Meta': 'Customized Experience Frameworks',
    },
    // Technical Layer
    'Applications': {
        'Primary': 'Future Workplace Applications',
        'Secondary': 'AI-Driven Task Management',
        'Meta': 'Workflow Optimization Tools',
    },
    'Platform': {
        'Primary': 'LLM-Powered Workplace Platform',
        'Secondary': 'Integrated AI Frameworks',
        'Meta': 'Agent-Based Ecosystem',
    },
    'Control': {
        'Primary': 'Smart Environment Control',
        'Secondary': 'Energy Efficiency Monitoring',
        'Meta': 'Agent-Driven Resource Allocation',
    },
    'AI-Middleware': {
        'Primary': 'AI Middleware',
        'Secondary': 'Contextual Processing Units',
        'Meta': 'LLM Integration',
    },
    'Hardware': {
        'Primary': 'IoT-Enabled Smart Furniture',
        'Secondary': 'Sensors and Actuators',
        'Meta': 'Interoperable Hardware Systems',
    },
    // Regulator
    'Directives': {
        'Primary': 'Workplace Policy Directives',
        'Secondary': 'Future Workforce Guidelines',
        'Meta': 'Standards for Agent-Driven Workflows',
    },
    'Reference Cases': {
        'Primary': 'Future Workplace Case Studies',
        'Secondary': 'Successful Implementations',
        'Meta': 'Smart Furniture Deployment',
    },
    'Standards': {
        'Primary': 'Workplace Standards',
        'Secondary': 'Interoperability Guidelines',
        'Meta': 'AI Compliance Frameworks',
    },
};

export const comp_semantic_enrichment_energy_enhanced = {
    // Operations
    'Operations Management': {
        'Primary': 'Energy Operations Management',
        'Secondary': 'Smart Grid Deployment',
        'Meta': 'Agent-Based Energy Optimization',
    },
    'Operations Analytics': {
        'Primary': 'Energy Analytics',
        'Secondary': 'Real-Time Monitoring',
        'Meta': 'LLM-Driven Predictive Insights',
    },
    'Operations Platform': {
        'Primary': 'Integrated Energy Platform',
        'Secondary': 'Grid Collaboration Tools',
        'Meta': 'Cloud-Based Energy Solutions',
    },
    // Producer
    'Producer Analytics': {
        'Primary': 'Energy Production Analytics',
        'Secondary': 'Supply Chain Monitoring',
        'Meta': 'AI-Optimized Generation Processes',
    },
    'Producer Management': {
        'Primary': 'Energy Production Lifecycle Management',
        'Secondary': 'Renewable Integration Frameworks',
        'Meta': 'Smart Grid Synchronization',
    },
    'Producer Platform': {
        'Primary': 'Energy Production Platform',
        'Secondary': 'Renewable Energy Management Tools',
        'Meta': 'Digital Twins for Energy Production',
    },
    // Consumer
    'Consumer Analytics': {
        'Primary': 'Energy Consumption Analytics',
        'Secondary': 'User Feedback Collection',
        'Meta': 'Adaptive Demand Modeling',
    },
    'Consumer Delivery': {
        'Primary': 'Smart Energy Delivery',
        'Secondary': 'Dynamic Load Balancing',
        'Meta': 'AI-Driven Energy Distribution',
    },
    'Consumer Platform': {
        'Primary': 'User-Centric Energy Platform',
        'Secondary': 'Intelligent Energy Assistants',
        'Meta': 'Customized Energy Solutions',
    },
    // Technical Layer
    'Applications': {
        'Primary': 'Energy Applications',
        'Secondary': 'AI-Driven Energy Management',
        'Meta': 'Workflow Optimization Tools',
    },
    'Platform': {
        'Primary': 'Energy Management Platform',
        'Secondary': 'Integrated AI Frameworks',
        'Meta': 'Agent-Based Energy Ecosystem',
    },
    'Control': {
        'Primary': 'Smart Energy Control',
        'Secondary': 'Grid Stability Monitoring',
        'Meta': 'Agent-Driven Resource Allocation',
    },
    'AI-Middleware': {
        'Primary': 'AI Middleware for Energy',
        'Secondary': 'Contextual Processing Units',
        'Meta': 'LLM Integration for Energy',
    },
    'Hardware': {
        'Primary': 'IoT-Enabled Smart Energy Devices',
        'Secondary': 'Sensors and Actuators for Energy',
        'Meta': 'Interoperable Energy Systems',
    },
    // Regulator
    'Directives': {
        'Primary': 'Energy Policy Directives',
        'Secondary': 'Sustainable Energy Guidelines',
        'Meta': 'Standards for Agent-Driven Energy Systems',
    },
    'Reference Cases': {
        'Primary': 'Energy Sector Case Studies',
        'Secondary': 'Successful Implementations',
        'Meta': 'Smart Grid Deployment',
    },
    'Standards': {
        'Primary': 'Energy Standards',
        'Secondary': 'Interoperability Guidelines',
        'Meta': 'AI Compliance Frameworks for Energy',
    },
};

export const comp_semantic_enrichment_health_enhanced = {
    // Operations
    'Operations Management': {
        'Primary': 'Healthcare Operations Management',
        'Secondary': 'Patient Workflow Optimization',
        'Meta': 'Resource Allocation Strategies',
    },
    'Operations Analytics': {
        'Primary': 'Health Data Analytics',
        'Secondary': 'Real-Time Monitoring',
        'Meta': 'Predictive Patient Outcomes',
    },
    'Operations Platform': {
        'Primary': 'Integrated Healthcare Platform',
        'Secondary': 'Collaboration Tools for Providers',
        'Meta': 'Cloud-Based Health Solutions',
    },
    // Producer
    'Producer Analytics': {
        'Primary': 'Healthcare Production Analytics',
        'Secondary': 'Supply Chain Monitoring',
        'Meta': 'AI-Optimized Manufacturing of Equipment',
    },
    'Producer Management': {
        'Primary': 'Medical Equipment Lifecycle Management',
        'Secondary': 'Customization Frameworks',
        'Meta': 'Integration with Smart Systems',
    },
    'Producer Platform': {
        'Primary': 'Healthcare Production Platform',
        'Secondary': 'Medical Equipment Management Tools',
        'Meta': 'Digital Twins for Medical Devices',
    },
    // Consumer
    'Consumer Analytics': {
        'Primary': 'Patient Behavior Analytics',
        'Secondary': 'Feedback Collection',
        'Meta': 'Adaptive Patient Modeling',
    },
    'Consumer Delivery': {
        'Primary': 'Smart Healthcare Delivery',
        'Secondary': 'Dynamic Appointment Scheduling',
        'Meta': 'AI-Driven Logistics Optimization',
    },
    'Consumer Platform': {
        'Primary': 'Patient-Centric Health Platform',
        'Secondary': 'Intelligent Health Assistants',
        'Meta': 'Customized Health Solutions',
    },
    // Technical Layer
    'Applications': {
        'Primary': 'Healthcare Applications',
        'Secondary': 'AI-Driven Health Management',
        'Meta': 'Workflow Optimization Tools',
    },
    'Platform': {
        'Primary': 'Health Management Platform',
        'Secondary': 'Integrated AI Frameworks',
        'Meta': 'Agent-Based Healthcare Ecosystem',
    },
    'Control': {
        'Primary': 'Smart Environment Control for Hospitals',
        'Secondary': 'Energy Efficiency in Healthcare Facilities',
        'Meta': 'Resource Allocation for Smart Health Systems',
    },
    'AI-Middleware': {
        'Primary': 'AI Middleware for Healthcare',
        'Secondary': 'Contextual Health Processing Units',
        'Meta': 'LLM Integration for Healthcare',
    },
    'Hardware': {
        'Primary': 'IoT-Enabled Medical Devices',
        'Secondary': 'Sensors and Actuators for Patient Monitoring',
        'Meta': 'Interoperable Health Equipment Systems',
    },
    // Regulator
    'Directives': {
        'Primary': 'Healthcare Policy Directives',
        'Secondary': 'Standards for Patient Care',
        'Meta': 'Guidelines for AI-Driven Healthcare Systems',
    },
    'Reference Cases': {
        'Primary': 'Healthcare Case Studies',
        'Secondary': 'Successful Implementations',
        'Meta': 'Smart Hospital Deployments',
    },
    'Standards': {
        'Primary': 'Healthcare Standards',
        'Secondary': 'Interoperability Guidelines',
        'Meta': 'AI Compliance Frameworks for Healthcare',
    },
};

export const comp_semantic_enrichment_security_enhanced = {
    // Operations
    'Operations Management': {
        'Primary': 'Security Operations Management',
        'Secondary': 'Threat Response Coordination',
        'Meta': 'Incident Handling Protocols',
    },
    'Operations Analytics': {
        'Primary': 'Security Data Analytics',
        'Secondary': 'Anomaly Detection',
        'Meta': 'Predictive Threat Analysis',
    },
    'Operations Platform': {
        'Primary': 'Integrated Security Platform',
        'Secondary': 'Collaborative Incident Tools',
        'Meta': 'Cloud-Based Security Solutions',
    },
    // Producer
    'Producer Analytics': {
        'Primary': 'Security System Analytics',
        'Secondary': 'Supply Chain Risk Assessment',
        'Meta': 'AI-Driven Production Security',
    },
    'Producer Management': {
        'Primary': 'Security Asset Management',
        'Secondary': 'Lifecycle Monitoring',
        'Meta': 'System Customization Frameworks',
    },
    'Producer Platform': {
        'Primary': 'Security Manufacturing Platform',
        'Secondary': 'Access Management Tools',
        'Meta': 'Digital Twins for Security Systems',
    },
    // Consumer
    'Consumer Analytics': {
        'Primary': 'Behavioral Security Analytics',
        'Secondary': 'User Risk Profiling',
        'Meta': 'Adaptive User Risk Management',
    },
    'Consumer Delivery': {
        'Primary': 'Security Solution Delivery',
        'Secondary': 'Dynamic Risk Mitigation',
        'Meta': 'AI-Optimized Security Deployment',
    },
    'Consumer Platform': {
        'Primary': 'User-Centric Security Platform',
        'Secondary': 'Intelligent Security Assistants',
        'Meta': 'Customized Security Frameworks',
    },
    // Technical Layer
    'Applications': {
        'Primary': 'Security Applications',
        'Secondary': 'AI-Powered Monitoring',
        'Meta': 'Workflow Optimization for Security',
    },
    'Platform': {
        'Primary': 'Cybersecurity Platform',
        'Secondary': 'Integrated AI Defense Frameworks',
        'Meta': 'Agent-Based Security Ecosystem',
    },
    'Control': {
        'Primary': 'Access and Identity Control',
        'Secondary': 'Real-Time Authentication',
        'Meta': 'Agent-Driven Resource Management',
    },
    'AI-Middleware': {
        'Primary': 'AI Middleware for Security',
        'Secondary': 'Threat Intelligence Processing Units',
        'Meta': 'LLM Integration for Cyber Defense',
    },
    'Hardware': {
        'Primary': 'IoT-Enabled Security Devices',
        'Secondary': 'Perimeter Sensors and Cameras',
        'Meta': 'Interoperable Security Systems',
    },
    // Regulator
    'Directives': {
        'Primary': 'Security Policy Directives',
        'Secondary': 'Compliance Guidelines',
        'Meta': 'Standards for Cybersecurity Frameworks',
    },
    'Reference Cases': {
        'Primary': 'Security Case Studies',
        'Secondary': 'Best Practices for Defense',
        'Meta': 'Success Stories in Cybersecurity',
    },
    'Standards': {
        'Primary': 'Security Standards',
        'Secondary': 'Interoperability Protocols',
        'Meta': 'AI Compliance for Security Systems',
    },
};
