/**
 * Recorded Audio Table
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 */


import React, { useState } from 'react';
import { Button, Col, Table, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function RecordedAudioTable({ interviewData }) {
    const [showModalTranscription, setShowModalTranscription] = useState(false);
    const [showModalMeta, setShowModalMeta] = useState(false);
    const [showModalFindings, setShowModalFindings] = useState(false);
    const [showModalArgument, setShowModalArgument] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [showModalQuotes, setShowModalQuotes] = useState(false);
    const [showModalRelevance, setShowModalRelevance] = useState(false);

    // Open modal with the full transcription
    const handleShowModalTranscription = (question) => {
        setModalContent({
            type: 'transcription',
            questionData: question,
        });
        setShowModalTranscription(true);
    };
    
    // Open modal with the full metadata
    const handleShowModalMeta = (question) => {
        setModalContent({
            type: 'metadata',
            questionData: question,
        });
        setShowModalMeta(true);
    };

    // Handle findings
    const handleShowFindings = (question) => {
        setModalContent({
            type: 'findings',
            questionData: question,
        });
        setShowModalFindings(true);
    };

    // Handle argument
    const handleShowArgument = (question) => {
        setModalContent({
            type: 'mainArgument',
            questionData: question,
        });
        setShowModalArgument(true);
    };

    // Handle quotes
    const handleShowQuotes = (question) => {
        setModalContent({
            type: 'quotes',
            questionData: question,
        });
        setShowModalQuotes(true);
    };

    // Handle response relevance
    const handleShowRelevance = (question) => {
        setModalContent({
            type: 'relevance',
            questionData: question,
        });
        setShowModalRelevance(true);
    };

    const handleCloseModalTranscription = () => setShowModalTranscription(false);
    const handleCloseModalMeta = () => setShowModalMeta(false);
    const handleCloseModalFindings = () => setShowModalFindings(false);
    const handleCloseModalArgument = () => setShowModalArgument(false);
    const handleCloseModalQuotes = () => setShowModalQuotes(false);
    const handleCloseModalRelevance = () => setShowModalRelevance(false);

    //console.log(interviewData);
    //console.log(interviewData.questions);

    return (
        <>
            <Col md={10}>
                <h1 style={{ marginTop: '50px', marginBottom: '30px' }}>
                    <b>Recorded Audio & Transcriptions</b>
                </h1>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Q#</th>
                            <th>Question</th>
                            <th>Audio</th>
                            <th>Theme</th>
                            <th>Keywords</th>                            
                            <th>Summary</th>
                            <th>Sentiment</th>
                            <th>Actions</th>                            
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: '0.85rem', color: '#6c757d', fontStyle: 'italic' }}>
                        {Object.keys(interviewData?.questions).map((questionSet) =>
                            Object.values(interviewData?.questions[questionSet])
                                .filter((question) => question?.audioData || question?.audio_transcription) // Only show rows with audio/transcription data
                                .map((question, index) => (
                                    <tr key={index}>
                                        <td>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Tooltip>
                                                        {(questionSet)
                                                            ? 
                                                            <>
                                                                <b>Set:</b> {questionSet}<br />
                                                                <b>Q#:</b> Q{question.question_id}
                                                            </>
                                                            : 'N/A'}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    {(question.keywords)
                                                        ? <>Q{question.question_id}</>
                                                        : 'N/A'}
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                        <td>{question.question}</td>
                                        <td>
                                            {question?.audioData && (question.audioData instanceof Blob || question.audioData instanceof File) ? (
                                                <audio controls src={URL.createObjectURL(question.audioData)} />
                                            ) : (
                                                'No audio available'
                                            )}
                                        </td>
                                        {/*
                                        <td>
                                            {question?.audioData ? (
                                                <audio controls src={URL.createObjectURL(question.audioData)} />
                                            ) : (
                                                'No audio available'
                                            )}
                                        </td>
                                        */}
                                        <td>{question.theme || 'N/A'}</td>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {(question.keywords)
                                                            ? question.keywords
                                                            : 'N/A'}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    {(question.keywords)
                                                        ? question.keywords
                                                        : 'N/A'}
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {question.summary || 'No summary available'}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    {question.summary
                                                        ? question.summary.slice(0, 50) + '...'
                                                        : 'No summary available'}
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                        <td>
                                            {question.sentiment || 'N/A'} ({question.sentiment_score || 'N/A'})
                                        </td>
                                        {/* Actions */}
                                        <td>
                                            <div className="d-grid gap-1">
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: '100%' }}
                                                    onClick={() => handleShowModalTranscription(question)}
                                                >
                                                    Transcript
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                    onClick={() => handleShowFindings(question)}
                                                >
                                                    Findings
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                    onClick={() => handleShowArgument(question)}
                                                >
                                                    Argument
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                    onClick={() => handleShowQuotes(question)}
                                                >
                                                    Quotes
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                    onClick={() => handleShowRelevance(question)}
                                                >
                                                    Relevance
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                    onClick={() => handleShowModalMeta(question)}
                                                >
                                                    Meta
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                        )}
                    </tbody>
                </Table>
            </Col>

            {/* Modal to display transcription */}
            <Modal show={showModalTranscription} onHide={handleCloseModalTranscription}>
                <Modal.Header closeButton>
                    <Modal.Title>Transcription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><b>Target Transcription (translated and transcribed):</b> {modalContent.questionData?.audio_transcription || 'No transcription available'}</p>
                    <p><b>Original transcripted:</b> {modalContent.questionData?.audio_detected_original_language !== 'EN' ? modalContent.questionData?.audio_original_text : 'Original language equals target language.'}</p>
                    <p><b>Detected Language:</b> {modalContent.questionData?.audio_detected_original_language}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalTranscription}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to display findings */}
            <Modal show={showModalFindings} onHide={handleCloseModalFindings}>
                <Modal.Header closeButton>
                    <Modal.Title>Findings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ paddingLeft: '20px' }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            {Array.isArray(modalContent.questionData?.findings) && modalContent.questionData.findings.length > 0 ? (
                                modalContent.questionData.findings.map((finding, index) => (
                                    <li key={index} style={{ marginBottom: '8px', color: '#495057' }}>
                                        {finding}
                                    </li>
                                ))
                            ) : (
                                <li style={{ color: '#6c757d' }}>No findings available</li>
                            )}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalFindings}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to display argument */}
            <Modal show={showModalArgument} onHide={handleCloseModalArgument}>
                <Modal.Header closeButton>
                    <Modal.Title>Argument</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {modalContent.questionData?.main_argument || 'No argument available'}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalArgument}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to display quotes */}
            <Modal show={showModalQuotes} onHide={handleCloseModalQuotes}>
                <Modal.Header closeButton>
                    <Modal.Title>Quotes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent.questionData?.quotes?.map((quote, index) => (
                        <p key={index}>{quote}</p>                   
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalQuotes}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to display relevance */}
            <Modal show={showModalRelevance} onHide={handleCloseModalRelevance}>
                <Modal.Header closeButton>
                    <Modal.Title>Relevance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent.questionData?.response_relevance?.map((relevance, index) => (
                        <p key={index}>{relevance}</p>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalRelevance(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal to display metadata */}
            <Modal show={showModalMeta} onHide={handleCloseModalMeta}>
                <Modal.Header closeButton>
                    <Modal.Title>Full Answer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <p><b>Question:</b> {modalContent.questionData?.question}</p>
                    <p><b>Transcription (Target Language):</b>{modalContent.questionData?.audio_transcription}</p>
                    <p><b>Original transcripted:</b> {modalContent.questionData?.audio_detected_original_language !== 'EN' ? modalContent.questionData?.audio_original_text : 'Original language equals target language.'}</p>
                    <p><b>Detected Language:</b> {modalContent.questionData?.audio_detected_original_language}</p>
                    <p><b>Duration:</b> {modalContent.questionData?.audio_duration} seconds</p>
                    <p><b>Theme:</b> {modalContent.questionData?.theme}</p>
                    <p><b>Keywords:</b> {modalContent.questionData?.keywords ? modalContent.questionData?.keywords : 'N/A'}</p>
                    <p><b>Summary:</b> {modalContent.questionData?.summary}</p>
                    <p><b>Sentiment:</b> {modalContent.questionData?.sentiment} (Score: {modalContent.questionData?.sentiment_score})</p>
                    <p><b>Findings:</b> {modalContent.questionData?.findings?.join(', ')}</p>
                    <p><b>Main Argument:</b> {modalContent.questionData?.main_argument}</p>
                    <p><b>Quotes:</b> {modalContent.questionData?.quotes?.join(', ')}</p>
                    <p><b>Response Relevance:</b> {modalContent.questionData?.response_relevance}</p>
                    <p><b>Answer Quality:</b> {modalContent.questionData?.answer_quality}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalMeta}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </>
    );
}







