/**
 * Enter use case information; Step 1
 *
 * Description: This form provides the fields representing the use case information
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-01
 * 
 */

import React, { useEffect, useState } from 'react';
import { Row, Col, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';

import CreatableSelect from 'react-select/creatable';

import Select from 'react-select';
//import countryList from 'react-select-country-list';
import { Country, City } from 'country-state-city'; // State

//import { handleKeyPressTag, removeTag } from '../elems/elem_tag';
import { getCurrentDateTime } from '../utils/utils_date';
//import AsyncSelect from 'react-select/async';
import { UserInformation } from '../auth_mgmt/auth_handling';

import { countryToContinentMapping } from '../elems/list_countries';

const getPartialId = (id, length = 10) => {
  if (id.length <= length) return id;
  return id.slice(0, length) + ' ...';
};

const getDateOnly = (dateTimeString) => {
  return dateTimeString.split(' ')[0];
};

const Info = ({ data, setData, useCaseDetails, uniqueKeywords, ucMode, setUCMode }) => { // onNextStep

  // Set initial tags for the use case
  //const [tags, setTags] = useState([]);
  //const creator = data.user;

  const [showInfoModal, setShowInfoModal] = useState(false);
  const info = `Describe the purpose and scope of the use case, highlighting key features or functionalities. Provide a summary of the main actors involved and mention any significant flows or scenarios.`;

  const { v4: uuidv4 } = require('uuid');
  const [displayId, setDisplayID] = useState('uc_' + uuidv4());
  const [visDisplayId, setVisDisplayId] = useState(getPartialId(displayId));

  const [selectedContinents, setSelectedContinents] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const [continentOptions, setContinentOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  // Handle the creation and update date of the use case
  /* OLD STATE
  useEffect(() => {    

    if (!data) return;    

    if (data.created && data.created !== '') {
      setDisplayID(data.ucid.value);
      setVisDisplayId(getPartialId(data.ucid.value));
    }

    setData(prevData => {
      if (!prevData) return prevData; // Ensure prevData exists
      const currentDate = getCurrentDate();
      // edit use case
      if (prevData.created && prevData.created !== '') {        
        if (prevData.updated !== currentDate) {
          return {
            ...prevData,
            updated: currentDate,
          };          
        }
      } else {
        if (prevData.created !== currentDate) {
          return {
            ...prevData,
            created: currentDate,
            ucid: displayId,
          };
        }
      }
      return prevData; // Return unchanged data to prevent re-renders
    });

  }, [data, setData, displayId])
  */
  
  useEffect(() => {

    if (!data) return;    
  
    if (data.created.value && data.created.value !== '') {
      setDisplayID(data.ucid.value);
      setVisDisplayId(getPartialId(data.ucid.value));
    }
  
    setData(prevData => {
      if (!prevData) return prevData; // Ensure prevData exists
  
      const currentDate = getCurrentDateTime();
      
      // Edit use case
      if (prevData.created.value && prevData.created.value !== '') {   
        /*     
        if (prevData.updated.value !== currentDate) {
          return {
            ...prevData,
            updated: {
              ...prevData.updated,
              value: currentDate,
              source: 'manually',
              modified: true,
              updated_timestamp: currentDate,
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: prevData.updated.version + 1,
            }
          };          
        }
        */
      } else {
        // Create use case
        if (prevData.created.value !== currentDate) {
          return {
            ...prevData,
            created: {
              ...prevData.created,
              value: currentDate,
              source: 'manually',
              modified: true,
              updated_timestamp: currentDate,
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: prevData.created.version + 1,
            },
            ucid: {
              ...prevData.ucid,
              value: displayId,
              source: 'manually',
              modified: true,
              updated_timestamp: currentDate,
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: prevData.ucid.version + 1,
            }
          };
        }
      }
      return prevData; // Return unchanged data to prevent re-renders
    });
  
  }, [data, setData, displayId]);

  // handle continent, country and city select information
  // init select field values based on continent, country and city values
  useEffect(() => {

    setContinentOptions([
      { value: 'Africa', label: 'Africa' },
      { value: 'Asia', label: 'Asia' },
      { value: 'Europe', label: 'Europe' },
      { value: 'North America', label: 'North America' },
      { value: 'Oceania', label: 'Oceania' },
      { value: 'South America', label: 'South America' }
    ])

    // Set country options based on the selected continents
    const tmpSelectedContinents = selectedContinents.length !== 0 ? selectedContinents : data.continents.value;

    // Ensure all elements in tmpSelectedContinents have the same structure
    const updatedCountryOptions = Country.getAllCountries()
      .filter(country => {
        // Get the continent mapping for the current country
        const countryContinent = countryToContinentMapping[country.isoCode];

        // Match based on whether data.country is populated
        const continentMatches = tmpSelectedContinents.some(continent => {
          // Handle cases where continent might be a string or an object with a 'value' property
          const continentValue = typeof continent === 'string' ? continent : continent.value;

          // Return the match result
          return countryContinent === continentValue;
        });

        // Only return countries that match the selected continents
        return continentMatches;
      })
      .map(country => ({ value: country.isoCode, label: country.name, flag: country.flag, latitude: country.latitude, longitude: country.longitude }));

    // Update the country options in the state
    setCountryOptions(updatedCountryOptions);
    //console.log('Updated Country Options:', updatedCountryOptions);

    // Set city options based on the selected countries
    const tmpSelectedCountries = selectedCountries.length !== 0 ? selectedCountries : data.country_code.value;

    // Ensure that tmpSelectedCountries contains the correct format (e.g., country codes)
    const updatedCityOptions = tmpSelectedCountries
      .flatMap(country => {
        // Check if country is a string or an object with a value property
        const countryCode = typeof country === 'string' ? country : country.value;

        // Get cities of the country and handle cases where no cities are found
        const cities = City.getCitiesOfCountry(countryCode) || [];
        return cities;
      })
      .map(city => ({ label: city.name, value: city.name }));

    // Update the city options in the state
    setCityOptions(updatedCityOptions);
    //console.log('Updated City Options:', updatedCityOptions);


  }, [selectedContinents, selectedCountries, data.continents, data.country_code, data.country.length]);

  /**
   * Update the data object with the input field value.
   * @param {*} e 
   */
  /*
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData(prevData => ({
      ...prevData,
      [name]: value,      
    }));
  };
  */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setData(prevData => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        value: value, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the update
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDateTime(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it
        uid: UserInformation().uid, // Record the user ID
        version: prevData[name].version + 1 // Increment the version
      }
    }));
  };

  /**
   * Provides some predefined description template for the use case.
   * This contains the structure: name, acronym, maturity, description, actor, flows, etc.
   */
  const handleDescr = () => {

    const tmp = `Use Case Description:
  
    - Briefly describe the purpose and scope of the use case.
    - Highlight key features or functionalities.
    - Provide a summary of the main actors involved.
    - Mention any significant flows or scenarios.`;

    /*
    setData(prevData => ({
      ...prevData,
      description: tmp,
    }));
    */
    setData(prevData => ({
      ...prevData,
      description: {
        ...prevData.description,
        value: `${prevData.description.value || ''}\n\n${tmp}`, // Append tmp to the existing value (if any)
        source: 'manual', // Record the source of the update
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDateTime(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.description.version + 1 // Increment the version
      }
    }));
  }

  const handleContinentChange = (selectedOptions) => {

    if (!selectedOptions) return;

    setSelectedContinents(selectedOptions);

    // Reset countries and cities when continent is set empty
    if (selectedOptions.length === 0) {
      setSelectedCountries([]); // Reset countries when continents change
      setSelectedCities(null); // Reset city when continents change
    }

    const selectedContinentsLabels = selectedOptions.map(option => option.label);

    // Update the data state with the selected continents as a list
    /*
    setData((prevData) => ({
        ...prevData,
        continents: selectedContinentsLabels,
        country: [],
        city: [],
    }));
    */
   // Update the data state with the selected continents as a list
    setData((prevData) => ({
      ...prevData,
      continents: {
        ...prevData.continents,
        value: selectedContinentsLabels,
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.continents.version + 1
      },
      country: {
        ...prevData.country,
        //value: [],
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.country.version + 1
      },
      city: {
        ...prevData.city,
        //value: [],
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(), // new Date().toISOString(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.city.version + 1
      }
    }));

    // Set data values empty when continent is set empty
    if (selectedOptions.length === 0) {
      setData((prevData) => ({
        ...prevData,
        country: {
          ...prevData.country,
          value: [],
          source: 'manual',
          modified: true,
          updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
          updated_by: UserInformation().displayName,
          uid: UserInformation().uid,
          version: prevData.country.version + 1
        },
        city: {
          ...prevData.city,
          value: [],
          source: 'manual',
          modified: true,
          updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
          updated_by: UserInformation().displayName,
          uid: UserInformation().uid,
          version: prevData.city.version + 1
        }
      }));
    }


    //setData((prevData) => ({ ...prevData, continent: selectedContinent ? selectedContinent.label : ''}))
  };

  const handleCountryChange = (selectedOptions) => {

    if (!selectedOptions) return;

    setSelectedCountries(selectedOptions);

    // Reset cities when country is set empty
    if (selectedOptions.length === 0) {
      setSelectedCities(null); // Reset city when countries change
    }

    const selectedCountryLabels = selectedOptions.map(option => option.label);
    const selectedCountryCodes = selectedOptions.map(option => option.value)
    const selectedCountryFlags = selectedOptions.map(option => option.flag);

    // Update the data state with the selected countries as a list
    /*
    setData((prevData) => ({
        ...prevData,
        country: selectedCountryLabels,
        country_code: selectedCountryCodes,
        country_flag: selectedCountryFlags,
        city: []
    }));
    */
    // Update the data state with the selected countries, country codes, and flags as a list
    setData((prevData) => ({
      ...prevData,
      country: {
        ...prevData.country,
        value: selectedCountryLabels,
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.country.version + 1
      },
      country_code: {
        ...prevData.country_code,
        value: selectedCountryCodes,
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.country_code.version + 1
      },
      country_flag: {
        ...prevData.country_flag,
        value: selectedCountryFlags,
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.country_flag.version + 1
      },
      city: {
        ...prevData.city,
        //value: [],
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.city.version + 1
      }
    }));

    // Set data values empty when country is set empty
    if (selectedOptions.length === 0) {
      setData((prevData) => ({
        ...prevData,
        city: {
          ...prevData.city,
          value: [],
          source: 'manual',
          modified: true,
          updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
          updated_by: UserInformation().displayName,
          uid: UserInformation().uid,
          version: prevData.city.version + 1
        }
      }));
    }

    /*
    setData((prevData) => (
      { 
        ...prevData, 
        country: selectedCountry ? selectedCountry.label : '',
        country_code: selectedCountry ? selectedCountry.value : ''
      }));
    
    setSelectedCountry(selectedCountry)
    */
  };

  const handleCityChange = (selectedOptions) => {

    if (!selectedOptions) return;

    setSelectedCities(selectedOptions);

    const selectedCityLabels = selectedOptions.map(option => option.label);
    //console.log('Selected City:', selectedCities);

    // Update the data state with the selected cities as a list
    /*
    setData((prevData) => ({
        ...prevData,
        city: selectedCityLabels
    }));
    */
    // Update the data state with the selected cities as a list  
    setData((prevData) => ({
      ...prevData,
      city: {
        ...prevData.city,
        value: selectedCityLabels,
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(), //new Date().toISOString(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.city.version + 1
      }
    }));

    //setData((prevData) => ({ ...prevData, city: selectedCity ? selectedCity.label : ''}))
  }

  const handleModalInfo = () => {
    setShowInfoModal(!showInfoModal);
  };

  // Update the data object with the use case details
  // Used to pre-fill the form fields based on the prompt responses
  /*
  useEffect(() => {

    //console.log(useCaseDetails);
    if (!useCaseDetails) return;
    setData(prevData => ({
      ...prevData,
      title: useCaseDetails[0].response,
      acronym: useCaseDetails[1].response,
      maturity: useCaseDetails[2].response,
      application: useCaseDetails[3].response,
      status: useCaseDetails[4].response,
      tags: useCaseDetails[5].response.split(',') || [],
      description: useCaseDetails[6].response,
    }));  

  }, [useCaseDetails, setData, creator]);
  */

  /*
  useEffect(() => {

    if (!useCaseDetails || useCaseDetails.length === 0 || !Array.isArray(useCaseDetails)) return;

    setData(prevData => ({
      ...prevData,
      title: useCaseDetails[0]?.response || '',
      acronym: useCaseDetails[1]?.response || '',
      maturity: useCaseDetails[2]?.response || '',
      application: useCaseDetails[3]?.response || '',
      status: useCaseDetails[4]?.response || '',
      tags: useCaseDetails[5]?.response?.split(',') || [],
      description: useCaseDetails[6]?.response || '',
    }));

  }, [useCaseDetails, setData]);
  */

  /*
  useEffect(() => {

    if (!useCaseDetails || useCaseDetails.length === 0 || !Array.isArray(useCaseDetails)) return;

    setData(prevData => ({
      ...prevData,
      title: useCaseDetails[0]?.response || '',
      acronym: useCaseDetails[1]?.response || '',
      maturity: useCaseDetails[2]?.response || '',
      application: useCaseDetails[3]?.response || '',
      status: useCaseDetails[4]?.response || '',
      tags: useCaseDetails[5]?.response?.split(',') || [],
      description: useCaseDetails[6]?.response || '',
    }));

  }, [useCaseDetails, setData, isPrompt, setIsPrompt]);
  */

  const handleFocus = () => {
    setVisDisplayId(displayId);
  };

  const handleBlur = () => {
    setVisDisplayId(getPartialId(visDisplayId));
  };

  const handleTagChange = (selectedOptions) => {

    const selectedTags = selectedOptions ? selectedOptions.map((option) => option.value) : [];
  
    // Prevent duplicates by ensuring all selected tags are unique
    const uniqueTags = [...new Set(selectedTags)];
  
    setData((prevData) => ({
      ...prevData,
      tags: {
        ...prevData.tags,
        value: uniqueTags,
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.tags.version + 1,
      },
    }));
  };

  const handleManualTagAddition = (inputValue) => {
    // Check if the inputValue is already in the selected tags
    if (data.tags.value.includes(inputValue)) {
    
      alert(`The tag "${inputValue}" is already added.`);
      return;
    }
  
    // Check if it's a valid input
    if (inputValue.trim() === '') {
      alert('Tag cannot be empty.');
      return;
    }
  
    // Add the new tag
    setData((prevData) => ({
      ...prevData,
      tags: {
        ...prevData.tags,
        value: [...prevData.tags.value, inputValue],
        source: 'manual',
        modified: true,
        updated_timestamp: getCurrentDateTime(),
        updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: prevData.tags.version + 1,
      },
    }));
  };

  return (
    <div>
      <Row style={ { marginBottom:'20px' } }>
        {/* Headline and Toolbar */}
        <Col md={3}>
            <div>
                <h3 className="text-start">Define Use Case</h3>
                <h6 className="text-start">Adjust manually</h6>
            </div>
        </Col>
        {/* Toolbar */}
        <Col md={9} className="d-flex justify-content-end align-items-center"> 
          <OverlayTrigger placement="top" overlay={<Tooltip>Use pre-defined data.</Tooltip>}>
              <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled hidden>
                  <i className="bi bi-clipboard-data"></i>
              </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Edit the data.</Tooltip>}>
              <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled hidden>
                  <i className="bi bi-pencil"></i>
              </Button>
          </OverlayTrigger>                                                      
          <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }} hidden></div>
          <OverlayTrigger placement="top" overlay={<Tooltip>Download data</Tooltip>}>
              <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled hidden>
                  <i className="bi bi-download"></i>
              </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Upload data</Tooltip>}>
              <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled hidden>
                  <i className="bi bi-upload"></i>
              </Button>
          </OverlayTrigger>                                    
        </Col>
      </Row>
      <Row>
        {/* Define use case name, acronym and more */}
        <Col md={7} style={{ textAlign: 'left' }}>
          {/* Use case name and acronym */}
          <Row className="mb-3">
            <Col md={7}>
              <label className="form-label small">Use Case Name:</label>
              <input className="form-control" type="text" name="title" value={data.title.value} onChange={handleInputChange} placeholder='use case name, keep it concise'/>
            </Col>
            <Col md={2}>
              <label className="form-label small">Acronym:</label>
              <input className="form-control" type="text" name="acronym" value={data.acronym.value} onChange={handleInputChange} placeholder='e.g. ucm'/>
            </Col>
            <Col md={3}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label className="form-label small">Use Case ID:</label>
                <div style={{ marginTop: '-5px' }}>
                {visDisplayId.length > 14 && (
                  <Button className='btn-sm' variant="outline-secondary" style={{ marginTop: '0px' }} onClick={handleBlur}>
                    <i className="bi bi-eye-slash"></i>
                  </Button>
                )}
                {visDisplayId.length <= 14 && (
                  <Button className='btn-sm' variant="outline-secondary" style={{ marginTop: '0px' }} onClick={handleFocus}>
                    <i className="bi bi-eye"></i>
                  </Button>
                )}
                </div>
              </div>

              <input 
                className="form-control" 
                type="text" 
                name="id" 
                value={visDisplayId} 
                disabled           
              />
            </Col>
          </Row>
          {/* Creator and date */}
          <Row className="mb-3">
            <Col md={6}>
              <label className="form-label small">Created by 
                {ucMode === 'template' && <span style={{ color: 'green' }}> (template by {getDateOnly(data.originally_created_by.value)})</span>}
                :
              </label>            
              <input className="form-control" type="text" name="details" value={data.created_by.value} disabled/>
            </Col>
            {/* Creator and date */}
            <Col md={3}>   
              <label className="form-label small">Date Created:</label>              
              <input className="form-control" type="text" name="details" value={getDateOnly(data.created.value)} disabled/>
            </Col>
            <Col md={3}>   
              {ucMode === 'edit' && (
                <>
                <label className="form-label small">Last Updated:</label>              
                <input className="form-control" type="text" name="details" value={getDateOnly(data.updated.value)} disabled/>
                </>
              )}
              {ucMode === 'template' && (
                <>
                <label className="form-label small">Originally created:</label>              
                <input className="form-control" type="text" name="details" style={{ color: 'green' }} value={getDateOnly(data.originally_created.value)} disabled/>
                </>
              )}
            </Col>
          </Row>
          {/* Location and status */}
          <Row className="mb-3">
            <Col md={selectedContinents.length ? 4 : 6}>
              <label className="form-label small">Continent:</label>
              <Select
                options={continentOptions}
                isMulti
                value={continentOptions.filter(option => data.continents.value.includes(option.label))}
                //value={continentOptions.filter(option => data.continents.includes(option.label))}
                //value={data.continents[0]} // selectedContinents
                onChange={handleContinentChange}
                className="basic-multi-select"
                classNamePrefix="select"
                isClearable
                isSearchable
                placeholder="Select continent(s)"
              />
            </Col>

            {(selectedContinents.length > 0 || data.country.value.length > 0) && (
              <Col md={selectedCountries.length ? 4 : 6}>
                <label className="form-label small">Country:</label>
                <Select
                  options={countryOptions}
                  isMulti
                  value={countryOptions.filter(option => data.country.value.includes(option.label))}
                  //value={countries.filter(option => data.country.includes(option.label))}
                  //value={data.country.map(country => ({ label: country, value: country }))}
                  onChange={handleCountryChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  placeholder="Select country(s)"
                />
              </Col>
            )}

            {(selectedCountries.length > 0 || data.city.value.length > 0 || (selectedCities && selectedCities.length > 0)  ) && (
              <Col md={4}>
                <label className="form-label small">City:</label>
                <Select
                  options={cityOptions}
                  isMulti
                  value={cityOptions.filter(option => data.city.value.includes(option.label))}
                  //value={data.city.map(city => ({ label: city, value: city }))}
                  //value={data.city ? cityOptions.filter(option => data.city.includes(option.label)) : []}
                  onChange={handleCityChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  placeholder="Select city(s)"
                />
              </Col>
            )}            
          </Row>        
          {/* Add maturity degree, application area and status */}
          <Row>
            {/* Add maturity degree selection */}
            <Col md={4}>
              <label className='form-label small'>Maturity degree:</label>
              <OverlayTrigger placement="top" overlay={<Tooltip>Maturity determines what level of development you see the use case in</Tooltip>}>
              <select className="form-control" name="maturity" value={data.maturity.value} onChange={handleInputChange}>
                <option value="">Select maturity degree</option>
                <option value="Initial">Initial</option>
                <option value="Managed">Managed</option>
                <option value="Defined">Defined</option>
                <option value="Quantitative">Quantitative</option>
                <option value="Optimized">Optimized</option>
              </select>
              </OverlayTrigger>
            </Col>
            {/* Add application area selection */}
            <Col md={4}>
              <label className="form-label small">Application area:</label>
              <OverlayTrigger placement="top" overlay={<Tooltip>Application area classifies its area it may operate in</Tooltip>}>
              <select className="form-control" name="application" value={data.application.value} onChange={handleInputChange}>
                <option value="">Select area</option>
                <option value="Industry">Industry</option>
                <option value="Administration">Administration</option>
                <option value="Research">Research</option>
                <option value="Public">Other</option>
              </select>
              </OverlayTrigger>
            </Col>
            {/* Add status selection */}
            <Col md={4}>
              <label className="form-label small">Status:</label>
              <OverlayTrigger placement="top" overlay={<Tooltip>Status determines how you classify the stage. Draft = Work In Progress; Created = Modeled; Validated = Tested; and Applied = Used</Tooltip>}>
              <select className="form-control" name="status" value={data.status.value} onChange={handleInputChange}>
                <option value="">Select status</option>
                <option value="Draft">Draft</option>
                <option value="Created">Created</option>
                <option value="Validated">Validated</option>
                <option value="Applied">Applied</option>
              </select>
              </OverlayTrigger>
            </Col>            
          </Row>  

          {/* Add tags to classify the use case */}

          {/* Former tag label and select field */}
          {/*
          <Row style={ { marginTop:'20px' }} >
            {/* Add badges to classify the use case 
            <Col md={12}>              
              <label className="form-label small">Keywords to classify use case:</label>
              <Row>
                <Col md="6">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <label className="form-label small">Select existing keywords:</label>
                  <select className="form-select" onChange={(e) => {
                    const selectedKeyword = e.target.value;
                    if (selectedKeyword && !data.tags.value.includes(selectedKeyword)) {
                      setData(prevData => ({
                        ...prevData,
                        tags: {
                          ...prevData.tags,
                          value: [...prevData.tags.value, selectedKeyword], // Add the new keyword to the tags value array
                          source: 'manual', // Record the source of the update
                          modified: true, // Mark as modified
                          updated_timestamp: getCurrentDateTime(), //new Date().toISOString(), // Set the updated timestamp
                          updated_by: UserInformation().displayName, // Record who updated it
                          uid: UserInformation().uid, // Record the user ID
                          version: prevData.tags.version + 1 // Increment the version
                        }
                      }));
                    }
                  }}>
                    <option value="">-- Select Keyword --</option>
                    {uniqueKeywords.map((keyword, index) => (
                      <option key={index} value={keyword}>
                        {keyword}
                      </option>
                    ))}
                  </select>
                </div>
                </Col>      
                <Col md={6}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <label className="form-label small">Add keywords manually:</label>              
                    <div 
                      style={{ 
                        border: '1px solid #ccc', 
                        width: '300px', 
                        marginLeft: '5px', 
                        padding: '5px 10px', 
                        borderRadius: '4px', 
                        fontSize: '14px',
                        lineHeight: '1.8',
                        outline: 'none'
                      }} 
                      contentEditable 
                      onKeyDown={(event) => handleKeyPressTag(event, data.tags.value, setData)}
                    ></div>                
                  </div>
                </Col>
              </Row>              
              {data.tags.value.map((tag, index) => (
                <span key={index} className="badge" style={{ marginRight: 5, backgroundColor: '#d3d3d3', color: '#000' }}>
                  {tag + ' '}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => removeTag(index, data.tags, setData)}
                    style={{ verticalAlign: 'middle' }}
                  ></button>
                </span>
              ))}
            </Col>
          </Row>
          */}

          <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
              <label className="form-label small">Keywords to classify use case:</label>
              <div style={{ marginBottom: '10px' }}>
                <CreatableSelect
                  isMulti
                  options={uniqueKeywords
                    .map((keyword) => keyword.trim().toLowerCase()) // Normalize keywords
                    .filter(
                      (keyword) =>
                        !data.tags.value
                          .map((tag) =>
                            typeof tag === 'object' ? tag.value.trim().toLowerCase() : tag.trim().toLowerCase()
                          )
                          .includes(keyword)
                    )
                    .map((keyword) => ({
                      value: keyword,
                      label: keyword,
                    }))}
                  value={data.tags.value.map((tag) => ({
                    value: typeof tag === 'object' ? tag.value.trim() : tag.trim(),
                    label: typeof tag === 'object' ? tag.label.trim() : tag.trim(),
                  }))}
                  onChange={handleTagChange}
                  onCreateOption={handleManualTagAddition}
                  placeholder="Select or type to add keywords"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      minHeight: '40px',
                    }),
                  }}
                />
              </div>
              {/* Render the selected tags as badges 
              {data.tags.value.map((tag, index) => (
                <span
                  key={index}
                  className="badge"
                  style={{
                    marginRight: 5,
                    backgroundColor: '#d3d3d3',
                    color: '#000',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '5px 10px',
                    borderRadius: '20px',
                  }}
                >
                  {tag}
                  <button
                    type="button"
                    className="btn-close"
                    style={{
                      marginLeft: '8px',
                      verticalAlign: 'middle',
                    }}
                    onClick={() => {
                      const updatedTags = data.tags.value.filter((_, i) => i !== index);
                      setData((prevData) => ({
                        ...prevData,
                        tags: {
                          ...prevData.tags,
                          value: updatedTags,
                          source: 'manual',
                          modified: true,
                          updated_timestamp: getCurrentDateTime(),
                          updated_by: UserInformation().displayName,
                          uid: UserInformation().uid,
                          version: prevData.tags.version + 1,
                        },
                      }));
                    }}
                  ></button>
                </span>
              ))}
              */}
            </Col>
          </Row>
        </Col>
        {/* Add description and ID */}
        <Col md={5} style={{ textAlign: 'left' }}>
          <Row>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom:'0px' }}>
                <label className="form-label small">Use Case Description:</label>      
                <div style={{ marginTop: '-5px' }}>     
                <OverlayTrigger placement="top" overlay={<Tooltip>About the description</Tooltip>}>
                  <Button className='btn-sm' style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleModalInfo}>
                      <i className="bi bi-info-circle"></i>
                  </Button>
                </OverlayTrigger>  
                </div>
              </div>
              <textarea 
                className="form-control" 
                name="description" 
                value={data.description.value} 
                onChange={handleInputChange} 
                rows={8}
                placeholder="Enter a description of your use case..."
                style={ { minHeight: '300px', height:'400px' } }>
              </textarea>
              {/*
              <Button variant="outline-primary" style={{ float: 'left', padding: '0px', paddingLeft: '5px', paddingRight: '5px', marginTop: '3px' }} onClick={handleDescr}>hint</Button>
              */}
            </div>
          </Row>
          {/*}
          <Row className="mb-3" style={{ marginTop: '20px', textAlign: 'right' }}>
              <label className="form-label">
                ID: {data.ucid},
                Created on: {data.created}
              </label>
          </Row>
          */}
        </Col>
      </Row>
      {showInfoModal && (
        <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
        <Modal.Body style={{ whiteSpace: 'pre-line' }}>
          {info}
          <OverlayTrigger placement="top" overlay={<Tooltip>Include template description</Tooltip>}>
            <Button 
              variant="outline-primary" 
              style={{ marginTop: '10px' }} 
              onClick={() => {
                handleDescr();
                setShowInfoModal(false);
              }}
            >Add template description</Button>
          </OverlayTrigger>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowInfoModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </div>
  );
};

export default Info;
