/**
 * Chat UC Data Retrieval
 *
 * Description: This component retrieves use case details based on user prompts in a chat-like interface. 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-13
 * Updated: 2024-05-01
 * Notes: This component is used to interact with the OpenAI API to retrieve use case details based on user prompts.
 */

import { prompts_info, prompts_actor, prompts_actions, prompts_conditions, prompts_components } from './chat_prompts'; // prompts

// Function to find a concept in the sidebarItems array
const findConceptInSidebarItems = (searchedConcept, sidebarItems) => {
  let foundConcept = null;

  // Recursive function to search for the concept
  const searchConcept = (concepts) => {
      for (const concept of concepts) {
      if (concept.title === searchedConcept) {
          foundConcept = concept;
          break;
      }
      if (concept.subconcepts) {
          searchConcept(concept.subconcepts);
      }
      }
  };

  searchConcept(sidebarItems);

  return foundConcept;
};

// Function to enrich prompts with semantic terms
/*
const enrichPromptsWithSemanticTerms0 = (promptsComponents, componentsSemanticEnrichmentSearchTerm, sidebarItems) => {
  return promptsComponents.map((prompt, index) => {
      const categoryKeys = Object.keys(componentsSemanticEnrichmentSearchTerm);
      const searchTerm = componentsSemanticEnrichmentSearchTerm[categoryKeys[index]];

      if (searchTerm) {
          const concept = findConceptInSidebarItems(searchTerm, sidebarItems);
          if (concept) {
              const conceptString = JSON.stringify(concept);
              return `${prompt} ${conceptString}`;
          }
      }
      return prompt;
  });
};
*/

/*

Applies the former old enrichPromptsWithSemanticTerms function to the new prompt object structure

const enrichPromptsWithSemanticTerms0 = (promptsComponents, componentsSemanticEnrichmentSearchTerm, sidebarItems) => {
  return promptsComponents.map((promptObj, index) => {
    const categoryKeys = Object.keys(componentsSemanticEnrichmentSearchTerm);
    const searchTerm = componentsSemanticEnrichmentSearchTerm[categoryKeys[index]];

    if (searchTerm) {
      const concept = findConceptInSidebarItems(searchTerm, sidebarItems);
      if (concept) {
        const conceptString = JSON.stringify(concept);
        return {
          ...promptObj,
          example: promptObj.example.replace('{semantic_terms}', conceptString),
        };
      }
    }
    return {
      ...promptObj,
      example: promptObj.example.replace('{semantic_terms}', 'No relevant terms found.'),
    };
  });
};
*/

/*
const enrichPromptsWithSemanticTerms0 = (promptsComponents, componentsSemanticEnrichmentSearchTerm, sidebarItems) => {

  return promptsComponents.map((promptObj, index) => {

    const categoryKeys = Object.keys(componentsSemanticEnrichmentSearchTerm);
    const categoryName = categoryKeys[index];
    const concepts = componentsSemanticEnrichmentSearchTerm[categoryName];

    //console.log('Category Name:', categoryName);
    //console.log('Concepts:', concepts);
    console.log(`Prompt Index: ${index}`);
    console.log(`Category Name: ${categoryName}`);
    console.log(`Concepts:`, concepts);

    if (concepts) {
      console.log('Asked Concepts:', concepts);
      let conceptString = '';

      // Retrieve and concatenate concepts for Primary, Secondary, and Meta
      if (concepts.Primary) {
        const primaryConcept = findConceptInSidebarItems(concepts.Primary, sidebarItems);
        conceptString += `Primary: ${primaryConcept ? JSON.stringify(primaryConcept) : 'No relevant terms found.'} \n`;
      }

      if (concepts.Secondary) {
        const secondaryConcept = findConceptInSidebarItems(concepts.Secondary, sidebarItems);
        conceptString += `Secondary: ${secondaryConcept ? JSON.stringify(secondaryConcept) : 'No relevant terms found.'} \n`;
      }

      if (concepts.Meta) {
        const metaConcept = findConceptInSidebarItems(concepts.Meta, sidebarItems);
        conceptString += `Meta: ${metaConcept ? JSON.stringify(metaConcept) : 'No relevant terms found.'}`;
      }

      // Replace placeholder with the concatenated string
      return {
        ...promptObj,
        example: promptObj.example.replace('{semantic_terms}', conceptString),
      };
    }

    // Default case when no concepts found
    return {
      ...promptObj,
      example: promptObj.example.replace('{semantic_terms}', 'No relevant terms found.'),
    };
  });
};
*/

const enrichPromptsWithSemanticTerms = (
  promptsComponents,
  componentsSemanticEnrichmentSearchTerm,
  sidebarItems
) => {

  //console.log('componentsSemanticEnrichmentSearchTerm:', componentsSemanticEnrichmentSearchTerm);

  return promptsComponents.map((promptObj) => {
    const { category_key } = promptObj; // Get category_key from prompt
    //console.log('Category Key:', category_key);

    // Match the enrichment terms using category_key
    const concepts = componentsSemanticEnrichmentSearchTerm[category_key];
    
    //console.log('Concepts:', concepts);
    if (!concepts) {
      console.warn(`No enrichment terms found for category: ${category_key}`);
      return {
        ...promptObj,
        example: promptObj.example.replace(
          '{semantic_terms}',
          'No relevant terms found.'
        ),
      };
    }

    let conceptString = '';

    // Retrieve and concatenate terms for Primary, Secondary, and Meta
    if (concepts.Primary) {
      const primaryConcept = findConceptInSidebarItems(concepts.Primary, sidebarItems);
      //console.log('Primary Concept:', primaryConcept);
      conceptString += `Primary: ${primaryConcept ? JSON.stringify(primaryConcept) : 'No relevant terms found.'}\n`;
    }

    if (concepts.Secondary) {
      const secondaryConcept = findConceptInSidebarItems(concepts.Secondary, sidebarItems);
      conceptString += `Secondary: ${secondaryConcept ? JSON.stringify(secondaryConcept) : 'No relevant terms found.'}\n`;
    }

    if (concepts.Meta) {
      const metaConcept = findConceptInSidebarItems(concepts.Meta, sidebarItems);
      conceptString += `Meta: ${metaConcept ? JSON.stringify(metaConcept) : 'No relevant terms found.'}`;
    }

    // Replace placeholder with the constructed concept string
    return {
      ...promptObj,
      example: promptObj.example.replace('{semantic_terms}', conceptString),
    };
  });
};

/**
 * Function to retrieve use case details based on pre-defined prompts.
 * @param {*} openai 
 * @param {*} systemMessage 
 * @param {*} prompts 
 * @returns an array of use case details
 */
const retrieveUCDetails = async (openai, systemMessage, requestMode, semanticData, setLoaderResponse, components_semantic_enrichment) => {

  // Initialize an array to store the use case details
  const useCaseDetails = [];
  let enrichedPromptsComponents = [];

  //let prompts = await FetchRequestMode(requestMode);
  //console.log('Prompts:', prompts);

  // Map semantic data to prompts components
  if (requestMode === 'init' || requestMode === 'uc_components') {
      enrichedPromptsComponents = enrichPromptsWithSemanticTerms(prompts_components, components_semantic_enrichment, semanticData);
      //console.log('Enriched Prompts Components:', enrichedPromptsComponents);
  }

  // Define an object to map request modes to their corresponding prompts
  const modePromptsMap = {
    'init': prompts_info.concat(prompts_actions).concat(prompts_conditions).concat(prompts_actor).concat(enrichedPromptsComponents),
    'uc_info': prompts_info,
    //'uc_meta': [],
    'uc_actions': prompts_actions.concat(prompts_conditions), // Concatenate prompts for uc_actions and uc_conditions
    'uc_actor': prompts_actor,      
    'uc_components': enrichedPromptsComponents,
    'uc_model': [],
  };

  //console.log('Request Mode:', prompts_components);
  //console.log('Mode Prompts Map:', enrichedPromptsComponents);

  //console.log('NEU Request Mode:', requestMode);
  //console.log('Mode Prompts Map:', modePromptsMap[requestMode]);

  // Get the prompts based on the request mode
  const prompts = modePromptsMap[requestMode];
  //console.log('Prompts:', prompts);

  if (prompts === undefined || prompts === null) {
    //console.log('Prompts are null.');
    console.log(`No prompts defined for request mode '${requestMode}'.`);
    return null;
  }

  try {
    // Iterate over each given pre-defined prompt to retrieve the use case details
    for (const promptObj of prompts) { // prompt
      const { prompt, context, expected_format, items, example } = promptObj; // included this line to address new prompt object structure
      const completion = await openai.chat.completions.create({
        model: 'gpt-4o-mini', //'gpt-3.5-turbo', // 'gpt-4o'
        messages: [
          //...messages, // Previous conversation messages
          { role: 'system', content: systemMessage },
          //{ role: 'user', content: prompt },
          { role: 'user', content: `Context: ${context}\nExpected format: ${expected_format}\nItems: ${items}\nExample: ${example}\n\n${prompt}` },
        ],
      });

      // Extract the completion response
      const response = completion.choices[0].message.content;
      //console.log('Response:', response);

      setLoaderResponse(response);
      //console.log('Loader Response:', response);

      useCaseDetails.push({ prompt, response });        
    }    
    return useCaseDetails;
  } catch (error) {
    console.error('Error in chat_uc_data:', error);
    setLoaderResponse('An error occurred while fetching details. Please contact the administrator or try again later.');
    return null;
  }  
};    

  export default retrieveUCDetails;