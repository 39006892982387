/**
 * DB configuration adapter
 *
 * Description: This module contains functions to fetch database configurations, connecting to the databases, respectively and dynamically fetching the actual databases.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-12-09
 * 
 */

import { db } from '../auth_mgmt/auth_config';
import {
  collection,
  getDocs,
} from 'firebase/firestore';

const dbLabelsCollection = collection(db, 'db_meta');

/**
 * Fetch all database labels dynamically
 * @returns {Promise<Object[]>} List of database configurations
 */
export const fetchDbConfigurations = async () => {
  try {

    const querySnapshot = await getDocs(dbLabelsCollection);
    const dbConfigs = querySnapshot.docs.map((doc) => ({
      id: doc.id, // Document ID as the database label
      ...doc.data(), // Include all meta fields (e.g., dbName, purpose)
    }));
    console.log('Fetched DB Configurations:', dbConfigs);
    return dbConfigs;

  } catch (error) {

    console.error('Error fetching database configurations:', error);
    return [];
    
  }
};