/**
 * Impressum
 *
 * Description: Impressum page
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-12-07
 * 
 */

import React from 'react';
import { Container, Row } from 'react-bootstrap';


export default function Impressum() {

    return (
        <Container fluid>
        <Row align='center'>      
            <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Impressum</b></h1>
            <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
              <i>UCM</i> is a domain-agnostic tool designed to explore required scenarios, actors and components to 
              conceptualize and build use cases. It is part of my doctoral research.
            </p>
        </Row>
        <Row align='center' style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
        <h4 id="m46">Diensteanbieter</h4>
            <p>Marc Augusto</p>
            <p>Ernst-Reuter-Platz 2</p>
            <p>10587 Berlin</p>

            <h4 id="m56">Kontaktmöglichkeiten</h4>
            <p>E-Mail-Adresse: marc.augusto@tu-berlin.de</p>

            <h4 id="m65">Haftungs- und Schutzrechtshinweise</h4>
            
            <p>Haftungsausschluss:</p>
            <p>Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z. B. das Impressum, die Datenschutzerklärung, AGB oder verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.</p>
            
            <p>Urheberrechte und Markenrechte:</p><br/>
            <p>Alle auf dieser Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteinhaber.</p>
            
            <p>Hinweise auf Rechtsverstöße:</p><br />
            <p>Sollten Sie innerhalb unseres Internetauftritts Rechtsverstöße bemerken, bitten wir Sie uns auf diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach Kenntnisnahme unverzüglich entfernen.</p>

            <h2>Logo und Darstellungen</h2><p>Das Logo und die visuellen Darstellungen auf dieser Website sind urheberrechtlich geschützt. Das Logo wurde von Fabio Augusto entworfen und exklusiv für diese Website bereitgestellt.</p>
            <p>Die Darstellungen und grafischen Inhalte wurden größtenteils eigenständig erstellt und unterliegen meinem Urheberrecht.  Eine Vervielfältigung, Bearbeitung oder Weiterverwendung ist ohne vorherige schriftliche Zustimmung nicht gestattet.</p>

            <p className="seal">
                <a 
                    href="https://datenschutz-generator.de/" 
                    title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." 
                    target="_blank" 
                    rel="noopener noreferrer nofollow" 
                    style={{ color: 'black', textDecoration: 'none' }}
                >
                    Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke
                </a>
            </p>
        </Row>
        </Container>
    )
}

export { default as Impressum } from './Impressum';